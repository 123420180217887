import React from 'react';
import { Popover } from 'antd'; // Make sure to import from antd
import { IconButton } from '@mui/material'; // Make sure to import from Material-UI
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { Typography } from 'antd';

const { Paragraph } = Typography;

const InfoComment = ({ title, content }) => {
  return (
    <Popover
      placement="leftTop"
      title={title}
      content={
        <Paragraph
          style={{ width: '200px' }}
          ellipsis={{
            rows: 5,
            expandable: true,
            symbol: 'more',
          }}
        >
          <i>{content}</i>
        </Paragraph>
      }
    >
      <IconButton color="primary">
        <HiOutlineInformationCircle style={{ fontSize: '20px' }} />
      </IconButton>
    </Popover>
  );
};

export default InfoComment;