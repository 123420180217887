import React, { useEffect, useState, useRef } from "react";

import { toast } from "react-toastify";
import { Typography, Grid, Box, Tab, Stack } from "@mui/material";
import { Modal, Tabs } from "antd";
import MainCard from "components/MainCard";
import ViewRecipe from "./ViewRecipe";
import { useLocation } from "react-router-dom";
import ApproveProcess from "./ApproveProcess";
import PendingRecipes from "./Recipe/PendingRecipe";
import InfoComponent from "components/Info";
import DAGFlowCopy from "pages/dashboard/DAGFlow copy";
import ProdRecipeApproval from "./ProdRecipeApproval";
import { accessControlUtils } from "components/AccessControlUtils";
import BOM from "./BOM";

function RecipeConfig() {
  const location = useLocation();
  const { tabId, editRecipeId } = location.state || {};
  const [user_access_data, set_user_access_data] = useState([]);
  const [flow_data, set_flow_data] = useState();
  const [tab, set_tab] = useState("1");
  const [isProduction, setIsProduction] = useState(false);
  const handleChange = (newValue) => {
    set_tab(newValue);
  };

  useEffect(() => {
    set_user_access_data(JSON.parse(localStorage.getItem("user_access_data")));
  }, []);

  const [refresh, set_refresh] = useState([]);
  useEffect(() => {
    const storedData = localStorage.getItem("user_access_data");
    if (storedData) {
      set_user_access_data(JSON.parse(storedData));
    } else {
      set_refresh(!refresh);
    }
  }, [refresh]);

  useEffect(() => {
    set_tab(tabId);
  }, [tabId, editRecipeId]);

  return (
    <MainCard>
      {flow_data?.recipe_id && (
        <DAGFlowCopy
          data={flow_data}
          set_flow_data={set_flow_data}
          isClickable={tab === "2" ? true : false}
        />
      )}
      <Tabs
        activeKey={tab}
        onChange={(value) => {
          handleChange(value);
          set_flow_data();
        }}
        tabBarExtraContent={
          <React.Fragment>
            <Stack direction="row" spacing="1" justifyContent="end">
              <InfoComponent
                infoText={
                  tab === "2"
                    ? "Click on the DAG to select a node or edge, then edit the operations as needed."
                    : "View Recipe"
                }
              />
            </Stack>
          </React.Fragment>
        }
        moreIcon
        items={[
          accessControlUtils("Recipe")?.read_access &&
            accessControlUtils("View Recipe")?.read_access && {
              label: "View Recipe",
              key: "1",
              children: (
                <ViewRecipe
                  set_flow_data={set_flow_data}
                  tab={tab}
                  setIsProduction={setIsProduction}
                />
              ),
            },
          accessControlUtils("Recipe")?.read_access &&
            accessControlUtils("Pending Recipe")?.read_access &&
            !isProduction && {
              label: "Pending Recipe's",
              key: "2",
              children: (
                <PendingRecipes
                  set_flow_data={set_flow_data}
                  set_tab={set_tab}
                  tab={tab}
                  editRecipeId={editRecipeId}
                />
              ),
            },
          accessControlUtils("Recipe")?.read_access &&
            accessControlUtils("Process Approval")?.read_access && {
              label: "Process Approval",
              key: "3",
              children: <ApproveProcess />,
            },
          accessControlUtils("Recipe")?.read_access &&
            accessControlUtils("Prod Recipe Approval")?.read_access && {
              label: "Production Recipe Approval",
              key: "4",
              children: <ProdRecipeApproval />,
            },
        ]}
      />
    </MainCard>
  );
}

export default RecipeConfig;
