import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  Tooltip,
  Button,
  Select,
  IconButton,
  MenuItem,
} from "@mui/material";
import {
  add_material_data,
  bp_add_location_data,
  bp_add_parameter_data,
  bp_add_unit_data,
  bp_units_data,
} from "utils/api";
import { toast } from "react-toastify";
import AddIcon from "@mui/icons-material/Add";
import AddIngredient from "./Configurations/AddIngredients";

const ConfigureRecipe = ({
  is_model_open,
  set_is_model_open,
  type_id,
  set_type_id,
  title,
  get_submit_status,
  set_get_submit_status,
}) => {
  const handleCancel = () => {
    set_is_model_open(false);
  };

  return (
    <Modal
      title={`Add ${title}`}
      open={is_model_open}
      onCancel={handleCancel}
      width={1000}
      footer={[]}
    >
      <AddIngredient
        is_model_open={is_model_open}
        set_is_model_open={set_is_model_open}
        type_id={type_id}
        set_type_id={set_type_id}
        get_submit_status={get_submit_status}
        set_get_submit_status={set_get_submit_status}
      />
    </Modal>
  );
};
export default ConfigureRecipe;
