import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  IconButton,
  ListItem,
  List,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Paper,
  Popper,
  Typography,
  useMediaQuery,
  Stack,
  Tooltip,
  Tab,
  Tabs,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { Typography as Typography1 } from "antd";
// project import
import Transitions from "components/@extended/Transitions";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
// assets

import {
  bp_get_batch_notification_for_employee,
  get_ingredient_value_alert,
  get_notification,
  get_api_function,
  get_notification_for_recipe_approval_action,
} from "utils/api";
import { useNavigate } from "react-router";

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",

  transform: "none",
};

const deviation_color = (per) => {
  let color;
  let bg_color;

  switch (true) {
    case per >= 0 && per <= 5:
      color = "green";
      bg_color = "#CCFFCC ";
      break;
    case (per >= -10 && per < 0) || (per > 5 && per <= 50):
      color = "orange";
      bg_color = "#FFF59E";
      break;
    case per < -10 || per > 50:
      color = "red";
      bg_color = "#FFCCCC";
      break;
    default:
      color = "black";
  }
  return { color: color, bg_color: bg_color };
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Notification = () => {
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down("md"));
  const notification_refresh = useSelector(
    (state) => state.notification_refresh
  );

  const navigate = useNavigate();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    bp_get_batch_notification_for_employee(success_get_notification);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = "grey.300";
  const iconBackColor = "grey.100";

  const [notifications_data, set_notifications_data] = useState([]);
  const [alert_data, set_alert_data] = useState([]);

  const success_get_notification = (res) => {
    set_notifications_data(res.data.data);
  };

  const success_get_alerts = (res) => {
    set_alert_data(res.data.data);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    !alert_data?.length &&
      get_notification_for_recipe_approval_action(success_get_alerts);
  };

  useEffect(() => {
    const fetchNotifications = () => {
      get_api_function(
        "/get_notification_for_recipe_approval_action",
        (res) => {
          set_alert_data(res?.data?.data || []);
        }
      );
      get_api_function("/bp_get_batch_notification_for_employee", (res) => {
        set_notifications_data(res?.data?.data || []);
      });
    };

    fetchNotifications();
    const intervalId = setInterval(fetchNotifications, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <Tooltip title="Notifications">
        <IconButton
          disableRipple
          color="secondary"
          sx={{
            color: "text.primary",
            bgcolor: open ? iconBackColorOpen : iconBackColor,
          }}
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? "profile-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Badge
            badgeContent={`${alert_data?.length + notifications_data?.length}`}
            color="primary"
          >
            <NotificationsOutlinedIcon />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popper
        placement={matchesXs ? "bottom" : "bottom-end"}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [matchesXs ? -5 : 0, 9],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: "100%",
                minWidth: 285,
                maxWidth: 420,
                [theme.breakpoints.down("md")]: {
                  maxWidth: 285,
                },
              }}
            >
              <>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    variant="fullWidth"
                    value={value}
                    onChange={handleChange}
                    aria-label="profile tabs"
                  >
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        textTransform: "capitalize",
                      }}
                      icon={
                        <NotificationsOutlinedIcon
                          color="success"
                          style={{
                            marginBottom: 0,
                            marginRight: "10px",
                          }}
                        />
                      }
                      label="Notifications"
                    />
                    <Tab
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        textTransform: "capitalize",
                      }}
                      icon={
                        <Badge
                          color="primary"
                          badgeContent={alert_data?.length}
                          style={{
                            marginBottom: 0,
                            marginRight: "10px",
                          }}
                        >
                          <NotificationImportantOutlinedIcon color="warning" />
                        </Badge>
                      }
                      label="Approval Notifications"
                    />
                  </Tabs>
                </Box>
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <List
                      component="nav"
                      sx={{
                        p: 0,
                        "& .MuiListItemButton-root": {
                          py: 0.5,
                          "& .MuiAvatar-root": avatarSX,
                          "& .MuiListItemSecondaryAction-root": {
                            ...actionSX,
                            position: "relative",
                          },
                        },
                      }}
                    >
                      {notifications_data?.slice(0, 5)?.map((item) => {
                        return (
                          <React.Fragment>
                            <ListItemButton
                            // onClick={() => {
                            //   handleTimeLine(item);
                            // }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    color: "success.main",
                                    bgcolor: "success.lighter",
                                  }}
                                >
                                  {item.recipe_name
                                    .split(" ")
                                    .map((item) => item[0])
                                    .join("")
                                    .toUpperCase()}
                                </Avatar>
                              </ListItemAvatar>
                              <Stack>
                                <ListItemText
                                  primary={
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      spacing={1}
                                    >
                                      <Typography1.Paragraph
                                        ellipsis={{
                                          rows: 2,
                                          expandable: true,
                                          symbol: "more",
                                        }}
                                      >
                                        {item?.msg}
                                      </Typography1.Paragraph>

                                      <Stack>
                                        <Typography variant="caption" noWrap>
                                          {item?.batch_schedule_time?.slice(
                                            0,
                                            10
                                          )}
                                        </Typography>
                                        <Typography variant="caption" noWrap>
                                          {item?.batch_schedule_time?.slice(
                                            11,
                                            19
                                          )}
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  }
                                  secondary={
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      spacing={0.5}
                                    >
                                      <div>{`Batch : ${item?.batch_number}`}</div>
                                      <div>{`Process : ${item?.process_name}`}</div>
                                    </Stack>
                                  }
                                />
                              </Stack>
                            </ListItemButton>
                            <Divider />
                          </React.Fragment>
                        );
                      })}

                      <Divider />
                      <ListItemButton
                        sx={{ textAlign: "center", py: `${12}px !important` }}
                        onClick={() => {
                          navigate("/notifications");
                          setOpen(false);
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h6" color="primary">
                              View All
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </ClickAwayListener>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <ClickAwayListener onClickAway={handleClose}>
                    <List
                      component="nav"
                      sx={{
                        p: 0,
                        "& .MuiListItemButton-root": {
                          py: 0.5,
                          "& .MuiAvatar-root": avatarSX,
                          "& .MuiListItemSecondaryAction-root": {
                            ...actionSX,
                            position: "relative",
                          },
                        },
                      }}
                    >
                      {alert_data?.slice(0, 5)?.map((item) => {
                        return (
                          <React.Fragment>
                            <ListItemButton
                            // onClick={() => {
                            //   handleTimeLine(item);
                            // }}
                            >
                              <ListItemAvatar>
                                <Avatar
                                  sx={{
                                    color: "orange",
                                    bgcolor: "white",
                                  }}
                                >
                                  {item?.recipe_name
                                    .split(" ")
                                    .map((item) => item[0])
                                    .join("")
                                    .toUpperCase()}
                                </Avatar>
                              </ListItemAvatar>
                              <Stack>
                                <ListItemText
                                  primary={
                                    <Typography1.Paragraph
                                      ellipsis={{
                                        rows: 2,
                                        expandable: true,
                                        symbol: "more",
                                      }}
                                    >
                                      {item?.msg}
                                    </Typography1.Paragraph>
                                  }
                                  secondary={
                                    <Stack
                                      direction="row"
                                      justifyContent="space-between"
                                      spacing={0.5}
                                    >
                                      <Stack>
                                        <div>{`Date : ${item?.date?.slice(
                                          0,
                                          19
                                        )}`}</div>
                                      </Stack>
                                      {/* <Stack>
                                        <div>{`Operation : ${item?.operation_type}`}</div>
                                        <div
                                          style={{
                                            color: deviation_color(
                                              item?.quantity_deviation
                                            )?.color,
                                            backgroundColor: deviation_color(
                                              item?.quantity_deviation
                                            )?.bg_color,
                                          }}
                                        >{`Quantity Deviation : ${item?.quantity_deviation} %`}</div>
                                      </Stack> */}
                                    </Stack>
                                  }
                                />
                              </Stack>
                              {/* <ListItemSecondaryAction>
                                <Stack>
                                  <Typography variant="caption" noWrap>
                                    {item?.batch_schedule_time?.slice(0, 10)}
                                  </Typography>
                                  <Typography variant="caption" noWrap>
                                    {item?.batch_schedule_time?.slice(11, 19)}
                                  </Typography>
                                </Stack>
                              </ListItemSecondaryAction> */}
                            </ListItemButton>
                            <Divider />
                          </React.Fragment>
                        );
                      })}

                      <Divider />
                      <ListItemButton
                        sx={{ textAlign: "center", py: `${12}px !important` }}
                        onClick={() => {
                          navigate("/notifications");
                          setOpen(false);
                        }}
                      >
                        <ListItemText
                          primary={
                            <Typography variant="h6" color="primary">
                              View All
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </ClickAwayListener>
                </TabPanel>
              </>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Notification;
