import React, { useState, useEffect, useRef } from "react";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Grid,
  Select,
  MenuItem,
  Button,
  Tooltip,
  TextField,
  Box,
  Chip,
  Autocomplete,
} from "@mui/material";
import MainCard from "components/MainCard";
import {
  get_departments,
  get_roles,
  get_designations,
  add_employee_user,
  get_employee_user_list,
  delete_employee,
  add_role,
  add_department,
  add_designation,
  add_role_group,
  get_role_by_group,
  get_role_group,
  delete_role,
  add_bulk_user,
  get_shift,
  bp_location_data,
  bp_add_location_data,
  bp_save_employee_shift,
} from "utils/api";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ErrorIcon from "@mui/icons-material/Error";
import { toast } from "react-toastify";
import {
  Table,
  Select as AntSelect,
  Popconfirm,
  Modal,
  Form as Form1,
  Tag,
  Upload,
  TimePicker,
} from "antd";

import { SearchOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import CloseIcon from "@mui/icons-material/Close";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
//import userData from "../../../assets/third-party/add_user_data.xlsx";

const UserConfig2 = () => {
  const formRef = useRef();
  const [searchvalue, setSearchvalue] = useState("");
  const user_Configuration_access = JSON.parse(
    localStorage.getItem("user_access_data")
  )?.find((item) => item.module_name === "User Configuration");

  // STATES OF INPUT FIELDS
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [department_id, setDepartment] = useState();
  const [location, set_location] = useState();
  const [designation_id, setDesignation] = useState();
  const [shift, setShift] = useState();
  const [group_id, setGroup] = useState([]);
  const [role_id, setRole] = useState([]);

  // INPUT HANDLERS
  const handlefirst_name = (e) => {
    setfirst_name(e.target.value);
  };
  const handlelast_name = (e) => {
    setlast_name(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleContact = (e) => {
    setContact(e.target.value);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
    set_add_name("");
    set_add_index("");
  };

  const [add_index, set_add_index] = useState("");
  const [add_name, set_add_name] = useState("");
  const [add_shift, set_add_shift] = useState([]);

  const handle_add_icon = (index) => {
    setIsModalOpen(true);
    set_add_index(index);
  };
  const handle_add_name = (e) => {
    set_add_name(e.target.value);
  };

  const handle_add_shift = (val) => {
    set_add_shift(val);
  };

  const [edit_val, set_edit_val] = useState(false);

  const [refreshValue, setRefreshValue] = useState(true);
  const exists_data_column = [
    {
      dataIndex: "first_name",
      align: "center",
      title: "First Name",
    },
    {
      dataIndex: "last_name",
      align: "center",
      title: "Last Name",
    },
    {
      dataIndex: "email",
      align: "center",
      title: "Email",
    },
    {
      dataIndex: "contact",
      align: "center",
      title: "Contact",
    },
    {
      dataIndex: "msg",
      title: "Error Message",
    },
  ];

  const success_add_User = async (res) => {
    if (res.status === "success") {
      toast.success(res.message);
      if (res?.elready_exists_data?.length > 0) {
        await error(res?.elready_exists_data);
      }
      resetForm();
      setRefreshValue(!refreshValue);
      set_refresh(!refresh);
      set_edit_val(false);
      formRef?.current?.reset();
    } else if (res.status === "failed") {
      toast.warning(res.message);
      formRef?.current?.reset();
    }
  };

  const error = (exists_data) => {
    Modal.error({
      title: "Error Message",
      content: (
        <Table
          size="small"
          bordered
          pagination={false}
          columns={exists_data_column}
          dataSource={exists_data}
        />
      ),
      width: "40%",
      okText: "Close",
    });
  };

  const [employee_id, set_employee_id] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();

    const user_data = edit_val
      ? {
          first_name: first_name,
          last_name: last_name,
          email: email,
          contact: contact,
          department_id: department_id,
          group_id: group_id.join(","),
          designation_id: designation_id,
          employee_id: employee_id,
          role_id: role_id.join(","),
          location_id: location,
          shift_id: shift,
        }
      : {
          first_name: first_name,
          last_name: last_name,
          email: email,
          contact: contact,
          department_id: department_id,
          group_id: group_id.join(","),
          designation_id: designation_id,
          role_id: role_id.join(","),
          location_id: location,
          shift_id: shift,
        };
    form.resetFields();
    add_employee_user(user_data, success_add_User);
  };
  const resetForm = () => {
    setfirst_name("");
    setlast_name("");
    setEmail("");
    setContact("");
    setDepartment();
    setDesignation();
    setRole([]); //Multiple
    setGroup([]);
    setShift();
    set_location();
    setRole([]);
  };

  // USEEFFECTS
  const [department_list, set_department_list] = useState([]);
  const [shift_list, set_shift_list] = useState([]);
  const [original_role_list, set_original_role_list] = useState([]);
  const [location_list, set_location_list] = useState([]);
  const [designation_list, set_designation_list] = useState([]);
  const [employees_list, set_employees_list] = useState([]);
  const [refresh, set_refresh] = useState(true);

  const success_employee_user_list = (res) => {
    set_employees_list(res?.data);
  };

  const success_get_location = (res) => {
    set_location_list(res.data.data);
  };

  const success_set_department = (res) => {
    set_department_list(res.data.departdata);
  };

  const success_get_shift = (res) => {
    set_shift_list(res.data.data);
  };

  const success_set_designation_list = (res) => {
    set_designation_list(res.data.data);
  };
  const success_set_original_role_list = (res) => {
    set_original_role_list(res.data.data);
  };
  const get_lists = async () => {
    try {
      await get_employee_user_list(success_employee_user_list);
      await get_departments(success_set_department);
      await get_shift(success_get_shift);
      await bp_location_data(success_get_location);
      await get_designations(success_set_designation_list);
      await get_roles(success_set_original_role_list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    get_lists();
  }, [refreshValue]);

  const actionColumn =
    user_Configuration_access?.update_access ||
    user_Configuration_access?.delete_access
      ? [
          {
            title: "Action",
            width: "10%",
            align: "center",
            render: (_, record) => {
              return (
                <Stack direction="row">
                  {user_Configuration_access?.update_access && (
                    <Tooltip title="Edit">
                      <Button
                        size="small"
                        onClick={() => {
                          //    handleEdit(record);
                        }}
                      >
                        <EditIcon
                          color="primary"
                          style={{
                            fontSize: "24px",
                          }}
                        />
                      </Button>
                    </Tooltip>
                  )}
                  {user_Configuration_access?.delete_access && (
                    <Popconfirm
                      description="Are you sure to delete this user?"
                      onConfirm={() => {
                        //     delete_action(record.employee_id);
                      }}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Tooltip title="Delete">
                        <Button size="small">
                          <DeleteForeverOutlinedIcon
                            style={{
                              color: "red",
                              fontSize: "28px",
                            }}
                          />
                        </Button>
                      </Tooltip>
                    </Popconfirm>
                  )}
                </Stack>
              );
            },
          },
        ]
      : [];

  // --------------------------------------------------------------------------------------

  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              confirm({
                closeDropdown: false,
              });
            }}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  // ----------------------------------------------------------------------------

  const employees_column = [
    {
      dataIndex: "employee_name",
      width: "15%",
      title: "Name",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return (
          String(record.employee_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.email).toLowerCase().includes(value.toLowerCase()) ||
          String(record.location).toLowerCase().includes(value.toLowerCase()) ||
          String(record.department)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.contact).toString().includes(value)
        );
      },
    },
    {
      dataIndex: "contact",
      width: "10%",
      title: "Phone",
    },
    {
      dataIndex: "email",
      width: "18%",
      title: "Email",
    },
    {
      dataIndex: "location",
      width: "10%",
      title: "Location",
      align: "center",
    },
    {
      dataIndex: "department",
      width: "10%",
      title: "Department",
      align: "center",
    },
    {
      dataIndex: "designation",
      width: "10%",
      align: "center",
      title: "Designation",
    },

    {
      title: "Roles",
      render: (_, record, index) => {
        const role_names = Array.isArray(record.role_name)
          ? record.role_name
          : JSON.parse(record.role_name.replace(/'/g, '"'));

        return (
          <>
            {role_names &&
              role_names?.map((item, i) => (
                <Chip size="small" label={item} key={i} sx={{ m: 0.5 }} />
              ))}
          </>
        );
      },
    },
  ];

  const [userShow, setuserShow] = useState(false);

  // ----------------------Delete Modal-------------------------

  // =======
  const [form] = Form1.useForm();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const userFileData = new FormData();
    userFileData.append("file", selectedFile);
    //  add_bulk_user(userFileData, success_add_User);
  };

  const handle_submit_add_name = () => {
    if (add_index === "1") {
      add_role(
        {
          role_name: add_name,
        },
        success_config
      );
    }
    if (add_index === "2") {
      add_department(
        {
          Department_name: add_name,
        },
        success_config
      );
    }
    if (add_index === "3") {
      add_designation(
        {
          designation: add_name,
        },
        success_config
      );
    }
    if (add_index === "9") {
      bp_add_location_data(
        {
          location_name: add_name,
        },
        success_config
      );
    }
    if (add_index === "5") {
      bp_save_employee_shift(
        {
          shift: add_shift,
        },
        success_config
      );
    }
  };

  const success_config = (res) => {
    if (res.status === "success") {
      toast.success(res.message);

      if (add_index === "1") {
        get_roles(success_set_original_role_list);
      }
      if (add_index === "2") {
        get_departments(success_set_department);
      }
      if (add_index === "3") {
        get_designations(success_set_designation_list);
      }
      if (add_index === "9") {
        bp_location_data(success_get_location);
      }
      if (add_index === "5") {
        get_shift(success_get_shift);
      }
      setIsModalOpen(false);
      set_add_name("");
    } else if (res.status === "failed") {
      toast.warning(res.message);
    }
  };

  return (
    <MainCard>
      <Stack direction="row" justifyContent="end">
        {userShow && (
          <>
            <Tooltip title="Close">
              <Button
                onClick={() => {
                  setuserShow(false);
                }}
              >
                <CloseIcon
                  color="error"
                  style={{
                    fontSize: "30px",
                  }}
                />
              </Button>
            </Tooltip>
          </>
        )}
      </Stack>
      <Stack spacing={1}>
        {userShow && (
          <form
            style={{
              marginBottom: "20px",
            }}
            onSubmit={handleSubmit}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} lg={4}>
                <Stack spacing={0.5}>
                  <InputLabel required htmlFor="first_name">
                    First Name
                  </InputLabel>
                  <OutlinedInput
                    id="first_name"
                    type="text"
                    name="first_name"
                    placeholder="Enter First Name"
                    fullWidth
                    required
                    value={first_name}
                    onChange={handlefirst_name}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Stack spacing={0.5}>
                  <InputLabel required htmlFor="last_name">
                    Last Name
                  </InputLabel>
                  <OutlinedInput
                    id="last_name"
                    type="text"
                    name="last_name"
                    placeholder="Enter Last Name"
                    fullWidth
                    required
                    value={last_name}
                    onChange={handlelast_name}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Stack spacing={0.5}>
                  <InputLabel required htmlFor="email">
                    Email
                  </InputLabel>
                  <TextField
                    id="email"
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    fullWidth
                    required
                    value={email}
                    onChange={handleEmail}
                    error={
                      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        email
                      ) && email !== ""
                    }
                    helperText={
                      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        email
                      ) || email === ""
                        ? ""
                        : "Enter valid email"
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Stack spacing={0.5}>
                  <InputLabel required htmlFor="contact">
                    Phone
                  </InputLabel>
                  <TextField
                    id="contact"
                    type="text"
                    name="contact"
                    placeholder="Enter Phone Number"
                    fullWidth
                    required
                    value={contact}
                    onChange={(e) => {
                      if (/^\d+$/.test(e.target.value) || e.target.value === "")
                        handleContact(e);
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} lg={4}>
                <InputLabel required id="location">
                  Location
                </InputLabel>
                <Stack spacing={0.5} direction="row">
                  <AntSelect
                    allowClear
                    showSearch
                    id="demo-select-small"
                    value={location}
                    size="large"
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    placeholder="Select Location"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.localeCompare(optionB.children)
                    }
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    onChange={(value, option) => {
                      set_location(value);
                    }}
                  >
                    {location_list?.map((item) => (
                      <AntSelect.Option
                        key={item.location_id}
                        value={item.location_id}
                      >
                        {item.location_name}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>
                  <Tooltip title="Add Location">
                    <Button
                      onClick={() => {
                        handle_add_icon("9");
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "#00C853",
                          fontSize: "32px",
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={4}>
                <InputLabel required id="department">
                  Department
                </InputLabel>
                <Stack spacing={0.5} direction="row">
                  <AntSelect
                    allowClear
                    showSearch
                    id="demo-select-small"
                    value={department_id}
                    size="large"
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    placeholder="Select Department"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.localeCompare(optionB.children)
                    }
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    onChange={(value, option) => {
                      setDepartment(value);
                    }}
                  >
                    {department_list?.map((item) => (
                      <AntSelect.Option key={item?.id} value={item?.id}>
                        {item?.department_name}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>

                  <Tooltip title="Add Department">
                    <Button
                      onClick={() => {
                        handle_add_icon("2");
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "#00C853",
                          fontSize: "32px",
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <InputLabel required id="role">
                  Role
                </InputLabel>
                <Stack spacing={0.5} direction="row">
                  <AntSelect
                    mode="multiple"
                    allowClear
                    showSearch
                    id="demo-select-small"
                    value={role_id}
                    size="large"
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    placeholder="Select Role"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.localeCompare(optionB.children)
                    }
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    onChange={(value, option) => {
                      setRole(value);
                    }}
                  >
                    {original_role_list?.map((item) => (
                      <AntSelect.Option
                        key={item?.role_id}
                        value={item?.role_id}
                      >
                        {item?.role}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>

                  <Tooltip title="Add Role">
                    <Button
                      onClick={() => {
                        handle_add_icon("1");
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "#00C853",
                          fontSize: "32px",
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Stack>
              </Grid>

              <Grid item xs={12} lg={4}>
                <InputLabel required id="designation">
                  Designation
                </InputLabel>
                <Stack spacing={0.5} direction="row">
                  <AntSelect
                    allowClear
                    showSearch
                    id="demo-select-small"
                    value={designation_id}
                    size="large"
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    placeholder="Select Designation"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.localeCompare(optionB.children)
                    }
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    onChange={(value, option) => {
                      setDesignation(value);
                    }}
                  >
                    {designation_list?.map((item) => (
                      <AntSelect.Option
                        key={item?.designation_id}
                        value={item?.designation_id}
                      >
                        {item?.designation}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>

                  <Tooltip title="Add Designation">
                    <Button
                      onClick={() => {
                        handle_add_icon("3");
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "#00C853",
                          fontSize: "32px",
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid item xs={12} lg={4}>
                <InputLabel required id="designation">
                  Shift
                </InputLabel>
                <Stack spacing={0.5} direction="row">
                  <AntSelect
                    allowClear
                    showSearch
                    id="demo-select-small"
                    value={shift}
                    size="large"
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    placeholder="Select Designation"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.localeCompare(optionB.children)
                    }
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    onChange={(value, option) => {
                      setShift(value);
                    }}
                  >
                    {shift_list?.map((item) => (
                      <AntSelect.Option
                        key={item?.shift_id}
                        value={item?.shift_id}
                      >
                        {item?.shift}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>

                  <Tooltip title="Add Shift">
                    <Button
                      onClick={() => {
                        handle_add_icon("5");
                      }}
                    >
                      <AddIcon
                        style={{
                          color: "#00C853",
                          fontSize: "32px",
                        }}
                      />
                    </Button>
                  </Tooltip>
                </Stack>
              </Grid>

              <Grid
                item
                xs={12}
                lg={12}
                container
                alignContent="end"
                justifyContent={{ xs: "center", lg: "end" }}
              >
                <Stack spacing={2} direction="row" justifyContent="end">
                  <Button
                    size="medium"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                        email
                      ) || role_id?.length === 0
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    size="medium"
                    type="reset"
                    variant="contained"
                    color="primary"
                    onClick={resetForm}
                  >
                    Reset
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}
        <Stack direction="row" justifyContent="end" alignItems="center">
          {user_Configuration_access?.create_access && !userShow && (
            <Tooltip title="Add User">
              <Button
                onClick={() => {
                  setuserShow(true);
                }}
              >
                <AddIcon
                  color="primary"
                  style={{
                    fontSize: "33px",
                  }}
                />
              </Button>
            </Tooltip>
          )}
          <Input.Search
            Placeholder="Search here..."
            className="my-1"
            size="large"
            style={{ width: "18%" }}
            onSearch={(value) => {
              setSearchvalue(value);
            }}
            onChange={(e) => setSearchvalue(e.target.value)}
          />
        </Stack>
        <Table
          size="small"
          bordered
          pagination={true}
          columns={employees_column}
          dataSource={employees_list}
          // scroll={{ x: 950, y: !userShow ? 500 : 260 }}
        />
      </Stack>

      <Modal
        title={`Add ${
          add_index === "1"
            ? "Role"
            : add_index === "2"
            ? "Department"
            : add_index === "3"
            ? "Designation"
            : add_index === "4"
            ? "Group"
            : add_index === "5"
            ? "Shift"
            : add_index === "9" && "location"
        }`}
        open={isModalOpen}
        okButtonProps={{
          disabled:
            add_index === "5" ? add_shift.length === 0 : add_name.length === 0,
        }}
        onOk={handle_submit_add_name}
        onCancel={handleCancel}
        okText="Add"
      >
        <Grid container spacing={1} direction="row" alignItems="center">
          <Grid item xs={12} lg={6}>
            <InputLabel id="name">
              {`${
                add_index === "1"
                  ? "Role"
                  : add_index === "2"
                  ? "Department"
                  : add_index === "3"
                  ? "Designation"
                  : add_index === "4"
                  ? "Group"
                  : add_index === "5"
                  ? "Shift"
                  : add_index === "9" && "location"
              } : `}
            </InputLabel>
          </Grid>
          <Grid item xs={12} lg={6}>
            {add_index === "5" ? (
              <TimePicker.RangePicker
                onChange={(e, e1) => {
                  handle_add_shift(e1);
                }}
              />
            ) : (
              <OutlinedInput
                id="name"
                type="text"
                name="text"
                size="small"
                placeholder={`Enter ${
                  add_index === "1"
                    ? "Role Name"
                    : add_index === "2"
                    ? "Department Name"
                    : add_index === "3"
                    ? "Designation Name"
                    : add_index === "4"
                    ? "Group Name"
                    : add_index === "5"
                    ? "Select Shift"
                    : add_index === "9" && "location name"
                }`}
                fullWidth
                required
                value={add_name}
                onChange={handle_add_name}
              />
            )}
          </Grid>
        </Grid>
      </Modal>
    </MainCard>
  );
};

export default UserConfig2;
