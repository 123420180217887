import { useEffect, useRef, useState } from "react";
import ReactFlow, {
  Controls,
  Background,
  ReactFlowProvider,
  MarkerType,
  useReactFlow,
  Panel,
  BackgroundVariant,
} from "reactflow";
import "reactflow/dist/style.css";
import { Stack, IconButton, Tooltip } from "@mui/material";
import { Badge } from "antd";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import CloseIcon from "@mui/icons-material/Close";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import "../../../assets/styles/flow.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import createLayout from "./testNodesEdges";
import { get_api_function } from "utils/api";
import { useSelector } from "react-redux";

const color_json = {
  Completed: "#28A745",
  "In Progress": "#1e90ff",
  Pending: "#ff6d00",
};

function Flow() {
  const [graph, setGraph] = useState(null);
  const [align, setAlign] = useState(true);
  const [all_data, set_all_data] = useState();
  const newProcessData = useSelector((state) => state.newProcessData);
  const [data, set_data] = useState({
    recipe_id: localStorage.getItem("recipe_md_id"),
  });

  const call_function = async (
    { operation_list, process_list, operation_edges, process_edges },
    align_align
  ) => {
    const { nodes, edges } = await createLayout(
      [...operation_list, ...newProcessData?.operation_list],
      [...process_list, ...newProcessData?.process_list],
      [
        ...operation_edges,
        ...newProcessData?.operation_edges,
        ...process_edges?.map((_) => ({
          ..._,
          hidden: true,
        })),
        ...newProcessData?.process_edges?.map((_) => ({
          ..._,
          hidden: true,
        })),
      ],
      align_align
    );

    setGraph({ nodes, edges });
  };

  const get_function = () => {
    get_api_function(
      `/get_operation_operation_type`,
      (res) => {
        let data = res.data.data;
        set_all_data(data[0]);
      },
      {
        ...(data?.recipe_id ? { recipe_id: data?.recipe_id } : {}),
      }
    );
  };

  useEffect(() => {
    get_function();
  }, [data]); // exclude align from the dependency array

  // Separate useEffect for alignment changes
  useEffect(() => {
    if (all_data) {
      call_function(all_data, align);
    }
  }, [align, all_data, newProcessData]);

  const { fitView, setViewport, setCenter, zoomTo } = useReactFlow();
  const divRef = useRef(null);
  const handle = useFullScreenHandle();

  const handleFitView = () => {
    if (divRef.current) {
      fitView();
    }
  };

  return (
    <FullScreen handle={handle}>
      <div
        ref={divRef}
        style={{
          height: handle.active ? "100vh" : "30vh",
          minHeight: "30vh",
          maxHeight: handle.active ? "100vh" : "60vh",
          resize: "vertical",
          overflow: "auto",
         // border: "1px solid grey"
        }}
      >
        <ReactFlow
          nodes={graph?.nodes}
          edges={graph?.edges}
          nodesDraggable={false}
          nodesConnectable={false}
          fitView
          edgesUpdatable={false}
          draggable={false}
          onBeforeDelete={() => false}
          deleteKeyCode={[]}
          colorMode="light"
          style={{ backgroundColor: "white" }}
          proOptions={{ hideAttribution: true }}
        >
          <Controls
            showZoom={false}
            showFitView={false}
            showInteractive={false}
          >
            <Stack direction="column" justifyContent="center">
              {/* <Tooltip
                  title={align ? "Align Verical" : "Align Horizontal"}
                  placement="right"
                >
                  <IconButton
                    onClick={() => {
                      get_function(!align);
                      setAlign(!align);
                    }}
                    size="small"
                  >
                    <VerticalAlignCenterIcon
                      sx={{
                        transform: align ? "" : "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </Tooltip> */}

              <Tooltip
                title={handle.active ? "Exit Full Screen" : "Enter Full Screen"}
                placement="right"
              >
                <IconButton
                  onClick={() => {
                    handle.active ? handle.exit() : handle.enter();
                    fitView();
                  }}
                  size="small"
                >
                  {handle.active ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </IconButton>
              </Tooltip>

              <Tooltip title="Fit View" placement="right">
                <IconButton
                  onClick={() => {
                    handleFitView();
                  }}
                  size="small"
                >
                  <FitScreenIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Close" placement="right">
                <IconButton
                  onClick={() => {
                    set_flow_data(null);
                  }}
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip> */}
            </Stack>
          </Controls>
        </ReactFlow>
      </div>
    </FullScreen>
  );
}

export default function ({ data, set_flow_data }) {
  return (
    <ReactFlowProvider>
      <Flow data={data} set_flow_data={set_flow_data} />
    </ReactFlowProvider>
  );
}
