import React, { useEffect, useState, useRef } from "react";
import {
  get_sensor_list,
  sensor_configuration,
  update_sensor_status,
  bp_units_data,
  add_sensor_type,
  get_sensor_type,
  get_machine_category,
} from "../../../utils/api";
import { toast } from "react-toastify";
import { Table, Pagination, Input, Switch, Select, Modal } from "antd";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ConfigModal from "./ConfigModal";
import { useNavigate } from "react-router-dom";
import QrCodeIcon from "@mui/icons-material/QrCode";
import { accessControlUtils } from "components/AccessControlUtils";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  InputAdornment,
  IconButton,
  MenuItem,
  Grid,
  Chip,
  Button,
} from "@mui/material";
import AddIngredient from "./AddIngredients";
import { indexOf } from "lodash";
import MainCard from "components/MainCard";

function SensorView({ show_config }) {
  const navigate = useNavigate();
  const formRef = useRef();
  const [sensorData, setsensorData] = useState();
  const [show_form, set_show_form] = useState(false);
  const [searchvalue, setSearchvalue] = useState("");
  const [show_modal, set_show_modal] = useState(false);
  const [pollRate, set_pollRate] = useState();
  const [pollRateUnit, set_pollRateUnit] = useState("");
  const [ip, set_ip] = useState("");
  const [port, set_port] = useState("");
  const [type, set_type] = useState("");
  const [address, set_address] = useState("");
  const [show, set_isShow] = useState(false);
  const [quantity, set_Quantity] = useState("");
  const [typeList, set_typeList] = useState([]);
  const [sensorType, set_sensorType] = useState("");
  const handleOk = () => {
    add_sensorType({ name: sensorType });
  };
  const onChange = (checked, record) => {
    update_sensor({ sensor_id: record?.sensor_id, sensor_status: checked });
  };

  const [name, setName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [make, setMake] = useState("");
  const [sensorStatus, setSensorStatus] = useState("");
  const [unitData, setUnitsData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [get_status, set_get_status] = useState(false);
  const ipRegex =
    /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;

  const sensorDetailColumns = [
    {
      dataIndex: "sensor_name",
      title: "Sensor Name",
      key: "name",
      align: "center",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return (
          String(record.sensor_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.category).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      dataIndex: "category",
      title: "Sensor Category",
      key: "category",
      align: "center",
    },
    {
      dataIndex: "ip_address",
      title: "IP Address",
      key: "ip",
      align: "center",
    },
    {
      dataIndex: "type",
      title: "Type",
      key: "type",
      align: "center",
    },
    {
      dataIndex: "address",
      title: "Address",
      key: "address",
      align: "center",
    },
    {
      dataIndex: "poll_rate",
      title: "Poll Rate",
      key: "pollrate",
      align: "center",
    },
    {
      dataIndex: "quantity",
      title: "Quantity",
      key: "unit",
      align: "center",
    },
    {
      dataIndex: "unit",
      title: "Unit",
      key: "unit",
      align: "center",
    },
    {
      dataIndex: "make",
      title: "Make",
      key: "make",
      align: "center",
    },
    {
      dataIndex: "status",
      title: "Status",
      key: "status",
      width: "8%",
      align: "center",
      render: (data, record, index) => {
        return (
          <Tooltip title={data ? "InActive" : "Active"}>
            <Switch
              checked={data}
              disabled={!accessControlUtils("Configuration")?.create_access}
              onChange={(checked) => onChange(checked, record)}
              style={{
                transform: "scale(0.8)", // Scale down the whole switch
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  //####################### Handle Api's######################################################

  const get_all_sensor = async () => {
    try {
      await get_sensor_list(success_sensor_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_sensor_list_get = (res) => {
    console.log("res", res);
    setsensorData(res?.data?.data);
  };

  const add_sensor_Data = async (req) => {
    try {
      await sensor_configuration(req, success_add_sensor_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const get_all_dropDown_Data = async () => {
    try {
      await bp_units_data(success_units_list_get);
      await get_sensor_type(successGetSensorType);
      await get_machine_category(success_get_all_categories);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_units_list_get = (res) => {
    setUnitsData(res?.data?.data);
  };

  const success_get_all_categories = (res) => {
    setCategoryList(res?.data?.data);
  };

  const add_sensorType = async (req) => {
    try {
      await add_sensor_type(req, success_add_sensor_type);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_add_sensor_type = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      get_sensor_type(successGetSensorType);
      set_isShow(false);
      set_sensorType("");
    } else {
      toast.warning(res?.message);
    }
  };
  const successGetSensorType = (res) => {
    set_typeList(res?.data?.data || []);
  };

  const success_add_sensor_data = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      reset_function();
      get_all_sensor();
    } else {
      toast.warning(res?.message);
    }
  };
  const update_sensor = async (req) => {
    try {
      await update_sensor_status(req, success_update_sensor_status);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_update_sensor_status = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      get_all_sensor();
    } else {
      toast.warning(res?.message);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validateIpAddress = ipRegex.test(ip);
    if (validateIpAddress) {
      if (categoryId && unitId) {
        const req = {
          name: name,
          category_id: categoryId,
          unit_id: unitId,
          make: make,
          sensor_status: sensorStatus === "Active" ? true : false,
          ip_address: ip,
          port: port,
          type: type,
          address: address,
          poll_rate: pollRate,
          quantity: quantity,
        };
        add_sensor_Data(req);
      } else {
        toast?.warning("Please provide all fields");
      }
    } else {
      toast?.warning("Please provide valid IP Address");
    }
  };

  const reset_function = () => {
    setName("");
    setCategoryId("");
    setMake("");
    setSensorStatus("");
    setUnitId("");
    set_address("");
    set_type("");
    set_Quantity("");
    set_pollRate("");
    set_pollRateUnit("");
    set_ip("");
    set_port("");
  };

  useEffect(() => {
    get_all_sensor();
    get_all_dropDown_Data();
  }, []);


  useEffect(() => {
    try {
      bp_units_data(success_units_list_get);
    } catch (error) {
      console.log(error);
    }
  }, [get_status]);

  return (
    <>
      <MainCard title="Sensor">
        {show_form && (
          <Stack direction="row" justifyContent="end">
            <Tooltip title="Close" style={{ marginTop: "-45px" }}>
              <IconButton onClick={() => set_show_form(!show_form)}>
                <CloseIcon color="error" style={{ fontSize: "35px" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        )}

        {show_form && (
          <form ref={formRef} onSubmit={handleSubmit}>
            <Grid container spacing={2} style={{ display: "flex" }}>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">Sensor ID / Name</InputLabel>
                  <OutlinedInput
                    id="title"
                    size="medium"
                    type="text"
                    name="size"
                    placeholder="Enter sensor ID or name"
                    fullWidth
                    inputProps={{ min: 0 }}
                    value={name}
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">IP Address</InputLabel>
                  <OutlinedInput
                    id="title"
                    size="medium"
                    type="text"
                    name="size"
                    placeholder="Enter IP"
                    fullWidth
                    inputProps={{ min: 0 }}
                    value={ip}
                    required
                    onChange={(e) => set_ip(e.target.value)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">Port</InputLabel>
                  <OutlinedInput
                    id="title"
                    size="medium"
                    type="number"
                    name="size"
                    placeholder="Enter Port"
                    fullWidth
                    inputProps={{ min: 0 }}
                    value={port}
                    required
                    onChange={(e) => set_port(e.target.value)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">Type</InputLabel>
                  <Stack direction="row" spacing={1}>
                    <Select
                      showSearch
                      id="demo-select-small"
                      value={type || null}
                      size="large"
                      style={{
                        width: "100%",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "3px",
                      }}
                      bordered={false}
                      placeholder="Select Type"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      onChange={(value) => set_type(value)}
                    >
                      {typeList?.map((mdata, index) => (
                        <Select.Option key={mdata.index} value={mdata.id}>
                          {mdata.name}
                        </Select.Option>
                      ))}
                    </Select>
                    <Tooltip title="Add Type">
                      <IconButton
                        onClick={() => {
                          set_isShow(true);
                        }}
                      >
                        <AddIcon
                          style={{
                            color: "#00C853",
                            fontSize: "32px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Category</InputLabel>
                  <Stack direction="row" spacing={1}>
                    <Select
                      showSearch
                      id="demo-select-small"
                      value={categoryId || null}
                      size="large"
                      style={{
                        width: "100%",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "3px",
                      }}
                      bordered={false}
                      placeholder="Select Category"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.localeCompare(optionB.children)
                      }
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      onChange={(value) => setCategoryId(value)}
                    >
                      {categoryList?.map((mdata) => (
                        <Select.Option
                          key={mdata.category_id}
                          value={mdata.category_id}
                        >
                          {mdata.category}
                        </Select.Option>
                      ))}
                    </Select>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">Make</InputLabel>
                  <OutlinedInput
                    id="title"
                    size="medium"
                    type="text"
                    name="size"
                    placeholder="Enter Manufacturer"
                    fullWidth
                    inputProps={{ min: 0 }}
                    value={make}
                    required
                    onChange={(e) => setMake(e?.target.value)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">Address</InputLabel>
                  <OutlinedInput
                    id="title"
                    size="medium"
                    type="number"
                    name="size"
                    placeholder="Enter Adddress"
                    fullWidth
                    inputProps={{ min: 0 }}
                    value={address}
                    required
                    onChange={(e) => set_address(e.target.value)}
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">Quantity</InputLabel>
                  <OutlinedInput
                    id="title"
                    size="medium"
                    type="number"
                    name="size"
                    placeholder="Enter Quantity"
                    fullWidth
                    inputProps={{ min: 0 }}
                    value={quantity}
                    required
                    onChange={(e) => set_Quantity(e.target.value)}
                  />
                </Stack>
              </Grid>

              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Unit</InputLabel>
                  <Stack direction="row" spacing={1}>
                    <Select
                      showSearch
                      id="demo-select-small"
                      value={unitId || null}
                      size="large"
                      style={{
                        width: "100%",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "3px",
                      }}
                      bordered={false}
                      placeholder="Select Unit"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.localeCompare(optionB.children)
                      }
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      onChange={(value) => setUnitId(value)}
                    >
                      {unitData?.map((mdata) => (
                        <Select.Option key={mdata.id} value={mdata.id}>
                          {mdata.unit_name}
                        </Select.Option>
                      ))}
                    </Select>
                    <Tooltip title="Add Unit">
                      <IconButton onClick={() => set_show_modal(true)}>
                        <AddIcon
                          style={{
                            color: "#00C853",
                            fontSize: "32px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="size">Poll Rate </InputLabel>
                  <OutlinedInput
                    id="pollrate"
                    type="number"
                    name="pollrate"
                    placeholder={`Enter Poll Rate`}
                    fullWidth
                    required
                    value={pollRate}
                    onChange={(e) => set_pollRate(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end" sx={{ marginRight: 0 }}>
                        <Select
                          showSearch
                          allowClear
                          size="large"
                          labelRender={(props) => {
                            return props?.label?.props?.children;
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                          value={pollRateUnit || null}
                          onChange={(value) => {
                            set_pollRateUnit(value);
                          }}
                          filterOption={(input, option) =>
                            (option?.label2 ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{
                            width: "100%",
                          }}
                          variant="borderless"
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          options={["min", "sec"]?.map((item) => {
                            return {
                              value: item,
                              label: (
                                <div
                                  style={{
                                    maxWidth: "100%",
                                    wordWrap: "break-word",
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  {item}
                                </div>
                              ),
                              label2: item,
                            };
                          })}
                        />
                      </InputAdornment>
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={4}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Status</InputLabel>
                  <Stack direction="row" spacing={1}>
                    <Select
                      showSearch
                      id="demo-select-small"
                      value={sensorStatus || null}
                      size="large"
                      style={{
                        width: "100%",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "3px",
                      }}
                      bordered={false}
                      placeholder="Select Status"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      //   filterSort={(optionA, optionB) =>
                      //     optionA.children.localeCompare(optionB.children)
                      //   }
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      onChange={(value) => setSensorStatus(value)}
                    >
                      {[{ status: "Active" }, { status: "InActive" }]?.map(
                        (mdata) => (
                          <Select.Option
                            key={mdata.status}
                            value={mdata.status}
                          >
                            {mdata.status}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  justifyContent="end"
                  spacing={1}
                  sx={{ m: 1 }}
                >
                  <Button
                    size="small"
                    type="reset"
                    variant="contained"
                    color="primary"
                    className="mx-2"
                    onClick={reset_function}
                  >
                    Reset
                  </Button>
                  <Button
                    size="small"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={
                      !(
                        name &&
                        categoryId &&
                        make &&
                        unitId &&
                        sensorStatus &&
                        type &&
                        ip &&
                        address &&
                        quantity &&
                        pollRate
                      )
                    }
                  >
                    Submit
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        )}

        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="end"
              alignItems="center"
            >
              {accessControlUtils("Configuration")?.create_access &&
                !show_form && (
                  <Tooltip title="Add Sensor">
                    <IconButton onClick={() => set_show_form(!show_form)}>
                      <AddIcon color="primary" style={{ fontSize: "35px" }} />
                    </IconButton>
                  </Tooltip>
                )}
              <Input.Search
                Placeholder="Search here..."
                className="my-1"
                size="large"
                style={{ width: "18%" }}
                onSearch={(value) => {
                  setSearchvalue(value);
                }}
                onChange={(e) => setSearchvalue(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Table
              dataSource={sensorData}
              columns={sensorDetailColumns}
              size="small"
              bordered
              pagination={true}
            />
          </Grid>

          <ConfigModal
            show_modal={show_modal}
            set_show_modal={set_show_modal}
            add_number={2}
            get_status={get_status}
            set_get_status={set_get_status}
          />
        </Grid>
        <Modal
          open={show}
          title="Sensor Type"
          onOk={handleOk}
          okText="Add"
          onCancel={() => {
            set_isShow(false);
            set_sensorType("");
          }}
          okButtonProps={{ disabled: !sensorType }}
        >
          <div className="mt-4">
            <Stack spacing={1}>
              <InputLabel htmlFor="size">Sensor Type</InputLabel>
              <OutlinedInput
                id="title"
                size="medium"
                type="text"
                name="size"
                placeholder="Enter Type"
                fullWidth
                value={sensorType}
                required
                onChange={(e) => set_sensorType(e.target.value)}
              />
            </Stack>
          </div>
        </Modal>
      </MainCard>
    </>
  );
}

export default SensorView;
