import React, { useState } from "react";
import { Modal, Button, Form, Input } from "antd";
import { InputLabel, OutlinedInput, Grid } from "@mui/material";
import { save_device_details } from "../../../utils/api";
import { toast } from "react-toastify";
const AddDevice = ({ modalVisible, setModalVisible }) => {
  const [deviceName, setDeviceName] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [manufacturer, setManufacturer] = useState("");
  const [modelNumber, setModelNumber] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  const handleCloseModal = () => {
    setDeviceName("");
    setDeviceType("");
    setManufacturer("");
    setSerialNumber("");
    setIpAddress("");
    setModelNumber("");
    setModalVisible(!modalVisible);
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    const req = {
      device_name: deviceName,
      device_type: deviceType,
      manufacturer: manufacturer,
      model_number: modelNumber,
      serial_number: serialNumber,
      ip_address: ipAddress,
    };
    saveDevice({ data_flow: [req] });
  };

  //########### handle api call############

  const saveDevice = async (deviceData) => {
    try {
      await save_device_details(deviceData, successAddDeviceData);
    } catch (error) {
      console.log(error);
    }
  };

  const successAddDeviceData = (res) => {
    console.log(res);
    if (res?.status === "success") {
      toast.success(res?.message);
      handleCloseModal();
    } else {
      toast.warning(res?.message);
    }
  };

  return (
    <div>
      <Modal
        title="Add Device"
        visible={modalVisible}
        onCancel={handleCloseModal}
        footer={null}
      >
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid container item xs={12} justify="center" alignItems="center">
              <Grid item xs={3}>
                <InputLabel required htmlFor="device-name-input">
                  Device Name
                </InputLabel>
              </Grid>
              <Grid item xs={9}>
                <OutlinedInput
                  fullWidth
                  name="device-name-input"
                  value={deviceName}
                  variant="outlined"
                  required
                  onChange={(e) => setDeviceName(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center">
              <Grid item xs={3}>
                <InputLabel required htmlFor="IP">
                  IP Address
                </InputLabel>
              </Grid>
              <Grid item xs={9}>
                <OutlinedInput
                  fullWidth
                  name="IP"
                  value={ipAddress}
                  required
                  variant="outlined"
                  onChange={(e) => setIpAddress(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center">
              <Grid item xs={3}>
                <InputLabel htmlFor="deviceType">Device Type</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <OutlinedInput
                  fullWidth
                  name="deviceType"
                  value={deviceType}
                  variant="outlined"
                  onChange={(e) => setDeviceType(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center">
              <Grid item xs={3}>
                <InputLabel htmlFor="manufacturer">Manufacturer</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <OutlinedInput
                  fullWidth
                  name="manufacturer"
                  value={manufacturer}
                  variant="outlined"
                  onChange={(e) => setManufacturer(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center">
              <Grid item xs={3}>
                <InputLabel htmlFor="model">Model Number</InputLabel>
              </Grid>
              <Grid item xs={9}>
                <OutlinedInput
                  fullWidth
                  value={modelNumber}
                  name="model"
                  variant="outlined"
                  onChange={(e) => setModelNumber(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} justify="center" alignItems="center">
              <Grid item xs={3}>
                <InputLabel htmlFor="serial">Serial Number</InputLabel>
              </Grid>

              <Grid item xs={9}>
                <OutlinedInput
                  fullWidth
                  value={serialNumber}
                  name="serial"
                  variant="outlined"
                  onChange={(e) => setSerialNumber(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
    </div>
  );
};

export default AddDevice;
