import React, { useEffect, useState, useRef } from "react";
import {
  bp_material_data,
  save_manual_qr_code_for_ingredent,
  save_manual_qr_code_for_machine,
  ImportIngredentQR,
  bp_machine_data,
  get_api_function,
} from "../../../utils/api";
import { toast } from "react-toastify";
import { Table, Image, Modal, Select as Select1 } from "antd";
import AddIcon from "@mui/icons-material/Add";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import sample_qr_data from "../../../assets/third-party/sample_qr_data.xlsx";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  InputAdornment,
  Select,
  MenuItem,
  Grid,
  Button,
  IconButton,
  Divider,
  FormHelperText,
} from "@mui/material";
import AddIngredient from "./AddIngredients";
import MainCard from "components/MainCard";
import { downloadPDF } from "pages/reusable_functions";
import ReactToPrint from "react-to-print";
import QrPrinter from "components/third-party/QrPrinter";
import { useLocation } from "react-router-dom";
function GenerateQRs({ show_config }) {
  const inputRef = useRef(null);
  const currentDate = new Date().toISOString().split("T")[0];
  const componentRef = useRef();
  const [modal_open, set_modal_open] = useState(false);
  const [inventoryData, setInventoryData] = useState();
  const [qr_data, set_qr_data] = useState([]);
  const [qr_submit_data, set_qr_submit_data] = useState([]);
  const [qr_file, set_qr_file] = useState([]);
  const [machine_data, set_machine_data] = useState([]);
  const [show_qr, set_show_qr] = useState(false);
  const [material_unit, set_material_unit] = useState("");
  const [qr_image, set_qr_image] = useState();
  const location = useLocation();
  const { from } = location.state || {};
  const [selectedMachine, set_selectedMachine] = useState("");

  // Define the PrintableComponent
  const PrintableComponent = React.forwardRef(({ imageUrl, text }, ref) => {
    return (
      <div ref={ref} style={{ textAlign: "center" }}>
        <img
          src={imageUrl}
          alt="Printable"
          style={{ maxWidth: "100%", marginBottom: "10px" }}
        />
        <p>{text}</p>
      </div>
    );
  });

  const [qr_obj, set_qr_obj] = useState({
    ingredent_material_id: "",
    quantity: 1,
    no_of_qr: 1,
    ingredent_material_name: "",
    bag_number: "",
    bag_size: "",
    expiry_date: "",
    material_unique_id: "",
    // ingredent_material_unit: "",
  });

  const inventoryDetailColumns = [
    {
      dataIndex: "ingredent_material_name",
      title: "Material Name",
      key: "ingredent_material_name",
      width: "8%",
      render: (_, record) => record?.ingredent_material_name,
      //   + " " + record?.ingredent_material_unit,
    },
    {
      dataIndex: "material_unique_id",
      title: "Material Unique Id",
      key: "material_unique_id",
      width: "8%",
    },
    {
      dataIndex: "bag_number",
      title: "Bag Number",
      key: "bag_number",
      width: "8%",
    },
    {
      dataIndex: "bag_size",
      title: "Bag Weight",
      key: "bag_number",
      width: "8%",
    },
    {
      dataIndex: "quantity",
      title: "Total Packs",
      key: "quantity",
      width: "8%",
      align: "center",
    },
    {
      dataIndex: "ingredent_material_unit",
      title: "Unit",
      key: "unit",
      width: "8%",
    },
    {
      dataIndex: "expiry_date",
      title: "Validity(Days)",
      key: "expiry_date",
      width: "8%",
      align: "center",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              save_manual_qr_code_for_ingredent(
                { data_flow: [record] },
                success_add_inventory
              );
            }}
          >
            Generate
          </Button>
        );
      },
    },
  ]?.filter(Boolean);

  const machineColumns = [
    {
      dataIndex: "machine_name",
      title: "Machine Name",
      key: "machine_name",
      width: "8%",
    },
    {
      dataIndex: "machine_type",
      title: "Machine Type",
      key: "machine_type",
      width: "8%",
    },
    {
      dataIndex: "installation_date",
      title: "Installation Date",
      key: "installation_date",
      width: "8%",
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      align: "center",
      render: (_, record) => {
        return (
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              generate_qr(record?.machine_id);
            }}
          >
            Generate
          </Button>
        );
      },
    },
  ]?.filter(Boolean);

  //####################### Handle Api's######################################################

  const get_all_inventory = async () => {
    try {
      await bp_material_data(success_inventory_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_inventory_list_get = (res) => {
    setInventoryData(res?.data?.data);
  };

  const getmachines = async () => {
    try {
      await get_api_function("/bp_get_machine_data", success_bp_machine_data);
    } catch (error) {
      console.log(error);
    }
  };

  const success_bp_machine_data = (res) => {
    if (res.data.status !== "failed") set_machine_data(res?.data?.data);
  };

  //Add inventory
  const generate_qr = async (machine_id) => {
    try {
      await save_manual_qr_code_for_machine(
        { machine_id: machine_id },
        success_add_machine
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const success_add_machine = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      // set_qr_data([]);
      set_modal_open(!modal_open);
      set_qr_submit_data(res?.data);
      if (res?.existing?.length > 0) {
        error(res?.existing);
      }
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      toast.success(res.message);
    }
  };

  const error = (exists_data) => {
    Modal.error({
      title: "Error Message",
      content: (
        <Table
          size="small"
          bordered
          pagination={false}
          columns={[
            {
              dataIndex: "name",
              align: "center",
              title: "Name",
            },
            {
              dataIndex: "msg",
              title: "Error Message",
            },
          ]}
          dataSource={exists_data}
        />
      ),
      width: "40%",
      okText: "Close",
    });
  };

  const success_add_inventory = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      // set_qr_data([]);
      set_modal_open(!modal_open);
      set_qr_submit_data(res?.data);
      if (res?.existing?.length > 0) {
        error(res?.existing);
      }
      if (inputRef.current) {
        inputRef.current.value = "";
      }
      toast.success(res.message);
    }
  };

  const reset_function = () => {
    set_qr_obj({
      ingredent_material_id: "",
      quantity: 1,
      no_of_qr: 1,
      ingredent_material_name: "",
      bag_number: "",
      bag_size: "",
      expiry_date: "",
      material_unique_id: "",
      //   ingredent_material_unit: "",
    });
  };

  useEffect(() => {
    get_all_inventory();
    getmachines();
  }, []);

  return (
    <MainCard>
      {from && from !== "machine" ? (
        <>
          <Stack direction="row" justifyContent="end">
            <FormHelperText>
              *Create a QR's for materials using either an Excel upload or
              manual entry.
            </FormHelperText>
          </Stack>
          <Grid item lg={12} container spacing={1}>
            <Grid item lg={2.75}>
              <Stack spacing={1}>
                <InputLabel htmlFor="quantity">Upload File</InputLabel>
                <Stack direction="row" spacing={1}>
                  <OutlinedInput
                    inputProps={{
                      accept:
                        ".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                    }}
                    type="file"
                    id="select-excel"
                    fullWidth
                    required
                    inputRef={inputRef}
                    onChange={(e) => {
                      set_qr_file(e.target.files);
                    }}
                  />
                  <a href={sample_qr_data} download="sample_file.xlsx">
                    <Tooltip title="Sample File">
                      <IconButton id="download-sample-file">
                        <DescriptionOutlinedIcon
                          color="primary"
                          style={{
                            fontSize: "30px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </a>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={2}>
              <Stack spacing={1}>
                <Typography>&nbsp;</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "fit-content" }}
                  disabled={qr_file?.length === 0}
                  onClick={() => {
                    const userFileData = new FormData();
                    userFileData.append("file", qr_file[0]);
                    ImportIngredentQR(userFileData, success_add_inventory);
                  }}
                >
                  Submit
                </Button>
              </Stack>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }}>OR</Divider>
          <Grid container item lg={12} spacing={2}>
            <Grid item xs={2.75}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Material</InputLabel>
                <Select1
                  showSearch
                  id="demo-select-small"
                  value={qr_obj["ingredent_material_id"] || null}
                  size="large"
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  bordered={false}
                  placeholder="Select Recipe"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.children.localeCompare(optionB.children)
                  }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onChange={(value) => {
                    set_qr_obj({
                      ...qr_obj,
                      ingredent_material_id: value,
                      ingredent_material_name: inventoryData?.find(
                        (item) => item?.material_id === value
                      )?.material_name,
                      material_unique_id: inventoryData?.find(
                        (item) => item?.material_id === value
                      )?.material_unique_id,
                      ingredent_material_unit:
                        inventoryData?.find(
                          (item) => item?.material_id === value
                        )?.unit || "",
                    });
                    const unit =
                      inventoryData?.find((data) => data?.material_id === value)
                        ?.unit || "";
                    set_material_unit(unit);
                  }}
                >
                  {inventoryData?.map((mdata) => (
                    <Select1.Option
                      key={mdata.material_id}
                      value={mdata.material_id}
                    >
                      {mdata.material_name}
                    </Select1.Option>
                  ))}
                </Select1>
              </Stack>
            </Grid>
            <Grid item xs={2.75}>
              <Stack spacing={1}>
                <InputLabel htmlFor="quantity">Material Unique Id</InputLabel>
                <OutlinedInput
                  id="quantity"
                  type="text"
                  name="code"
                  placeholder={`Material Unique Id`}
                  fullWidth
                  disabled
                  value={qr_obj["material_unique_id"]}
                  onChange={(e) =>
                    set_qr_obj({
                      ...qr_obj,
                      material_unique_id: e.target.value,
                    })
                  }
                  required
                />
              </Stack>
            </Grid>
            <Grid item xs={2.75}>
              <Stack spacing={1}>
                <InputLabel htmlFor="quantity">Bag Number</InputLabel>
                <OutlinedInput
                  id="quantity"
                  type="text"
                  name="code"
                  placeholder={`Enter Bag Number`}
                  fullWidth
                  value={qr_obj["bag_number"]}
                  required
                  onChange={(e) =>
                    set_qr_obj({
                      ...qr_obj,
                      bag_number: e.target.value,
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={2.75}>
              <Stack spacing={1}>
                <InputLabel htmlFor="quantity">Bag Weight (Each)</InputLabel>
                <OutlinedInput
                  id="quantity"
                  type="number"
                  name="email"
                  placeholder={`Bag Weight`}
                  fullWidth
                  value={qr_obj["bag_size"]}
                  required
                  endAdornment={
                    <InputAdornment>
                      <span>{material_unit}</span>
                    </InputAdornment>
                  }
                  inputProps={{ min: 0 }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                        {
                          "-webkit-appearance": "none",
                        },
                    },
                  }}
                  onChange={(e) =>
                    e.target.value >= 0 &&
                    set_qr_obj({
                      ...qr_obj,
                      bag_size: parseInt(e.target.value),
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={2.75}>
              <Stack spacing={1}>
                <InputLabel htmlFor="quantity">Total Packs</InputLabel>
                <OutlinedInput
                  id="quantity"
                  type="number"
                  name="email"
                  placeholder={`Enter Quantity`}
                  fullWidth
                  value={qr_obj["quantity"]}
                  required
                  onChange={(e) =>
                    e.target.value >= 0 &&
                    set_qr_obj({
                      ...qr_obj,
                      quantity: parseInt(e.target.value),
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={2.75}>
              <Stack spacing={1}>
                <InputLabel htmlFor="expiry">Validity(Days)</InputLabel>
                <OutlinedInput
                  id="date"
                  type="number"
                  name="expiry"
                  fullWidth
                  value={qr_obj["expiry_date"]}
                  required
                  placeholder="Valid for (Days)"
                  inputProps={{ min: 0 }}
                  onChange={(e) =>
                    set_qr_obj({
                      ...qr_obj,
                      expiry_date: e.target.value,
                    })
                  }
                />
              </Stack>
            </Grid>

            <Grid item xs={1}>
              <Stack spacing={1}>
                <Typography>&nbsp;</Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ width: "fit-content" }}
                  disabled={Object.values(qr_obj)?.some(
                    (item) => !Boolean(item)
                  )}
                  onClick={() => {
                    set_qr_data((data) => [...data, qr_obj]);
                    reset_function();
                    set_qr_submit_data([]);
                  }}
                >
                  Add
                </Button>
              </Stack>
            </Grid>

            {/* {qr_data?.length > 0 && (
          <Grid item lg={12}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="contained"
                color="primary"
                disabled={qr_data?.length === 0}
                onClick={() => {
                  generate_qr();
                }}
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        )} */}
          </Grid>
        </>
      ) : (
        <>
          <Grid container item lg={12} spacing={2}>
            <Grid item xs={2.75}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Machine</InputLabel>
                <Select1
                  showSearch
                  id="demo-select-small"
                  value={selectedMachine || null}
                  size="large"
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  bordered={false}
                  placeholder="Select machine"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onChange={(value) => {
                    const machine = machine_data?.find(
                      (data) => data?.machine_id === value
                    );
                    const qrdata = {
                      machine_id: machine?.machine_id,
                      machine_name: machine?.name,
                      machine_type: machine?.machine_type,
                      installation_date: machine?.installation_date?.slice(
                        0,
                        19
                      ),
                    };
                    set_qr_data((data) => [...data, qrdata]);
                    set_selectedMachine(value);
                  }}
                >
                  {machine_data?.map((mdata) => (
                    <Select1.Option
                      key={mdata.machine_id}
                      value={mdata.machine_id}
                    >
                      {mdata.name}
                    </Select1.Option>
                  ))}
                </Select1>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      {qr_data?.length > 0 && (
        <Grid container xs={12}>
          <Table
            dataSource={qr_data}
            columns={
              from === "machine" ? machineColumns : inventoryDetailColumns
            }
            scroll={{ x: 940, y: 410 }}
            size="small"
            style={{ marginTop: 4 }}
            bordered
            pagination={false}
          />
        </Grid>
      )}
      <Modal
        title="Generated QR"
        open={modal_open}
        onCancel={() => {
          set_modal_open(!modal_open);
        }}
        footer={false}
        width={1200}
      >
        <Table
          dataSource={qr_submit_data}
          columns={
            from === "machine"
              ? [
                  {
                    dataIndex: "machine_name",
                    title: "Machine Name",
                    key: "machine_name",
                    align:"center"
                  },
                  {
                    dataIndex: "machine_type",
                    title: "Machine Type",
                    key: "machine_type",
                    align:"center"
                  },
                  {
                    dataIndex: "installation_date",
                    title: "Installation Date",
                    key: "installation_date",
                    align:"center",
                    render: (data, record, index) => data?.slice(0, 19)
                  },
                  {
                    dataIndex: "qr",
                    title: "QR",
                    key: "qr",
                    width: "40%",
                    align: "center",
                    render: (_, record, index) => (
                      <Image
                        width={200} // Set the desired width
                        src={`data:image/png;base64,${record?.qr}`}
                        alt="Base64 Image"
                      />
                    ),
                  },
                  {
                    title: "Action",
                    key: "action",
                    width: "10%",
                    align: "center",
                    render: (_, record) => {
                      return (
                        <>
                          {/* Print Button */}
                          {/* <ReactToPrint
                      trigger={() => ( */}

                          <IconButton
                            color="primary"
                            onClick={() => {
                              set_show_qr(true);
                              set_qr_image(record);
                            }}
                          >
                            <LocalPrintshopIcon color="primary" />
                          </IconButton>
                          {/* )}
                      content={() => componentRef.current}
                    /> */}
                        </>
                      );
                    },
                  },
                ]
              : [
                  {
                    dataIndex: "ingredent_material_name",
                    title: "Material Name",
                    key: "ingredent_material_name",
                    width: "30%",
                    render: (_, record) => record?.ingredent_material_name,
                    //   + " " + record?.ingredent_material_unit,
                  },
                  {
                    dataIndex: "qr_number",
                    title: "QR Number",
                    key: "qr_number",
                    width: "15%",
                    align: "center",
                  },
                  {
                    dataIndex: "bag_number",
                    title: "Bag Number",
                    key: "bag_number",
                    width: "15%",
                    align: "center",
                  },
                  {
                    dataIndex: "quantity",
                    title: "Quantity",
                    key: "quantity",
                    width: "15%",
                    align: "center",
                  },
                  {
                    dataIndex: "expiry_date",
                    title: "Validity(Days)",
                    key: "expiry_date",
                    width: "15%",
                    align: "center",
                  },
                  {
                    dataIndex: "packs",
                    title: "Packs",
                    key: "no_of_qrs",
                    width: "15%",
                    align: "center",
                  },
                  {
                    dataIndex: "qr",
                    title: "QR",
                    key: "qr",
                    width: "40%",
                    align: "center",
                    render: (_, record, index) => (
                      <Image
                        width={200} // Set the desired width
                        src={`data:image/png;base64,${record?.qr}`}
                        alt="Base64 Image"
                      />
                    ),
                  },
                  {
                    title: "Action",
                    key: "action",
                    width: "10%",
                    align: "center",
                    render: (_, record) => {
                      return (
                        <>
                          {/* Print Button */}
                          {/* <ReactToPrint
                      trigger={() => ( */}

                          <IconButton
                            color="primary"
                            onClick={() => {
                              set_show_qr(true);
                              set_qr_image(record);
                            }}
                          >
                            <LocalPrintshopIcon color="primary" />
                          </IconButton>
                          {/* )}
                      content={() => componentRef.current}
                    /> */}
                        </>
                      );
                    },
                  },
                ]?.filter(Boolean)
          }
          scroll={{ x: 940, y: 510 }}
          size="small"
          bordered
          pagination={false}
        />
        {/* <Stack direction="row" justifyContent="center" sx={{ m: 1 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              //   generate_qr();
            }}
          >
            Print
          </Button>
        </Stack> */}
      </Modal>
      <QrPrinter
        show_qr={show_qr}
        set_show_qr={set_show_qr}
        set_qr_image={set_qr_image}
        qr_image={qr_image}
        componentRef={componentRef}
        from={from}
      />
    </MainCard>
  );
}

export default GenerateQRs;
