import MainCard from "components/MainCard";
import React, { useEffect, useState } from "react";
import {
  Stack,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  Button,
  ButtonGroup,
  Box,
} from "@mui/material";
import { DatePicker, Space, Select as AntSelect, Modal, Tabs } from "antd";
import {
  bp_get_batch_number_by_recipe,
  bp_get_batch_report_view,
  get_pidilite_pdf_report,
  get_recipe_by_product_id,
  get_batch_report_pdf,
  bp_product_data,
} from "utils/api";
import ReportSkeleton from "./ReportSkeleton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { base64ToFile } from "pages/reusable_functions";
import { toast } from "react-toastify";

function Report() {
  const [recipe, set_recipe] = useState("");
  const { RangePicker } = DatePicker;
  const [batch, set_batch] = useState("");
  const [show_report, set_show_report] = useState(false);
  const [fromDate, setFromDate] = useState(false);
  const [toDate, setToDate] = useState(false);
  const [operation_data, set_operation_data] = useState([]);
  const [recipe_data, set_recipe_data] = useState([]);
  const [selectedButton, setSelectedButton] = useState("test");
  const [product_list, set_product_list] = useState([]);
  const [product, set_product] = useState();
  const [tab, setTab] = useState(1);
  const [base64Data, set_base64Data] = useState();
  const handleClick = (button) => {
    setSelectedButton(button);
  };

  const items = [
    {
      key: "1",
      label: "OverAll",
      children: (
        <iframe
          title="PDF Viewer"
          width="100%"
          height="800px"
          src={base64Data}
          type="application/pdf"
        />
      ),
    },
    {
      key: "2",
      label: "Individual",
      children: "",
    },
  
  ];

  const buttonStyles = (isSelected) => ({
    width: "100px",
    backgroundColor: isSelected ? "#1976d2" : "inherit", // Use primary.main color or change it to match your theme
    color: isSelected ? "#ffffff" : "inherit", // Use primary.contrastText color or change it to match your theme
    border: "1px solid #1976d2", // Optional: to have a consistent border color
    "&:hover": {
      backgroundColor: isSelected ? "#115293" : "inherit", // Use primary.dark color or change it to match your theme
    },
  });

  const filterRecipe = () => {
    return (
      <Stack>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            "& > *": {
              m: 1,
            },
          }}
        >
          <ButtonGroup variant="outlined" aria-label="Basic button group">
            <Button
              onClick={() => handleClick("test")}
              style={buttonStyles(selectedButton === "test")}
            >
              Test
            </Button>
            <Button
              onClick={() => handleClick("production")}
              style={buttonStyles(selectedButton === "production")}
            >
              Production
            </Button>
          </ButtonGroup>
        </Box>
      </Stack>
    );
  };

  //######### Handle Api's ###########
  const get_data = async (recipe_id) => {
    try {
      await bp_get_batch_number_by_recipe(success_bp_get_batch_process_active, {
        recipe_id: recipe_id,
      });
      //   await bp_get_batch_process_schedule(success_bp_get_batch_process_active);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_get_batch_process_active = (res) => {
    set_operation_data(res?.data?.data);
  };

  const success_bp_product_data = (res) => {
    set_product_list(res?.data?.data);
  };

  const get_recipe = (product) => {
    try {
      get_recipe_by_product_id(success_recipe_by_product, {
        product_id: product,
        ...(selectedButton !== "test" && { recipe_type: true }),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const success_recipe_by_product = (res) => {
    if (res?.data?.status === "success") {
      set_recipe_data(res?.data?.data);
    } else {
      set_recipe_data([]);
    }
  };

  const [process_value, set_process_value] = useState("");
  const [final_report, set_final_report] = useState([]);
  const [report, set_report] = useState([]);
  const get_report = async (batch_value, start, end) => {
    try {
      const batchReq = { batch_number: batch_value };

      // Conditionally add start_date and end_date
      if (start && end) {
        batchReq.start_date = start;
        batchReq.end_date = end;
      }
      if (fromDate && toDate) {
        batchReq.start_date = fromDate;
        batchReq.end_date = toDate;
      }
      await bp_get_batch_report_view(
        success_bp_get_batch_report_view,
        batchReq
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_bp_get_batch_report_view = (res) => {
    if (res?.data?.status === "success") {
      set_report(res?.data?.data || []);
    } else {
      toast.warning(res?.data?.message);
      set_report([]);
    }
    // setFromDate("");
    // setToDate("");
  };

  const handleDownload = async (batch) => {
    try {
      // Assuming get_pidilite_pdf_report returns a Promise
      await get_pidilite_pdf_report(batch, (res) => {
        if (res?.data?.status === "success") {
          set_base64Data(`data:application/pdf;base64,${res?.data?.data}`);
        }
      });
    } catch (error) {
      console.error("Error downloading the report:", error);
    }
  };

  const onChangeTab = (key) => {
    setTab(key);
  };

  useEffect(() => {
    bp_product_data(success_bp_product_data);
  }, [selectedButton]);

  return (
    <MainCard>
      <Grid container spacing={2}>
        <Grid item lg={12}>
          <Stack spacing={2} direction="row" alignItems="center">
            {filterRecipe()}
            {/* <Space direction="vertical" size={12}>
              <RangePicker
                format="YYYY-MM-DD"
                onChange={onChange}
                onOk={onOk}
                onClear={onClear}
              />
            </Space> */}
          </Stack>
        </Grid>

        <Grid item lg={10}>
          <Stack spacing={2} direction="row" alignItems="center">
            <InputLabel htmlFor="title" required>
              Product
            </InputLabel>
            <AntSelect
              showSearch
              allowClear
              id="demo-select-small"
              value={product || null}
              size="medium"
              style={{
                minWidth: "200px",
                maxWidth: "300px",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              bordered={false}
              placeholder="Select Product"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              dropdownStyle={{
                minWidth: "250px",
                maxHeight: "400px",
                overflow: "auto",
              }}
              onChange={(value, option) => {
                set_product(value);
                set_recipe("");
                set_report([]);
                set_final_report([]);
                set_batch("");
                set_process_value("");
                get_recipe(value);
              }}
            >
              {product_list?.map((mdata) => (
                <AntSelect.Option
                  key={mdata.product_data_id}
                  value={mdata.product_data_id}
                >
                  {mdata.product_name}
                </AntSelect.Option>
              ))}
            </AntSelect>
            <InputLabel htmlFor="title" required>
              Recipe
            </InputLabel>
            <AntSelect
              showSearch
              allowClear
              id="demo-select-small"
              value={recipe || null}
              size="medium"
              style={{
                minWidth: "200px",
                maxWidth: "300px",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              bordered={false}
              placeholder="Select Recipe"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              dropdownStyle={{
                minWidth: "250px",
                maxHeight: "400px",
                overflow: "auto",
              }}
              onChange={(value, option) => {
                set_recipe(value);
                set_report([]);
                set_final_report([]);
                set_batch("");
                set_base64Data();
                set_process_value("");
                get_data(value);
              }}
            >
              {recipe_data?.map((mdata) => (
                <AntSelect.Option key={mdata.recipe_id} value={mdata.recipe_id}>
                  {mdata.recipe_name}
                </AntSelect.Option>
              ))}
            </AntSelect>

            <InputLabel htmlFor="title" required>
              Batch
            </InputLabel>
            <AntSelect
              showSearch
              id="demo-select-small"
              value={batch || null}
              style={{
                minWidth: "150px",
                maxWidth: "250px",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              bordered={false}
              placeholder="Select Batch"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              dropdownStyle={{
                minWidth: "180px",
                maxHeight: 400,
                overflow: "auto",
              }}
              onChange={(value, option) => {
                set_batch(value);
                handleDownload(value);
                get_report(value);
              }}
            >
              {operation_data?.map((mdata) => (
                <AntSelect.Option
                  key={mdata.batch_number}
                  value={mdata.batch_number}
                >
                  {mdata.batch_number}
                </AntSelect.Option>
              ))}
            </AntSelect>

            {/* <InputLabel htmlFor="title">Process </InputLabel>
            <AntSelect
              allowClear
              showSearch
              id="demo-select-small"
              value={process_value || null}
              style={{
                minWidth: "150px",
                maxWidth: "250px",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              bordered={false}
              placeholder="Select Process"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              dropdownStyle={{
                minWidth: "200px",
                maxHeight: 400,
                overflow: "auto",
              }}
              onChange={(value, option) => {
                set_process_value(value);
                let temp = report?.filter(
                  (item, index) => item?.process_number === value
                );
                set_final_report(temp);
              }}
            >
              {report
                ?.filter((process) => {
                  return process?.operations?.length !== 0;
                })
                ?.map((mdata) => (
                  <AntSelect.Option
                    key={mdata.process_number}
                    value={mdata.process_number}
                  >
                    {mdata.process_number}
                  </AntSelect.Option>
                ))}
            </AntSelect> */}

            {/* <Button
              variant="contained"
              onClick={() => {
                set_show_report(true);
                let temp = report?.filter(
                  (item, index) => item?.process_number === process_value
                );
                set_final_report(temp);
              }}
            >
              Submit
            </Button> */}
          </Stack>
        </Grid>
        {batch && (
          <Grid item lg={2}>
            <Stack direction="row" justifyContent="end">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<FileDownloadIcon />}
                onClick={() => {
                  get_batch_report_pdf(batch, (res) => {
                    base64ToFile(res?.data?.data, `${batch}_report`);
                  });
                }}
              >
                Download
              </Button>
            </Stack>
          </Grid>
        )}
        <Grid item lg={12}>
          <Tabs items={items} onChange={onChangeTab} />

          {tab === "2" &&
            ((final_report?.length > 0 &&
              final_report[0]?.operations?.length > 0) ||
              report[0]?.operations?.length > 0) && (
              <ReportSkeleton
                report={final_report?.length === 0 ? report : final_report}
              />
            )}
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default Report;
