import React, { useEffect, useState, useRef } from "react";
import {
  bp_get_sampling_config_details,
  bp_get_testing_type,
  bp_parameters_data,
  bp_save_sampling_config,
  bp_add_testing_type,
} from "../../../utils/api";
import { Modal } from "antd";
import { toast } from "react-toastify";
import { Table, Pagination, Select as AntSelect } from "antd";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import {
  InputLabel,
  OutlinedInput,
  Stack,
  Tooltip,
  Typography,
  MenuItem,
  Grid,
  InputAdornment,
  Select,
  Button,
  IconButton,
} from "@mui/material";
import ConfigModal from "./ConfigModal";
import MainCard from "components/MainCard";

function SampleConfig({
  show_config,
  show_table,
  get_status_of_mod,
  set_get_status_of_mod,
}) {
  const formRef = useRef();
  const [sampleConfigData, setsampleConfigData] = useState();
  const [testingType, setTestingType] = useState("");
  const [testingTypeList, setTestingTypeList] = useState([]);
  const [parameterData, setParameterData] = useState();
  const [add_name, set_add_name] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [show_modal, set_show_modal] = useState(false);
  const [get_status, set_get_status] = useState(false);
  const [add_number, set_add_number] = useState(0);
  const [show_form, set_show_form] = useState(false);
  const [searchvalue, setSearchvalue] = useState("");

  const handleOk = () => {
    if (add_name) {
      add_testing_type({
        name: add_name,
      });
      setIsModalOpen(false);
      set_add_name("");
    } else {
      toast.warning("Enter test name");
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    set_add_name("");
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const [sampleConfigReq, setsampleConfigReq] = useState({
    parameter_id: "",
    sampling_min_value: "",
    sampling_max_value: "",
    standard: "",
  });

  const [addConfigDataList, setAddConfigDataList] = useState([
    {
      parameter_id: "",
      sampling_min_value: "",
      sampling_max_value: "",
      standard: "",
    },
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emptyData = addConfigDataList?.find(
      (data) => data.parameter_id === ""
    );

    if (emptyData === undefined) {
      const configDataList = addConfigDataList?.map((data) => ({
        ...data,
        testing_type_id: testingType,
      }));
      add_sampleConfig(configDataList); // call the api
    } else {
      toast.warning("Please Select Parameter");
    }
  };

  const sampleConfigDetailColumns = [
    {
      dataIndex: "testing_type_name",
      title: "Testing Type",
      key: "testing_type",
      align: "left",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return (
          String(record?.testing_type_name).toLowerCase().includes(value.toLowerCase()) 
        );
      },
    },
  ];

  const addSampleConfigDetails = [
    {
      dataIndex: "parameter",
      title: "Parameter",
      key: "parameter",
      align: "center",
      width: "20%",
      render: (data, record, index) => {
        return (
          <Stack direction="row">
            <AntSelect
              allowClear
              showSearch
              id="demo-select-small"
              value={addConfigDataList[index]["parameter_id"] || null}
              size="large"
              style={{
                width: "100%",
                border: "0.5px solid #D9D9D9",
                borderRadius: "3px",
              }}
              name="parameter_id"
              bordered={false}
              placeholder="Select Parameter"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              filterSort={(optionA, optionB) =>
                optionA.children.localeCompare(optionB.children)
              }
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
              onChange={(value, option) => {
                const updatedList = [...addConfigDataList];
                updatedList[index] = {
                  ...updatedList[index],
                  parameter_id: value,
                };
                setAddConfigDataList(updatedList);
              }}
            >
              {parameterData?.map((item) => (
                <AntSelect.Option key={item.id} value={item.id}>
                  {item.parameter_name}
                </AntSelect.Option>
              ))}
            </AntSelect>

            <Tooltip title="Add Parameter">
              <IconButton
                onClick={() => {
                  set_show_modal(true);
                  set_add_number(4);
                }}
              >
                <AddIcon
                  style={{
                    color: "#00C853",
                    fontSize: "32px",
                  }}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        );
      },
    },
    {
      dataIndex: "sampling_min_value",
      title: "Sampling Min Value",
      key: "sampling_min_value",
      align: "center",
      render: (data, record, index) => {
        return (
          <>
            <OutlinedInput
              id="title"
              type="number"
              size="small"
              name="sampling_min_value"
              placeholder="min value"
              fullWidth
              value={addConfigDataList[index].sampling_min_value}
              required
              onChange={(e) => updateField(index, e)}
            />
          </>
        );
      },
    },
    {
      dataIndex: "sampling_max_value",
      title: "Sampling Max Value",
      key: "sampling_max_value",
      align: "center",
      render: (data, record, index) => {
        return (
          <>
            <OutlinedInput
              id="title"
              type="number"
              size="small"
              name="sampling_max_value"
              placeholder="max value"
              fullWidth
              inputProps={{ min: addConfigDataList[index].sampling_min_value }}
              value={addConfigDataList[index].sampling_max_value}
              required
              onChange={(e) => updateField(index, e)}
            />
          </>
        );
      },
    },
    {
      dataIndex: "standard",
      title: "Standard",
      key: "standard",
      align: "center",
      render: (data, record, index) => {
        return (
          <>
            <OutlinedInput
              id="title"
              type="text"
              size="small"
              name="standard"
              placeholder="standard"
              fullWidth
              value={addConfigDataList[index].standard}
              required
              onChange={(e) => updateField(index, e)}
            />
          </>
        );
      },
    },
    {
      dataIndex: "status",
      title: "Status",
      key: "status",
      align: "center",
      render: (data, record, index) => {
        return (
          <>
            <Stack direction="row" justifyContent="center">
              <Button
                hidden={index !== 0}
                onClick={() => {
                  handleAdd();
                }}
              >
                <AddCircleRoundedIcon
                  sx={{ color: "green", fontSize: "25px" }}
                />
              </Button>

              <Button
                hidden={index === 0}
                onClick={() => {
                  handleDelete(index);
                }}
              >
                <DeleteForeverRoundedIcon
                  sx={{ color: "red", fontSize: "25px" }}
                />
              </Button>
            </Stack>
          </>
        );
      },
    },
  ];

  const expandedRowRender = (record) => {
    const updateData = record?.data?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });

    const parameterCols = [
      {
        dataIndex: "parameter_name",
        title: "Parameter Name",
        key: "standard",
        align: "center",
      },
      {
        dataIndex: "sampling_min_value",
        title: "Sampling Min Value",
        key: "sampling_min_value",
        align: "center",
      },
      {
        dataIndex: "sampling_max_value",
        title: "Sampling Max Value",
        key: "sampling_max_value",
        align: "center",
      },
      {
        dataIndex: "standard",
        title: "Standard",
        key: "standard",
        align: "center",
      },
    ];

    return (
      <Table
        columns={parameterCols}
        dataSource={updateData}
        pagination={false}
        bordered
      />
    );
  };

  //For collapse open at a time only one row
  const addKeysInFilterDataForCollapse = (filterData) => {
    const updatedFilterList = filterData?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return updatedFilterList;
  };

  //####################### Handle Api's######################################################

  const get_all_data = async () => {
    try {
      await bp_get_sampling_config_details(success_sampleConfig_list_get);
      await bp_get_testing_type(succcessGetTestingTypes);
      await bp_parameters_data(success_parameter_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_sampleConfig_list_get = (res) => {
    setsampleConfigData(addKeysInFilterDataForCollapse(res?.data?.data));
  };

  const succcessGetTestingTypes = (res) => {
    setTestingTypeList(res?.data?.data);
  };

  const success_parameter_list_get = (res) => {
    console.log("res", res);
    setParameterData(res?.data?.data);
  };

  //Add Sampling data
  const add_sampleConfig = async (sampleConfigReq) => {
    try {
      const configList = {
        data_flow: [...sampleConfigReq],
      };
      await bp_save_sampling_config(configList, success_add_sampleConfig);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const add_testing_type = async (testName) => {
    try {
      await bp_add_testing_type(testName, success_test_name);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_test_name = (res) => {
    formRef.current.reset();
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success(res.message);
      get_all_data();
    }
  };

  const success_add_sampleConfig = (res) => {
    console.log(res);
    formRef.current.reset();
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success(res.message);
      setAddConfigDataList([
        {
          parameter_id: "",
          sampling_min_value: "",
          sampling_max_value: "",
          standard: "",
        },
      ]);
      setsampleConfigReq({
        parameter_id: "",
        sampling_min_value: "",
        sampling_max_value: "",
        standard: "",
      });
      setTestingType("");
      get_all_data(); // get all data
      set_get_status_of_mod(!get_status_of_mod);
    }
  };

  //Update the perticular field
  const updateField = (indexToUpdate, event) => {
    console.log(event.target.name);
    const updatedList = [...addConfigDataList];
    updatedList[indexToUpdate] = {
      ...updatedList[indexToUpdate],
      [event.target.name]: event.target.value,
    };
    setAddConfigDataList(updatedList);
  };

  const handleDelete = (indexToDelete) => {
    // Create a new array without the element at the specified index
    const updatedList = addConfigDataList.filter(
      (_, index) => index !== indexToDelete
    );
    setAddConfigDataList(updatedList);
  };

  const handleAdd = () => {
    setAddConfigDataList([...addConfigDataList, sampleConfigReq]);
  };

  useEffect(() => {
    try {
      add_number === 4 && bp_parameters_data(success_parameter_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [get_status]);

  useEffect(() => {
    get_all_data();
  }, []);

  // useEffect(() => {
  //   if (showDetails) {
  //     setIsShowDetails(showDetails);
  //   }
  // }, []);

  return (
    <MainCard title="Sample Config">
      {show_form && (
        <Stack direction="row" justifyContent="end">
          <Tooltip title="Close" style={{ marginTop: "-45px" }}>
            <IconButton onClick={() => set_show_form(!show_form)}>
              <CloseIcon color="error" style={{ fontSize: "35px" }} />
            </IconButton>
          </Tooltip>
        </Stack>
      )}
      <Grid container spacing={2} columns={12} style={{ marginTop: "-25px" }}>
        {(show_form || show_table) && (
          <Grid item xs={12} lg={12}>
            <form ref={formRef} onSubmit={handleSubmit}>
              <Grid
                container
                spacing={1}
                item
                xs={12}
                lg={12}
                style={{ marginTop: "2px" }}
              >
                <Grid item xs={4} lg={3}>
                  <Stack spacing={1} direction={"row"}>
                    <AntSelect
                      allowClear
                      showSearch
                      id="demo-select-small"
                      value={testingType || null}
                      size="large"
                      style={{
                        minWidth: "100%",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "3px",
                      }}
                      bordered={false}
                      placeholder="Select Testing Type"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.localeCompare(optionB.children)
                      }
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      onChange={(value, option) => {
                        setTestingType(value);
                      }}
                    >
                      {testingTypeList?.map((item) => (
                        <AntSelect.Option
                          key={item.testing_type_id}
                          value={item.testing_type_id}
                        >
                          {item.testing_type}
                        </AntSelect.Option>
                      ))}
                    </AntSelect>
                    <Tooltip title="Add Testing Type">
                      <IconButton
                        onClick={() => {
                          showModal();
                        }}
                      >
                        <AddIcon color="primary" style={{ fontSize: 35 }} />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
                {testingType && (
                  <Grid item xs={12} lg={12}>
                    <Table
                      dataSource={addConfigDataList}
                      columns={addSampleConfigDetails}
                      // scroll={{ x: 940, y: 410 }}
                      size="small"
                      bordered
                      pagination={false}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    justifyContent="end"
                    spacing={1}
                    sx={{ m: 1 }}
                  >
                    <Button
                      size="small"
                      type="reset"
                      variant="contained"
                      color="primary"
                      className="mx-2"
                      onClick={() => {
                        setTestingType("");
                        setAddConfigDataList([
                          {
                            parameter_id: "",
                            sampling_min_value: "",
                            sampling_max_value: "",
                            standard: "",
                          },
                        ]);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      size="small"
                      type="submit"
                      disabled={testingType === ""}
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Grid>
        )}
        {!show_table && (
          <Grid container spacing={1}>
            <Grid item xs={12} lg={12}>
              <Stack
                direction="row"
                spacing={1}
                justifyContent="end"
                alignItems="center"
              >
                {!show_form && (
                  <Tooltip title="Add Sample Config">
                    <IconButton onClick={() => set_show_form(!show_form)}>
                      <AddIcon color="primary" style={{ fontSize: "35px" }} />
                    </IconButton>
                  </Tooltip>
                )}
                <Input.Search
                  Placeholder="Search here..."
                  className="my-1"
                  size="large"
                  style={{ width: "15%" }}
                  onSearch={(value) => {
                    setSearchvalue(value);
                  }}
                  onChange={(e) => setSearchvalue(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Table
                dataSource={sampleConfigData}
                columns={sampleConfigDetailColumns}
                //scroll={{ x: 940, y: 410 }}
                size="small"
                expandable={{
                  expandedRowRender,
                  //defaultExpandedRowKeys: ['0'],
                }}
                bordered
                pagination={true}
              />
            </Grid>
          </Grid>
        )}
        <Modal
          title="Add Testing Types"
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Add"
          okButtonProps={{
            disabled: !add_name,
          }}
        >
          <OutlinedInput
            type="text"
            placeholder="Add Testing Name"
            fullWidth
            value={add_name}
            onChange={(e) => set_add_name(e.target.value)}
          />
        </Modal>

        <ConfigModal
          show_modal={show_modal}
          set_show_modal={set_show_modal}
          add_number={add_number}
          set_add_number={set_add_number}
          get_status={get_status}
          set_get_status={set_get_status}
        />
      </Grid>
    </MainCard>
  );
}

export default SampleConfig;
