import React, { useEffect, useState, useRef } from "react";
import {
  bp_add_meta_data,
  bp_get_role,
  bp_product_data,
  get_api_function,
  bp_add_product_data,
} from "../../../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Modal, Tooltip, Select } from "antd";
import AddIcon from "@mui/icons-material/Add";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  IconButton,
  Grid,
  Checkbox,
  FormGroup,
  FormControlLabel,
  MenuItem,
  Button,
} from "@mui/material";
import MainCard from "components/MainCard";
import { useDispatch, useSelector } from "react-redux";
import { settab_number } from "store/reducers/tabNumber";

function RecipeMetaData() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [product, set_product_id] = useState("");
  const [productname, setproductname] = useState("");
  const [machine_id, set_machine_id] = useState();
  const [machine_data, set_machine_data] = useState([]);
  const [productDesc, setproductDesc] = useState("");
  const [product_list, set_product_list] = useState([]);
  const formRef = useRef();
  const [rolesList, setRoleList] = useState([]);
  const [recipeRollList, setRecipeRollList] = useState([]);
  const [show, set_show] = useState(false);
  const [machines, setMachines] = useState([]);
  //Roll list Change
  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      // Checkbox is checked, add the role_id to the list
      setRecipeRollList([...recipeRollList, { role_id: id }]);
    } else {
      // Checkbox is unchecked, remove the role_id from the list
      setRecipeRollList(recipeRollList.filter((item) => item.role_id !== id));
    }
  };
  const [receipeMetaData, setReceipeMetaData] = useState({
    product_id: "",
    recipe_name: "",
    min_quantity: "",
    max_quantity: "",
    actual_quantity: "",
    description: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    add_MetaData({
      ...receipeMetaData,
      ...(recipeRollList?.length > 0
        ? {
          process_roles: recipeRollList?.map((obj) => obj.role_id).join(","),
        }
        : {}),
      machine_id: machines?.join(',')
    });
  };

  const handleAddProduct = (e) => {
    e.preventDefault();
    add_product({ name: productname, description: productDesc });
  };

  //####################### Handle Api's######################################################

  const add_MetaData = async (metaData) => {
    try {
      await bp_add_meta_data(metaData, success_add_metadata);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_add_metadata = (res) => {
    console.log(res);
    formRef.current.reset();
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      // dispatch(settab_number("2"));
      formRef.current.reset();

      // set_recipe_md_id(res.recipe_id)
      localStorage.setItem("recipe_md_id", res.recipe_id);
      localStorage.setItem("recipe_name", receipeMetaData.recipe_name);
      setReceipeMetaData({
        product_id: "",
        recipe_name: "",
        min_quantity: "",
        max_quantity: "",
        actual_quantity: "",
        description: "",
      });
      navigate("/addRecipeForm");
      localStorage.setItem("last_process_op_id", 0);
      toast.success("Metadata added Successfully");
    }
  };

  const success_Role_List = (res) => {
    if (res?.data?.data) {
      const data = res?.data?.data;
      const rolesToFilter = ["QA", "Production Manager", "R&D"];
      const filteredList = data?.filter((item) =>
        rolesToFilter.includes(item.role)
      ); // to get exact rolls
      setRoleList(filteredList);
    }
  };

  const add_product = async (productReq) => {
    try {
      await bp_add_product_data(productReq, success_add_product);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_add_product = (res) => {
    console.log(res);
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setproductname("");
      setproductDesc("");
      toast.success(res.message);
      set_show(false);
      try {
        bp_product_data(success_bp_product_data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const resetFunction = () => {
    setReceipeMetaData({
      product_id: "",
      recipe_md_name: "",
      description: "",
      recipe_name: "",
      min_quantity: "",
      max_quantity: "",
      actual_quantity: "",
    });
  };

  useEffect(() => {
    try {
      bp_get_role(success_Role_List);
      bp_product_data(success_bp_product_data);
      get_api_function("/get_all_machine_list", success_bp_machine_data);
    } catch (error) {
      console.log(error);
    }
  }, []);
  const success_bp_machine_data = (res) => {
    if (res.data.status !== "failed") set_machine_data(res?.data?.data);
  };

  const success_bp_product_data = (res) => {
    set_product_list(res?.data?.data);
  };

  return (
    <>
      <MainCard title="Recipe MetaData">
        <form ref={formRef} onSubmit={handleSubmit}>
          <Grid container spacing={2} style={{ display: "flex" }}>
            <Grid item xs={12} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="product">Product</InputLabel>
                <Stack direction="row">
                  <Select
                    showSearch
                    allowClear
                    size="large"
                    placeholder="Select Product"
                    labelRender={(props) => {
                      return props?.label?.props?.children;
                    }}
                    optionFilterProp="children"
                    value={product || null}
                    onChange={(value) => {
                      set_product_id(value);
                      setReceipeMetaData({
                        ...receipeMetaData,
                        product_id: value,
                      });
                    }}
                    filterOption={(input, option) =>
                      (option?.label2 ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    options={product_list?.map((item) => {
                      return {
                        value: item?.product_data_id,
                        label: (
                          <div
                            style={{
                              maxWidth: "100%",
                              wordWrap: "break-word",
                              whiteSpace: "normal",
                            }}
                          >
                            {item?.product_name}
                          </div>
                        ),
                        label2: item?.product_name,
                      };
                    })}
                  />
                  <Tooltip title={"Add Product"}>
                    <IconButton
                      onClick={() => {
                        set_show(true);
                      }}
                    >
                      <AddIcon color="success" style={{ fontSize: "33px" }} />
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="title">Name</InputLabel>
                <OutlinedInput
                  id="title"
                  type="text"
                  size="medium"
                  name="name"
                  placeholder="Enter Name"
                  fullWidth
                  // value={receipeMetaData["recipe_md_name"]}
                  required
                  onChange={(e) =>
                    setReceipeMetaData({
                      ...receipeMetaData,
                      recipe_name: e.target.value,
                    })
                  }
                />
              </Stack>
            </Grid>

            <Grid item xs={12} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="des">Description</InputLabel>
                <OutlinedInput
                  id="des"
                  type="text"
                  size="medium"
                  name="des"
                  placeholder="Description"
                  fullWidth
                  // value={receipeMetaData["description"]}
                  required
                  onChange={(e) =>
                    setReceipeMetaData({
                      ...receipeMetaData,
                      description: e.target.value,
                    })
                  }
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Stack spacing={1}>
                <InputLabel htmlFor="batch">Batch Quantity</InputLabel>
                <Stack direction="row" spacing={2}>
                  <OutlinedInput
                    id="createdOn"
                    type="number"
                    size="medium"
                    name="batch"
                    placeholder="Min"
                    fullWidth
                    value={receipeMetaData["min_quantity"]}
                    required
                    inputProps={{ min: 0 }}
                    onChange={(e) => {
                      setReceipeMetaData({
                        ...receipeMetaData,
                        min_quantity: e.target.value,
                      });
                    }}
                  />
                  <OutlinedInput
                    id="createdOn"
                    type="number"
                    size="small"
                    name="batch"
                    placeholder="Max"
                    inputProps={{ min: receipeMetaData.min_quantity }}
                    fullWidth
                    value={receipeMetaData["max_quantity"]}
                    required
                    onChange={(e) => {
                      setReceipeMetaData({
                        ...receipeMetaData,
                        max_quantity: e.target.value,
                      });
                    }}
                  />
                  <OutlinedInput
                    id="createdOn"
                    type="number"
                    size="small"
                    inputProps={{
                      min: receipeMetaData.min_quantity,
                      max: receipeMetaData.max_quantity,
                    }}
                    name="batch"
                    placeholder="Default"
                    fullWidth
                    value={receipeMetaData["actual_quantity"]}
                    required
                    onChange={(e) => {
                      setReceipeMetaData({
                        ...receipeMetaData,
                        actual_quantity: e.target.value,
                      });
                    }}
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={5.5}>
              <Stack spacing={1}>
                <InputLabel htmlFor="machine">Machine</InputLabel>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Select
                    labelId="machine"
                    id="machine"
                    mode="multiple"
                    name="machine"
                    fullWidth
                    placeholder="Select Machine"
                    value={machines}
                    onChange={(value) => {
                      setMachines(value);
                    }}
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    variant="borderless"
                    size="large"
                  >
                    {machine_data?.map((machine) => (
                      <MenuItem value={machine.machine_id}>
                        {machine.machine_name}
                      </MenuItem>
                    ))}
                  </Select>

                  <Stack direction="row" alignItems="center" spacing={2}>
                    <InputLabel htmlFor="title">Approval: </InputLabel>
                    {rolesList?.map((data) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            //  checked={oneTimeChecked}
                            onChange={(e) =>
                              handleCheckboxChange(e, data.role_id)
                            }
                          />
                        }
                        label={
                          data.role === "Production Manager"
                            ? "Production"
                            : data.role
                        }
                      // disabled={frequencyChecked}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Button
                  size="medium"
                  type="reset"
                  variant="contained"
                  color="primary"
                  onClick={resetFunction}
                  className="mx-2"
                >
                  Reset
                </Button>
                <Button
                  size="medium"
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  Submit
                </Button>
              </div>
            </Grid>
          </Grid>
        </form>
        <Modal
          open={show}
          onCancel={() => set_show(false)}
          title="Product"
          footer={[]}
        >
          <div className="mt-4">
            <form ref={formRef} onSubmit={handleAddProduct}>
              <Grid container spacing={2} style={{ display: "flex" }}>
                <Grid item xs={12} lg={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="title">Product Name</InputLabel>
                    <OutlinedInput
                      id="title"
                      size="small"
                      type="text"
                      name="email"
                      placeholder="Enter Product Name"
                      fullWidth
                      value={productname}
                      required
                      onChange={(e) => setproductname(e.target.value)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Stack spacing={1}>
                    <InputLabel htmlFor="title">Description</InputLabel>
                    <OutlinedInput
                      id="title"
                      size="small"
                      type="text"
                      name="email"
                      placeholder="Enter Description"
                      fullWidth
                      value={productDesc}
                      required
                      onChange={(e) => setproductDesc(e.target.value)}
                    />
                  </Stack>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                    }}
                  >
                    <Button
                      size="small"
                      type="reset"
                      variant="contained"
                      color="primary"
                      className="mx-2"
                      onClick={() => {
                        setproductname("");
                        setproductDesc("");
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      size="small"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Submit
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </form>
          </div>
        </Modal>
      </MainCard>
    </>
  );
}

export default RecipeMetaData;
