import Axios from "axios";

export const login_api = async (username, password, success) => {
  const response = await fetch(`/api/token/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
    // console.log("success")
  } else if (response.status === 500) {
    console.log("Internal Server Error");
  } else {
    console.log("failed", text, response);
    Object.entries(JSON.parse(text))?.forEach(([key, value]) => {
      console.log("fail");
      success("login failed");
    });
  }
};

export const get_api_function = async (url, success, params, signal) => {
  const token = await localStorage.getItem("Token");
  Axios.get(url, {
    params: params,
    signal: signal,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      success(res);
    })
    .catch((error) => {
      console.error("There was an error!", error);
    });
};

export const post_api_function = async (url, success, data) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const post_formadata_api_function = async (url, success, data) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: data,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const forgot_password_API = async (email, success) => {
  const response = await fetch(`/forgot_password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: email,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else if (response.status === 500) {
    console.log("Internal Server Error");
  } else {
    console.log("failed", text, response);
  }
};

export const set_password_API = async (set_password_data, success) => {
  const response = await fetch(`/set_password`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(set_password_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else if (response.status === 500) {
    console.log("Internal Server Error");
  } else {
    console.log("failed", text, response);
  }
};

export const get_user_details = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/userprofile", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const get_user_role = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_user_role", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

// MACHINE
export const get_all_machineDetails = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/machine_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_Equipment = async (equipmentFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/machine_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: equipmentFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_machine_attributes = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_parameters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_Machine_Attribute_Name = async (parameterNameObj, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_parameters`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(parameterNameObj),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const add_Machine_Attribute = async (machine_Attribute, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`bp_add_machineparameter_value`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(machine_Attribute),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

// TICKET
export const get_all_ticketDetails = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/create_ticket", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_user_all_ticketDetails = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_all_ticket", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_ticket_approval = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_approval", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_created_by_me = async (success, search_item, page_number) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_user_created", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_flow_details = async (success, ticket_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_history", {
    params: { ticket_id: ticket_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const main_dashboard_analysis = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/dashboard_analysis", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const count_analysis_ticket_user = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/analysis_ticket_user", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const count_analysis_ticket_manager = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/analysis_for_manager", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const count_analysis_for_department = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/analysis_for_department", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_issue_types = async (success, issue_type_name) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_issue_type", {
    params: { issue_type: issue_type_name },

    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_locations = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_locations", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_departments = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/departdata", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_shift = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_employee_shift", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_prioritys = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_priority_level", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_Ticket = async (ticketFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/create_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: ticketFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const get_employee_working_status = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/employee_working_status", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_employee_work_status = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_employee_work_status", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_employee_working_status = async (statusFormData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/employee_working_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: statusFormData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_ticket_status = async (
  ticket_id_to_be_updated,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/update_ticket_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      // Accept: "application/json",
      // "Content-Type": "application/json",
    },
    body: ticket_id_to_be_updated,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_workflow_engin = async (workflow_tobe_updated, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/workflow_engin`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: workflow_tobe_updated,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employees_for_assign = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_employee_assign", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_department_approval_data = async (
  success,
  search_item,
  page_number
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/department_approval_data", {
    params: { search_item: search_item, page_number: page_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_issue_type = async (issueType, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_issue_type`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: issueType,
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_roles = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_role", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_roles_config = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_role_config", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_designations = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("bp_get_designation", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_employee = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_employee_user", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_machine_shift = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_machine_shift", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_instruction_status = async (success, ticket_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_instruction_status", {
    params: { ticket_id: ticket_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const reopen_ticket = async (reopen_formdata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/ticket_reopen`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: reopen_formdata,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const return_ticket = async (return_formdata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/return_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: return_formdata,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const ticket_workflow_creation = async (ticketFlow, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/ticket_workflow_creation`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ticket_flow: ticketFlow,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_ticket_workflow_creation = async (success, issue_type_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/ticket_workflow_creation", {
    params: { issue_type_id: issue_type_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const issue_type_dept_config = async (issueTypeDeptConfig, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_issue_depart`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(issueTypeDeptConfig),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_issue_type_dept = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_issue_depart", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const add_employee_user = async (user_data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_employee_user`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employee_user_list = async (success) => {
  const token = await localStorage.getItem("Token");

  // const params = department_id
  //   ? { department_id: department_id }
  //   : designation_id
  //   ? { designation_id: designation_id }
  //   : {};

  Axios.get("bp_get_employee_user", {
    // params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const bp_get_access = async (params, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_access", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data);
  });
};

export const update_access_list_admin = async (
  access_list_to_be_updated,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_access`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: access_list_to_be_updated }),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employee_access_list = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("bp_get_employee_access_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res.data.data);
  });
};

export const add_department = async (department, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/departdata`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(department),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_designation = async (department, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_designation`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(department),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_location = async (location, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/location_config`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(location),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_role = async (role, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_role`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(role),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_employee_role_config = async (success, role_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_employee_role_config", {
    params: { role_id: role_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_employee_role_config1 = async (success, employee_id) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/add_employee_role_config", {
    params: { employee_id: employee_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_role_emp_config = async (role_emp_config, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_employee_role_config`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ data: [role_emp_config] }),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_location_group = async (group_name, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/pm/add_group`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      group: group_name,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_location_groups = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/pm/get_group", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const genarate_pdf_for_ticket = async (success, ticket_id) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/genarate_pdf_for_ticket`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ticket_id: ticket_id,
    }),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

//##############Batch Processing Api's ###################

export const bp_machine_data = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_machine_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_machine_by_machine_typ = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_machine_by_machine_type", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_testing_type = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_testing_type", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_batch_details = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_batch_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_material_data = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_material", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_material_type_data = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("bp_get_material_type", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const add_material_data = async (materialData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_material`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(materialData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_update_starttime_for_batch_number = async (data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_update_starttime_for_batch_number`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const save_simulation_data = async (data, success) => {
  const response = await fetch(`/save_simulation_data`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const update_recipe_value = async (data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_recipe_value`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_save_batch_ingredients = async (data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_save_batch_ingredients`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_save_batch_product_inventory = async (data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_save_batch_product_inventory`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_save_sample_details = async (data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_save_sample_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_save_batch_parameters = async (data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_save_batch_parameters`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_batch_configuration = async (data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_batch_configuration`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_batch_details_by_process_id = async (
  success,
  params,
  signal
) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_batch_details_by_process_id", {
    params: params,
    signal,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((res) => {
      success(res);
    })
    .catch((e) => {
      console.log(e);
    });
};

export const bp_parameters_data = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_parameters", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_batch_report_view = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_batch_report_view", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_recipe_meta_data = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_recipe_with_batch", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_batch_number_by_recipe = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_batch_number_by_recipe", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};


export const get_batch_numbers = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_batch_numbers", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_recipe_list = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_recipe_list", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_batch_process_active = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_batch_process_active", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_sample_detailss = async (params, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_sample_detailss", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_sampling_details_by_sample = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_sampling_details_by_sample", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_recipe_by_product_id = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_recipe_by_product_id", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_process_operation_by_recipe = async (success, params) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_process_operation_by_recipe", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_batch_process_schedule = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_batch_process_schedule", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_supplier_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_supplier_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_inventory_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_material_inventory", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_product_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_product_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_units_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_unit", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_location_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_location", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_approvel_status_for_batch = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_approvel_status_for_batch", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_machine_cat_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_machine_cat_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_recipe_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_recipe_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_recipe_MetaData_ById = async (recipeId, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_recipe_meta_data", {
    params: recipeId ? { recipe_md_id: recipeId } : null,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_process_data_ById = async (processId, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_process_data", {
    params: { process_id: processId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const bp_process_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_process_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_Ingredients = async (recipe_md_id, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_recipe_ingredients_data", {
    params: { recipe_md_id: recipe_md_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const bp_add_product_data = async (productData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/save_product_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(productData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const save_grafana_url = async (urlData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/save_grafana_url`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(urlData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const save_device_details = async (devicedata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/save_device_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(devicedata),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const sensor_configuration = async (devicedata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/sensor_configuration`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(devicedata),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_sensor_list = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_sensor_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_device_details = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_device_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const bp_get_all_Ingredients = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_recipe_ingredients_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_recipeData = async (recipe_md_id, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bp_get_recipe", {
    params: { recipe_md_id: recipe_md_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_weighing_machine_by_category = async (params, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_weighing_machine_by_category", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_add_parameter_data = async (parameterData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_parameters`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(parameterData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const add_new_operation_in_operation = async (configData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_new_operation_in_operation`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const delete_operation_details = async (configData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/delete_operation_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_update_approval_status_for_recipe = async (
  approval_data,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_update_approval_status_for_recipe`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(approval_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const update_batch_recipe_approval_status = async (
  approval_data,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_batch_recipe_approval_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(approval_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const edit_recipe_machine = async (approval_data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/edit_recipe_machine`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(approval_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_add_machine_cat_data = async (catData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_machine_cat_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(catData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_add_supplier_data = async (supplierData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_supplier_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(supplierData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_material_inentory_data = async (inventoryData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_material_inventory`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(inventoryData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const save_manual_qr_code_for_ingredent = async (qr_data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/save_manual_qr_code_for_ingredent`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(qr_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const save_manual_qr_code_for_machine = async (qr_data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/save_manual_qr_code_for_machine`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(qr_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};


export const add_machine_shift = async (shift_data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_machine_shift`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(shift_data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_add_unit_data = async (unitdata, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_unit`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(unitdata),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_add_location_data = async (locationData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_location`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(locationData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_save_employee_shift = async (locationData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_save_employee_shift`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(locationData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_machine_data = async (formData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_machine_data`, {
    method: "POST",

    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {
      console.log("fail");
    });
  }
};

export const ImportIngredentQR = async (formData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/ImportIngredentQR`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {
      console.log("fail");
    });
  }
};

export const Add_Bulk_Machine_Shift = async (formData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/Add_Bulk_Machine_Shift`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {
      console.log("fail");
    });
  }
};

export const bp_add_meta_data = async (metaData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_recipe_meta_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(metaData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_add_ingredient_data = async (ingredienetData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_recipe_ingredients_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(ingredienetData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_add_process_data = async (processData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_process_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(processData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_save_sample_actual_data = async (data, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_save_sample_actual_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_add_recipe_data = async (recipeData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_recipe_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(recipeData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_process_details = async (processData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_save_process_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(processData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_process_details = async (processData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_update_process_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(processData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_product_inv_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_all_process_data", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const getProcessByRecipeId = async (recipe_id, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_all_process_data", {
    params: { recipe_id: recipe_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_save_sampling_config = async (samplingConfigData, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_save_sampling_config`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(samplingConfigData),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_add_testing_type = async (testing_type, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_testing_type`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(testing_type),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_update_process_approval_status = async (
  approvalreq,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_update_process_approval_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(approvalreq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_operationType = async (opTypeReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_add_operation_type`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(opTypeReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const department_data = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/departdata", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_sampling_config_details = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_sampling_config_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_operation_type = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_operation_type", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_true_recipe = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_true_recipe", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_all_testing_type = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_all_testing_type", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_role = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_role", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_batch_process_by_schedule = async (
  batchNo,
  batchId,
  success
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_batch_process_name_by_schedule", {
    params: { batch_number: batchNo, batch_id: batchId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const bp_get_pending_recipe = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_pending_recipe", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_prod_recipe_approval_list = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_prod_recipe_approval_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_batch_approval_details = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_batch_approval_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_approval_recipe = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_approval_recipe", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_update_employee_for_batch_number = async (empreq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_update_employee_for_batch_number`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(empreq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_sensor_status = async (senReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_sensor_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(senReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_save_recipe_status = async (recipe_status_req, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_save_recipe_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(recipe_status_req),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const send_recipe_approval_status = async (
  recipe_status_req,
  success
) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/send_recipe_approval_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(recipe_status_req),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_reject_recipe_status = async (recipe_status_req, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bp_reject_recipe_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(recipe_status_req),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const bp_get_approval_status = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_approval_recipe", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_depart_wise_employee = async (opId, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_depart_wise_employee", {
    params: { operation_id: opId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bp_get_batch_notification_for_employee = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_batch_notification_for_employee", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_notification_for_recipe_approval_action = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_notification_for_recipe_approval_action", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};


export const get_ingredient_value_alert = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_ingredient_value_alert", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};


export const get_batch_recipe_approval = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_batch_recipe_approval", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

//################# BKT #####################////

export const process_configuration = async (process_configuration, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bkt_process_configuration`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(process_configuration),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const bkt_update_process = async (updateConfig, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/bkt_update_process`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateConfig),
  });
  const text = await response.text();
  if (response.status === 200) {
    console.log("success", JSON.parse(text));
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
    Object.entries(JSON.parse(text)).forEach(([key, value]) => {});
  }
};

export const bkt_get_all_process_list = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bkt_get_all_process_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_bkt_check_list_result_table = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_bkt_check_list_result_table", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bkt_get_all_process_configuration = async (proce_id, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bkt_get_all_process_configuration", {
    params: { process_id: proce_id },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bkt_get_completed_process_list = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bkt_get_completed_process_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const bkt_get_pending_process_list = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/bkt_get_pending_process_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_checklist_names = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_checklist_names", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_running_batch_details = async (filter, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_running_batch_details", {
    params: filter,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_schedule_batch_details = async (filter, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_schedule_batch_details", {
    params: filter,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_completed_batch_details = async (filter, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_completed_batch_details", {
    params: filter,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_checklist_data_by_id = async (checklistId, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_checklist_data_by_id", {
    params: { checklist_id: checklistId },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_batch_report_pdf = async (batch_number, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_batch_report_pdf", {
    params: { batch_number: batch_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_pidilite_pdf_report = async (batch_number, success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_pidilite_pdf_report", {
    params: { batch_number: batch_number },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const config_weighing_machine = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/config_weighing_machine`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_machine_room = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_machine_room`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const save_machine_category = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/save_machine_category`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const update_schedule_time = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_schedule_time`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const delete_batch_details = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/delete_batch_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
 
export const store_sample_detail_status = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/store_sample_detail_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const copy_recipe_data = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/copy_recipe_data`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const copy_batch_details = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/copy_batch_details`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const resampling_sample = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/resampling_sample`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_new_operation_in_recipe = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_new_operation_in_recipe`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const edit_operation = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/edit_operation`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};
export const update_recipe_approval_status = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_recipe_approval_status`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const delete_recipe = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/delete_recipe`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const update_all_employee_for_process = async (success, configReq) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/update_all_employee_for_process`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_user_code = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_user_code`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const add_sensor_type = async (configReq, success) => {
  const token = await localStorage.getItem("Token");
  const response = await fetch(`/add_sensor_type`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(configReq),
  });
  const text = await response.text();
  if (response.status === 200) {
    success(JSON.parse(text));
  } else {
    console.log("failed", text);
  }
};

export const get_sensor_type = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_sensor_type", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_machine_category = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_machine_category", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_room_name = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_room_name", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_user_code = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_user_code", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_bill_of_materials = async (params, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_bill_of_materials", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_final_recipe_list = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_all_recipe_details", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_weighing_machine_list = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_weighing_machine_list", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const get_grafana_url = async (success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_grafana_url", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const getting_employee_log = async (params, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/getting_employee_log", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_operation_alert = async (success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_operation_alert", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_recipe_approval = async (params,success) => {
  const token = await localStorage.getItem("Token");
  Axios.get("/get_recipe_approval", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_batch_copy_details = async (params, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_batch_copy_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_all_operation_details_by_operation = async (
  params,
  success
) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_all_operation_details_by_operation", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
export const bp_get_all_process_data = async (params, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/bp_get_all_process_data", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_batch_changes_details = async (params, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_batch_changes_details", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_material_balance = async (params, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_material_balance", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_all_process_data_for_copy_recipe = async (params, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_all_process_data_for_copy_recipe", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};

export const get_recipe_details_by_recipe_id = async (params, success) => {
  const token = await localStorage.getItem("Token");

  Axios.get("/get_recipe_details_by_recipe_id", {
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => {
    success(res);
  });
};
//################# BKT #####################////
