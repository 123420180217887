import React, { useState, useEffect } from "react";

// import { DashboardOutlined } from '@ant-design/icons';
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CasinoOutlinedIcon from "@mui/icons-material/CasinoOutlined";
import ConfirmationNumberOutlinedIcon from "@mui/icons-material/ConfirmationNumberOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import SchemaOutlinedIcon from "@mui/icons-material/SchemaOutlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import ApprovalIcon from "@mui/icons-material/Approval";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SpeedIcon from "@mui/icons-material/Speed";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import WorkHistoryIcon from "@mui/icons-material/WorkHistoryOutlined";
import ScienceOutlinedIcon from "@mui/icons-material/ScienceOutlined";
import { ControlOutlined } from "@ant-design/icons";
import { accessControlUtils } from "components/AccessControlUtils";
import CategoryIcon from "@mui/icons-material/Category";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import MuseumIcon from "@mui/icons-material/Museum";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import SensorsIcon from "@mui/icons-material/Sensors";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import {
  // DashboardOutlined,
  ChromeOutlined,
  QuestionOutlined,
  FileTextOutlined,
  AuditOutlined,
  GoldOutlined,
} from "@ant-design/icons";
// icons
const icons = {
  DashboardOutlinedIcon,
  PrecisionManufacturingIcon,
  CategoryIcon,
  SchemaOutlinedIcon,
  CasinoOutlinedIcon,
  ConfirmationNumberOutlinedIcon,
  CategoryOutlinedIcon,
  PersonOutlineIcon,
  SensorsIcon,
  SettingsOutlinedIcon,
  PermDataSettingIcon,
  AssignmentTurnedInOutlinedIcon,
  EventAvailableIcon,
  LocalPoliceIcon,
  SpeedIcon,
  BookmarkAddedIcon,
  ApprovalIcon,
  NotificationsActiveIcon,
  IntegrationInstructionsIcon,
  MuseumIcon,
  ArticleIcon,
  ContentPasteIcon,
  WorkHistoryIcon,
  AcUnitIcon,
  ScienceOutlinedIcon,
  AuditOutlined,
  ControlOutlined,
};

// ==============================|| MENU ITEMS ||============================== //

function Dashboard() {
  const [access_list_data, setaccess_list_data] = useState([]);
  const [refresh, set_refresh] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem("user_access_data");
    if (storedData) {
      setaccess_list_data(JSON.parse(storedData));
    } else {
      set_refresh(!refresh);
    }
  }, [refresh]);

  return [
    {
      id: "group-dashboard",
      title: "Menu",
      type: "group",
      children: [
        {
          id: "DashBoard",
          title: "DashBoard",
          type: "item",
          icon: icons.SchemaOutlinedIcon,
          // url: "http://89.116.20.193:8088/superset/dashboard/11",
          //target: "_blank", // Open in a new tab
          url: "/dash",
          breadcrumbs: true,
          showMenu: accessControlUtils("Dashboard")?.read_access,
        },
        {
          id: "Recipe",
          title: "Recipe",
          type: "item",
          icon: icons.DashboardOutlinedIcon,
          url: "/viewRecipe",
          breadcrumbs: true,
          showMenu: accessControlUtils("Recipe")?.read_access,
        },
        {
          id: "Batches",
          title: "Batches",
          type: "item",
          icon: icons.ContentPasteIcon,
          url: "/batches",
          breadcrumbs: true,
          showMenu: accessControlUtils("Batches")?.read_access,
        },
        {
          id: "result",
          title: "Result",
          type: "item",
          icon: icons.ArticleIcon,
          url: "/result",
          breadcrumbs: true,
          showMenu: accessControlUtils("Result")?.read_access,
        },
        {
          id: "batch-approval",
          title: "Batch Approval",
          type: "item",
          icon: icons.ApprovalIcon,
          url: "/batch-approval",
          breadcrumbs: true,
          showMenu: accessControlUtils("Batch Approval")?.read_access,
        },
        {
          id: "recipe-approval",
          title: "Recipe Approval",
          type: "item",
          icon: icons.BookmarkAddedIcon,
          url: "/recipe-approval",
          breadcrumbs: true,
          showMenu: accessControlUtils("Recipe Approval")?.read_access,
        },
        {
          id: "sample_testing",
          title: "Sample Testing",
          type: "item",
          icon: icons.ScienceOutlinedIcon,
          url: "/sample-testing",
          breadcrumbs: true,
          showMenu: accessControlUtils("Sample Testing")?.read_access,
        },
        {
          id: "notification",
          title: "Notifications & Alerts",
          type: "item",
          url: "/notifications",
          icon: icons.NotificationsActiveIcon,
          breadcrumbs: true,
          showMenu: false,
        },
        {
          id: "userConfig",
          title: "Users",
          type: "item",
          icon: icons.PersonOutlineIcon,
          url: "/userConfig",
          breadcrumbs: true,
          showMenu: accessControlUtils("User Configuration")?.read_access,
        },
        {
          id: "audit-report",
          title: "Audit Report",
          type: "item",
          icon: icons.AuditOutlined,
          url: "/audit-report",
          breadcrumbs: true,
          showMenu: accessControlUtils("Audit Report")?.read_access,
        },
        {
          id: "configuration",
          title: "Configuration",
          type: "collapse",
          icon: icons.SettingsOutlinedIcon,
          breadcrumbs: true,
          showMenu: accessControlUtils("Configuration")?.read_access,
          children: [
            {
              id: "machine",
              title: "Machine",
              type: "item",
              icon: icons.CategoryIcon,
              url: "/machine-config",
              breadcrumbs: true,
              showMenu: accessControlUtils("Configuration")?.read_access,
            },
            {
              id: "parameter",
              title: "Parameter",
              type: "item",
              icon: icons.AcUnitIcon,
              url: "/param-config",
              breadcrumbs: true,
              showMenu: accessControlUtils("Configuration")?.read_access,
            },
            {
              id: "material",
              title: "Material",
              type: "item",
              icon: icons.MuseumIcon,
              url: "/material-config",
              breadcrumbs: true,
              showMenu: accessControlUtils("Configuration")?.read_access,
            },
            {
              id: "product",
              title: "Product",
              type: "item",
              icon: GoldOutlined,
              url: "/prod-config",
              breadcrumbs: true,
              showMenu: accessControlUtils("Configuration")?.read_access,
            },
            {
              id: "equipment",
              title: "Equipment",
              type: "item",
              icon: icons.PrecisionManufacturingIcon,
              url: "/equipment-config",
              breadcrumbs: true,
              showMenu: accessControlUtils("Configuration")?.read_access,
            },
            {
              id: "sensor",
              title: "Sensor",
              type: "item",
              icon: icons.SensorsIcon,
              url: "/sensor",
              breadcrumbs: true,
              showMenu: accessControlUtils("Configuration")?.read_access,
            },
            {
              id: "qualitycontrol",
              title: "Quality Control",
              type: "item",
              icon: icons.SettingsOutlinedIcon,
              url: "/sample-config",
              breadcrumbs: true,
              showMenu: accessControlUtils("Configuration")?.read_access,
            },
            {
              id: "dashboardUrl",
              title: "URL Config",
              type: "item",
              icon: icons.IntegrationInstructionsIcon,
              url: "/url-config",
              breadcrumbs: true,
              showMenu: accessControlUtils("Dashboard URL")?.read_access,
            },
            {
              id: "licence-config",
              title: "Licence Config",
              type: "item",
              icon: icons.LocalPoliceIcon,
              url: "/licence-config",
              breadcrumbs: true,
              showMenu: accessControlUtils("Licence")?.read_access,
            },
          ],
        },
        {
          id: "shift-details",
          title: "Shift Details",
          type: "item",
          icon: icons.WorkHistoryIcon,
          url: "/shift-details",
          breadcrumbs: true,
          showMenu: accessControlUtils("Shift Details")?.read_access,
        },
        {
          id: "Access Control",
          title: "Access Control",
          type: "item",
          icon: icons.ControlOutlined,
          url: "/accessControl",
          breadcrumbs: true,
          showMenu: accessControlUtils("Access Control")?.read_access,
        },
      ].filter(Boolean),
    },
  ];
}

export default Dashboard;
