import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import {
  Typography,
  Grid,
  Box,
  Tab,
  ButtonGroup,
  Stack,
  Chip,
  OutlinedInput,
  InputLabel,
  MenuItem,
  Tooltip,
  IconButton,
  InputAdornment,
  Button,
} from "@mui/material";
import { Table, Input, Modal, Select, Popconfirm, Collapse, Panel } from "antd";
import MainCard from "components/MainCard";
import { accessControlUtils } from "components/AccessControlUtils";
import { useLocation } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import BackspaceIcon from "@mui/icons-material/Backspace";
import {
  get_recipe_details_by_recipe_id,
  delete_operation_details,
} from "../../../utils/api";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableInModal from "components/TableInModal";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

function EditProcess() {
  const controllerRef = useRef();
  const accessData = accessControlUtils("EditProcess");
  const editProcess = JSON.parse(window?.localStorage?.getItem("editProcess"));
  const [recipeData, setRecipeData] = useState([]);
  const navigate = useNavigate();
  const [searchvalue, setSearchvalue] = useState("");
  const location = useLocation();
  const { processId, processName, recipeName, recipeId } = location.state || {};

  const deleteConfirm = (record) => {
    const req = {
      operation_id: record?.operation_id,
      process_id: processId || "",
    };
    delete_operation(req);
  };
  //For collapse open at a time only one row
  const addKeysInFilterDataForCollapse = (filterData) => {
    const updatedFilterList = filterData?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return updatedFilterList;
  };

  //############### Handle API's ##################

  const get_recipe_details = (recipe) => {
    try {
      get_recipe_details_by_recipe_id(
        {
          recipe_id: parseInt(recipe),
        },
        success_get_recipe
      );
    } catch (error) {
      console.log(error);
    }
  };

  const success_get_recipe = (res) => {
    console.log(res);

    if (res?.data?.status === "success") {
      toast.success(res?.message);
      if (
        res?.data?.data[0]?.process_list &&
        res?.data?.data[0]?.process_list?.length > 0
      ) {
        const operationData = res?.data?.data[0]?.process_list?.find(
          (data) => data?.process_id === processId
        );
        setRecipeData(
          addKeysInFilterDataForCollapse(operationData?.operations)
        );
      }
    } else {
      toast.warning(res?.message);
    }
  };

  const delete_operation = async (req) => {
    try {
      await delete_operation_details(req, successDeleteOperation);
    } catch (error) {
      console.log(error);
    }
  };

  const successDeleteOperation = (res) => {
    console.log(res);
    if (res?.status === "success") {
      toast.success(res?.message);
      get_recipe_details(recipeId);
    } else {
      toast.warning(res?.message);
    }
  };

  const cols = [
    {
      title: "Operation Number",
      dataIndex: "operation_number",
      key: "title",
      align: "center",
    },
    {
      title: "Operation Name",
      dataIndex: "operation_name",
      key: "title",
      align: "center",
    },
    {
      title: "Operation Type",
      dataIndex: "operation_type",
      key: "title",
      align: "center",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "status",
      align: "center",
      width: "30%",
      render: (_, record, index) => {
        return (
          <Stack
            direction="row"
            spacing={-1}
            alignItems="center"
            justifyContent="center"
          >
            <Tooltip title={"Edit Operation"}>
              <IconButton
                size="medium"
                variant="outline"
                color="primary"
                disabled={record?.status === "Completed"}
                onClick={() => {
                  const stateparam = {
                    operation_id: record?.operation_id,
                    process_id: processId,
                    recipe_name: recipeName,
                    process_name: processName,
                    edit_recipe_id: recipeId,
                    fromDag: "",
                  };
                  window?.localStorage?.setItem(
                    "editOperation",
                    JSON.stringify(stateparam)
                  );
                  navigate("/edit-operation", {
                    state: stateparam,
                  });
                }}
              >
                <BorderColorIcon
                  sx={{
                    color:
                      record?.status === "Completed"
                        ? "secondary.main"
                        : "orange",
                  }}
                />
              </IconButton>
            </Tooltip>
            <Popconfirm
              title={"Are you sure you want to add new Operation"}
              onConfirm={() => {
                const stateparam = {
                  editProcess: processId,
                  editProcessName: processName,
                  recipeName: recipeName,
                  recipeId: recipeId || localStorage?.getItem("editProcess"),
                  level: record?.operation_number + 1,
                };
                window?.localStorage?.setItem(
                  "newOperation",
                  JSON.stringify(stateparam)
                );
                navigate("/new-operation", {
                  state: stateparam,
                });
              }}
              onCancel={() => {
                if (record?.operation_number === 1) {
                  const stateparam = {
                    editProcess: processId,
                    editProcessName: processName,
                    recipeName: recipeName,
                    recipeId: recipeId || localStorage?.getItem("editProcess"),
                    level: record?.operation_number,
                  };
                  window?.localStorage?.setItem(
                    "newOperation",
                    JSON.stringify(stateparam)
                  );
                  navigate("/new-operation", {
                    state: stateparam,
                  });
                }
              }}
              okText={record?.operation_number === 1 ? "After" : "Yes"}
              cancelText={record?.operation_number === 1 ?"Before" : "No"}
            >
              <Tooltip title={"Add New"}>
                <IconButton
                  size="medium"
                  variant="outline"
                  disabled={record?.status === "Completed"}
                  color="primary"
                >
                  <AddCircleIcon
                    sx={{
                      color:
                        record?.status === "Completed"
                          ? "secondary.main"
                          : "green",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Popconfirm>
            <Popconfirm
              title="Are you sure you want to delete this Operation?"
              onConfirm={() => deleteConfirm(record)}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip title={"Delete Operation"}>
                <IconButton size="medium" variant="outline" color="primary">
                  <DeleteForeverIcon
                    sx={{
                      color:
                        record?.status === "Completed"
                          ? "secondary.main"
                          : "red",
                    }}
                  />
                </IconButton>
              </Tooltip>
            </Popconfirm>
          </Stack>
        );
      },
    },
  ];

  useEffect(() => {
    if (recipeId || editProcess?.recipeId) {
      get_recipe_details(recipeId ? recipeId : editProcess?.recipeId);
    }
  }, []);

  return (
    <>
      <MainCard>
        <Grid container spacing={1} style={{ display: "flex" }}>
          <Grid item container lg={12} xs={12}>
            <Grid item xs={6}>
              <span>
                <b>Recipe Name:</b> &nbsp;
                <span style={{ color: "#cc0000" }}>
                  {recipeName ? recipeName : editProcess?.recipeName}
                </span>
              </span>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton
                onClick={() => {
                  navigate("/viewRecipe", {
                    state: {
                      tabId: "2",
                      editRecipeId: recipeId ? recipeId : editProcess?.recipeId,
                    },
                  });
                }}
              >
                <BackspaceIcon color="primary" sx={{ fontSize: "30px" }} />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <span>
                <b>Process Name:</b> &nbsp;
                <span style={{ color: "#cc0000" }}>
                  {processName ? processName : editProcess?.processName}
                </span>
              </span>
            </Grid>
          </Grid>
          <Grid xs={12} lg={12} item>
            <Stack
              direction="row"
              justifyContent="end"
              alignItems="center"
              spacing={1}
              style={{ marginTop: "-20px" }}
            >
              <Input.Search
                Placeholder="Search here..."
                size="large"
                style={{ width: "15%" }}
                onSearch={(value) => {
                  setSearchvalue(value);
                }}
                onChange={(e) => setSearchvalue(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} lg={12}>
            <Table
              dataSource={recipeData}
              columns={cols}
              pagination={true}
              showHeader={true}
              bordered
            />
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}

export default EditProcess;
