import React, { useEffect, useState, useRef } from "react";
import {
  get_api_function,
  get_prod_recipe_approval_list,
  update_recipe_value,
} from "../../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Pagination,
  Input,
  Modal,
  Timeline,
  Typography as Typography1,
  Tag,
  Popconfirm,
  Collapse,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { settab_number } from "store/reducers/tabNumber";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableInModal from "components/TableInModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import CloseIcon from "@mui/icons-material/Close";
import { ClockCircleOutlined, CheckCircleOutlined } from "@ant-design/icons";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Chip,
  Select,
  MenuItem,
  FormControlLabel,
  Grid,
  InputAdornment,
  IconButton,
  FormHelperText,
  Button,
  Checkbox,
  Tooltip,
  FormGroup,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import InfoComment from "components/InfoComment";

function ProdRecipeApproval() {
  const formRef = useRef();
  const [searchvalue, setSearchvalue] = useState("");
  const [recipe_id, set_recipe_id] = useState("");
  const [metadataList, setMetadataList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const userDetails = localStorage.getItem("user_details");
  const [selectedprocess, setSelectedProcess] = useState("");
  const [operationDetails, set_operationDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [recipeShow, setRecipeShow] = useState(false);
  const [recipe_name, set_recipe_name] = useState("");
  const [aprLoading, set_aprloading] = useState(false);
  const [current_recipe_expand, set_current_recipe_expand] = useState();
  const { Panel } = Collapse;
  const loginRole = userDetails
    ? JSON.parse(userDetails).roles?.map((role) => role.role_id)
    : [];
  const [current_expand, set_current_expand] = useState();
  const [expanded, set_expanded] = useState([]);
  const [recipeRecord, setRecipeRecord] = useState([]);
  const confirm = (recipeId) => {
    setRecipeShow(true);
    set_recipe_id(recipeId);
  };
  const handleRecipeCancel = () => {
    setRecipeShow(false);
    set_recipe_name("");
    set_recipe_id("");
  };
  const handleRecipeOk = () => {
    if (recipe_id && recipe_name) {
      move_recipe_to_production(recipe_id, recipe_name);
    }
  };

  const columns = [
    {
      title: "Recipe Name",
      dataIndex: "recipe_name",
      key: "title",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return String(record?.recipe_name)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      align: "center",
    },
    {
      title: "Created On",
      dataIndex: "created_on",
      key: "created_on",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return String(record?.created_on)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
      align: "center",
      render: (data) => data?.slice(0, 16),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (data, record, index) => {
        return (
          <IconButton>
            <Popconfirm
              title="Are you sure to approve recipe for Production ?"
              onConfirm={() => confirm(record?.recipe_id)}
              okText="Yes"
              cancelText="No"
            >
              <ThumbUpIcon color="primary" sx={{ fontSize: "28px", mr: -1 }} />
            </Popconfirm>
          </IconButton>
        );
      },
      align: "center",
    },
  ];

  const operationCols = [
    {
      title: "Operation Number",
      dataIndex: "operation_number",
      key: "Operation",
      align: "center",
    },
    {
      title: "Operation",
      dataIndex: "operation_type",
      key: "operation_type",
      align: "center",
      render: (data, record, index) => {
        const ingred = ingredientStrings(record.ingredients, "operation");

        return (
          <div style={{ textAlign: "left" }}>
            <p>
              <strong> Operation Type: </strong>
              {`${data}, `} <br />
              {record?.instruction?.length > 0 && (
                <>
                  <strong>Instructions:</strong>
                  <Tooltip title="View Instructions">
                    <IconButton
                      onClick={() => {
                        Modal.info({
                          title: "Instructions",
                          content: (
                            <ul>
                              {record?.instruction?.map(
                                (instruction, index) => (
                                  <li key={index}>{instruction}</li>
                                )
                              )}
                            </ul>
                          ),
                        });
                      }}
                      disabled={record?.instruction?.length === 0}
                    >
                      <VisibilityIcon
                        color="info"
                        style={{ fontSize: "21px" }}
                      />
                    </IconButton>
                  </Tooltip>
                  <br />
                </>
              )}
              {record?.dependencie && (
                <>
                  <strong> Depending on Operations: </strong>
                  <span>{`${record?.dependencie}`}</span> <br />
                </>
              )}
              {ingred && ingred.length > 0 && (
                <>
                  <strong> Ingredients: </strong>
                  {ingred.join("\n")}
                </>
              )}
            </p>
          </div>
        );
      },
    },
    {
      title: "Equipment",
      dataIndex: "machine_type",
      align: "center",
      key: "machine_type",
      render: (data, record, index) => {
        return data && data.includes(",") ? (
          <Tooltip title="View Machines">
            <IconButton
              onClick={() => {
                Modal.info({
                  title: "Machines",
                  content: (
                    <ul>
                      {record?.machine_type?.split(",")?.map((type, index) => (
                        <li key={index}>{type}</li>
                      ))}
                    </ul>
                  ),
                });
              }}
              disabled={record?.machine_type?.length === 0}
            >
              <VisibilityIcon color="info" style={{ fontSize: "21px" }} />
            </IconButton>
          </Tooltip>
        ) : (
          <span>{data}</span>
        );
      },
    },
    {
      title: "Duration",
      dataIndex: "max_duration",
      key: "max_duration",
      align: "center",
    },
    {
      title: "Details / Remarks",
      dataIndex: "products",
      key: "process_products",
      width: "25%",
      align: "center",
      render: (data, record, index) => {
        const ingred = ingredientStrings(record?.ingredients, "");
        console.log(ingred);

        let mul = "X Batch Size";
        let perc = "% of Batch Size";
        return (
          <div style={{ textAlign: "left" }}>
            {ingred.length > 0 && (
              <>
                <strong> Ingredients: </strong>

                {/* {ingred?.map((data, index) => (
                      <div key={index}>{data}</div>
                    ))} */}
                <div className="mb-4">
                  {ingred?.map((data, index) => {
                    return (
                      <>
                        <div key={index}>
                          <strong>{`${index + 1}.  `}</strong>
                          {data?.split("-")?.length > 2
                            ? `${data?.split("-")[0]}-${data?.split("-")[1]}`
                            : data?.split("-")[0]}
                          {record?.ingredients[index]?.is_percentage
                            ? "" + perc
                            : record?.ingredients[index]?.material_unit + mul}
                          <InfoComment
                            title={"Instruction"}
                            content={record?.ingredients[index]?.instruction}
                          />
                        </div>
                        {data?.split("-")[1] &&
                        data?.split("-")[1] !== "" &&
                        data?.split("-")[1].length !== 1 &&
                        !(data?.split("-")?.length > 2) ? (
                          <div key={index}>
                            <strong>
                              {data?.split("-")[1]?.split(":")[0]}
                              {":"}
                            </strong>
                            {data?.split("-")[1]?.split(":")[1]}
                          </div>
                        ) : data?.split("-")[2] &&
                          data?.split("-")[2].length !== 1 ? (
                          <div key={index}>
                            <strong>
                              {data?.split("-")[2]?.split(":")[0]}
                              {":"}
                            </strong>
                            {data?.split("-")[2]?.split(":")[1]}
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    );
                  })}
                </div>
              </>
            )}
          </div>
        );
      },
    },
  ];

  const ingredientStrings = (data, header) => {
    return data?.map((ingredient, index) => {
      let ingredientString = `${ingredient.material_name} `;
      if (header === "operation") {
        // Conditionally include the parameter with its min and max values if they exist
        ingredientString += "";
        // ingredient.parameter_name && `(${ingredient.parameter_name}), `;"
      } else {
        ingredientString += `: ${ingredient.quantity + ""}`;
        if (ingredient.parameter_name !== "") {
          ingredientString += `-${ingredient.parameter_name}: Min ${ingredient.min_value} ${ingredient.parameter_name_unit} and Max ${ingredient.max_value} ${ingredient.parameter_name_unit}`;
        } else {
          ingredientString += `-`;
        }
      }
      ingredientString += index === data?.length - 1 ? "" : ",";
      return ingredientString;
    });
  };

  //####################### Handle Api's######################################################
  const move_recipe_to_production = (recipe_id, recipe_name) => {
    try {
      update_recipe_value(
        {
          recipe_id: recipe_id,
          is_production: true,
          new_recipe_name: recipe_name,
        },
        success_production_recipe
      );
    } catch (error) {
      console.log(error);
    }
  };

  const success_production_recipe = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      get_all_prod_data();
    } else {
      toast.warning(res?.message);
    }
    handleRecipeCancel();
  };

  //   const success_update_process = (res) => {
  //     console.log(res);
  //     if (res.status === "failed") {
  //       toast.warning(res.message);
  //     } else {
  //       toast.success(res.message);
  //       set_all_Process_data([]);
  //       get_all_DropDown_data();
  //       setSelectedMetadata("i");
  //     }
  //   };

  const get_all_prod_data = async () => {
    try {
      await get_prod_recipe_approval_list(sucess_production_approval_recipes);
      set_loading(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      set_loading(false);
    }
  };
  //For collapse open at a time only one row
  const addKeysInFilterDataForCollapse = (filterData) => {
    const updatedFilterList = filterData?.map((item, index) => {
      return {
        ...item,
        key: index.toString(), // Update the key to the index converted to a string
      };
    });
    return updatedFilterList;
  };
  const sucess_production_approval_recipes = (res) => {
    if (res?.data?.data && res?.data?.data?.length > 0) {
      setMetadataList(addKeysInFilterDataForCollapse(res?.data?.data));
    } else {
      setMetadataList([]);
    }
    set_loading(false);
  };
  const controllerRef = useRef();
  const [loading, set_loading] = useState(false);

  const processByRecipeId = async (recipeId) => {
    try {
      // await getProcessByRecipeId(recipeId, success_getProcessByRecipeId);
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
      controllerRef.current = new AbortController();
      const signal = controllerRef.current.signal;
      set_loading(true);
      await get_api_function(
        `/get_process_list_by_recipe?recipe_id=${recipeId}`,
        success_getProcessByRecipeId,
        {},
        signal
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_getProcessByRecipeId = (res) => {
    setProcessList(res?.data?.data);
    set_loading(false);
  };

  useEffect(() => {
    get_all_prod_data();
  }, []);

  // ==========================================
  const [operation_loading, set_operation_loading] = useState(false);

  const handleOnProcessSelect = (record) => {
    setShow(true);
    setSelectedProcess(record?.process_name);
    // set_operationDetails(record?.operations);
    const success_get_operation = (res) => {
      if (res.data.status === "success") {
        set_operationDetails(res.data.data[0].operations);
      }
      set_operation_loading(false);
    };
    try {
      set_operation_loading(true);
      get_api_function(
        `/bp_get_all_process_data?recipe_id=${recipeRecord?.recipe_id}&process_id=${record?.process_id}`,
        success_get_operation
      );
    } catch (e) {
      console.log(e);
    }
  };

  const cancelShow = () => {
    setShow(false);
    setSelectedProcess("");
    set_operationDetails([]);
    console.log("hello");
  };

  const process = [
    {
      title: "Process Name",
      dataIndex: "process_name",
      key: "process_name",
    },
    {
      title: "View Details",
      dataIndex: "",
      key: "process_name",
      render: (data, record, index) => {
        return (
          <IconButton onClick={() => handleOnProcessSelect(record)}>
            <VisibilityIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const expandedRowRenderoperations = (record) => {
    return (
      <Collapse accordion>
        {record?.process_products?.length > 0 && (
          <>
            <Panel header="Product" key="1">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Product Name",
                    dataIndex: "product_name",
                    key: "product_name",
                  },
                  {
                    title: "Process Thero Quantity",
                    dataIndex: "process_thero_quantity",
                    key: "operation_number",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                  {
                    title: "Expected Min Value",
                    dataIndex: "process_expec_min_value",
                    key: "process_expec_min_value",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                  {
                    title: "Expected Max Value",
                    dataIndex: "process_expec_max_value",
                    key: "process_expec_max_value",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                ]}
                dataSource={record?.process_products}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          </>
        )}

        {record?.process_parameters &&
          record?.process_parameters?.length > 0 && (
            <>
              <Panel header="Process Parameters" key="2">
                <Table
                  size="small"
                  pagination={false}
                  columns={[
                    {
                      title: "Parameter",
                      dataIndex: "parameter_name",
                      rowScope: "row",
                    },
                    {
                      title: "Readings Per Interval (HH:MM:SS)",
                      dataIndex: "parameter_max_value",
                      key: "parameter_max_value",
                      render: (data, record, index) => {
                        return <p>{`${data}`}</p>;
                      },
                    },
                    {
                      title: "Quantity",
                      dataIndex: "qunatity",
                      key: "qunatity",
                      align: "center",
                      render: (data, record) => {
                        return (
                          <p>{`${data} (${record?.parameter_name_unit})`}</p>
                        );
                      },
                    },
                    {
                      title: "Readings",
                      dataIndex: "reading",
                      key: "min_value",
                      align: "center",
                    },
                    {
                      title: "Min",
                      dataIndex: "min_value",
                      key: "min_value",
                      align: "center",
                      render: (data, record) => {
                        return (
                          <p>
                            {data
                              ? `${data} (${record?.parameter_name_unit})`
                              : "NA"}
                          </p>
                        );
                      },
                    },
                    {
                      title: "Max",
                      dataIndex: "max_value",
                      key: "max_value",
                      align: "center",
                      render: (data, record) => {
                        return (
                          <p>
                            {data
                              ? `${data} (${record?.parameter_name_unit})`
                              : "NA"}
                          </p>
                        );
                      },
                    },
                  ]}
                  dataSource={record.process_parameters}
                  bordered
                  style={{ width: "100%" }}
                />
              </Panel>
            </>
          )}
        {record?.operation_type === "Sampling" &&
          record?.sampling &&
          record?.sampling?.length > 0 && (
            <Panel header="Sampling" key="3">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Output",
                    align: "center",
                    dataIndex: "material_inventory_name",
                    key: "material_inventory_name",
                  },
                  {
                    title: "Quantity",
                    align: "center",
                    dataIndex: "quantity",
                    key: "quantity",
                    render: (data, record, index) => {
                      return (
                        <p>{`${data} (${record.material_inventory_unit})`}</p>
                      );
                    },
                  },
                ]}
                dataSource={record.sampling}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          )}

        {record?.machines && record?.machines?.length > 0 && (
          <Panel header="Machines" key="4">
            <Table
              size="small"
              pagination={false}
              columns={[
                {
                  title: "Machine Name",
                  dataIndex: "machine_name",
                  key: "machine_name",
                },
              ]}
              dataSource={record?.machines}
              bordered
              style={{ width: "100%" }}
            />
          </Panel>
        )}
      </Collapse>
    );
  };

  const handleExpand = (expanded, record) => {
    if (expanded) {
      set_current_expand(record?.key);
    } else {
      set_current_expand("");
    }
  };

  const handleProdExpand = (expanded, record) => {
    setRecipeRecord(record);
    if (expanded) {
      processByRecipeId(record?.recipe_id);
      set_current_recipe_expand(record.key);
    } else {
      setProcessList([]);
      set_current_recipe_expand("");
    }
  };

  const expandedRowRender = (record) => {
    return (
      <Table
        columns={process}
        dataSource={addKeysInFilterDataForCollapse(processList) || []}
        pagination={false}
        loading={loading}
        bordered
      />
    );
  };

  const expandedRowRenderOperations = (record) => {
    console.log(record);
    return (
      <Collapse accordion>
        {record?.process_products?.length > 0 && (
          <>
            <Panel header="Product" key="1">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Product Name",
                    dataIndex: "product_name",
                    key: "product_name",
                  },
                  {
                    title: "Process Thero Quantity",
                    dataIndex: "process_thero_quantity",
                    key: "operation_number",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                  {
                    title: "Expected Min Value",
                    dataIndex: "process_expec_min_value",
                    key: "process_expec_min_value",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                  {
                    title: "Expected Max Value",
                    dataIndex: "process_expec_max_value",
                    key: "process_expec_max_value",
                    render: (data, record, index) => {
                      return <p>{`${data} (${record.product_name_unit})`}</p>;
                    },
                  },
                  {
                    title: "Instruction",
                    dataIndex: "instruction",
                    key: "instruction",
                    align: "center",
                    render: (data, record) => {
                      return (
                        <p>
                          <InfoComment
                            title="Instruction"
                            content={record?.instruction}
                          />
                        </p>
                      );
                    },
                  },
                ]}
                dataSource={record?.process_products}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          </>
        )}

        {record.process_parameters && record.process_parameters?.length > 0 && (
          <>
            <Panel header="Process Parameters" key="2">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Parameter",
                    dataIndex: "parameter_name",
                    rowScope: "row",
                  },
                  {
                    title: "Readings Per Interval (HH:MM:SS)",
                    dataIndex: "parameter_max_value",
                    key: "parameter_max_value",
                    render: (data, record, index) => {
                      return <p>{`${data}`}</p>;
                    },
                  },
                  {
                    title: "Quantity",
                    dataIndex: "qunatity",
                    key: "qunatity",
                    align: "center",
                    render: (data, record) => {
                      return (
                        <p>{`${data} (${record?.parameter_name_unit})`}</p>
                      );
                    },
                  },
                  {
                    title: "Readings",
                    dataIndex: "reading",
                    key: "reading",
                    align: "center",
                    render: (data, record) => {
                      return (
                        <p>
                          {data
                            ? `${data}`
                            : [
                                record?.is_start ? "At Start" : null,
                                record?.is_end ? "At End" : null,
                              ]
                                .filter(Boolean) // Remove any null values
                                .join(", ")}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Min",
                    dataIndex: "min_value",
                    key: "min_value",
                    align: "center",
                    render: (data, record) => {
                      return (
                        <p>
                          {data
                            ? `${data} (${record?.parameter_name_unit})`
                            : "NA"}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Max",
                    dataIndex: "max_value",
                    key: "max_value",
                    align: "center",
                    render: (data, record) => {
                      return (
                        <p>
                          {data
                            ? `${data} (${record?.parameter_name_unit})`
                            : "NA"}
                        </p>
                      );
                    },
                  },
                  {
                    title: "Auto Status",
                    dataIndex: "auto_status",
                    key: "auto_status",
                    align: "center",
                    render: (data, record) => {
                      return <p>{data ? `${"Auto"}` : "Manual"}</p>;
                    },
                  },
                  {
                    title: "Sensor",
                    dataIndex: "sensor_name",
                    key: "sensor_name",
                    align: "center",
                    render: (data, record) => {
                      return <p>{data}</p>;
                    },
                  },
                  {
                    title: "Instruction",
                    dataIndex: "instruction",
                    key: "instruction",
                    align: "center",
                    render: (data, record) => {
                      return (
                        <p>
                          <InfoComment
                            title="Instruction"
                            content={record?.instruction}
                          />
                        </p>
                      );
                    },
                  },
                ].filter(Boolean)}
                dataSource={record?.process_parameters}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          </>
        )}
        {record.operation_type === "Sampling" &&
          record?.sampling &&
          record?.sampling?.length > 0 && (
            <Panel header="Sampling" key="3">
              <Table
                size="small"
                pagination={false}
                columns={[
                  {
                    title: "Output",
                    align: "center",
                    dataIndex: "material_inventory_name",
                    key: "material_inventory_name",
                  },
                  {
                    title: "Quantity",
                    align: "center",
                    dataIndex: "quantity",
                    key: "quantity",
                    render: (data, record, index) => {
                      return (
                        <p>{`${data} (${record.material_inventory_unit})`}</p>
                      );
                    },
                  },
                  {
                    title: "Testing Type",
                    align: "center",
                    dataIndex: "testing_type_name",
                    key: "testing_type_name",
                  },
                  {
                    title: "Parameter",
                    align: "center",
                    dataIndex: "conditional_param",
                    key: "parameter",
                    render: (data, record) => {
                      return data[0]?.parameter ? data[0].parameter : "NA";
                    },
                  },
                  {
                    title: "Min",
                    align: "center",
                    dataIndex: "conditional_param",
                    key: "min_value",
                    render: (data) => {
                      return data[0]?.min_value ? data[0].min_value : "NA";
                    },
                  },
                  {
                    title: "Max",
                    align: "center",
                    dataIndex: "conditional_param",
                    key: "max_value",
                    render: (data) => {
                      return data[0]?.max_value ? data[0].max_value : "NA";
                    },
                  },
                  {
                    title: "Instruction",
                    dataIndex: "instruction",
                    key: "instruction",
                    align: "center",
                    render: (data, record) => {
                      return (
                        <p>
                          <InfoComment
                            title="Instruction"
                            content={record?.instruction}
                          />
                        </p>
                      );
                    },
                  },
                ]}
                dataSource={record?.sampling}
                bordered
                style={{ width: "100%" }}
              />
            </Panel>
          )}

        {record?.machines && record?.machines?.length > 0 && (
          <Panel header="Machines" key="4">
            <Table
              size="small"
              pagination={false}
              columns={[
                {
                  title: "Machine Name",
                  dataIndex: "machine_name",
                  key: "machine_name",
                },
              ]}
              dataSource={record?.machines}
              bordered
              style={{ width: "100%" }}
            />
          </Panel>
        )}
      </Collapse>
    );
  };

  return (
    <MainCard title="Recipe Approval">
      <Grid
        container
        spacing={1}
        columns={12}
        style={{ display: "flex", marginTop: "-50px" }}
      >
        <Grid item xs={12} lg={12}>
          <Stack direction="row" spacing={1}>
            <div style={{ marginLeft: "auto" }}>
              <Input.Search
                Placeholder="Search here..."
                className="mt-1"
                size="large"
                onSearch={(value) => {
                  setSearchvalue(value);
                }}
                onChange={(e) => setSearchvalue(e.target.value)}
              />
            </div>
          </Stack>
        </Grid>

        <Grid item lg={12}>
          <Table
            dataSource={addKeysInFilterDataForCollapse(metadataList)}
            columns={columns}
            pagination={{ pageSize: 10 }}
            expandable={{
              expandedRowRender,
              onExpand: handleProdExpand,
              expandedRowKeys: [current_recipe_expand],
            }}
            loading={aprLoading}
            showHeader={true}
            bordered
          />
        </Grid>

        {/* <Grid item lg={12}>
          <Stack direction="row" justifyContent="end" spacing={2}>
            <Button
              type="button"
              variant="contained"
              onClick={(e) => handleSubmit()}
              color="primary"
            >
              Submit
            </Button>
          </Stack>
        </Grid> */}
      </Grid>
      <Modal
        open={show}
        width={"1350px"}
        title={`${selectedprocess} All Operations `}
        size="large"
        footer={[]}
        onCancel={cancelShow}
        zIndex={1300}
      >
        <Table
          dataSource={addKeysInFilterDataForCollapse(operationDetails)}
          columns={operationCols}
          pagination={{ pageSize: 3 }}
          bordered
          loading={operation_loading}
          rowClassName={(record, index) => {
            if (expanded?.includes(index)) {
              return "col";
            }
          }}
          expandable={{
            expandedRowRender: expandedRowRenderOperations,
            onExpand: handleExpand,
            onExpandedRowsChange: (expanded) => set_expanded(expanded),
            expandedRowKeys: [current_expand],
            expandedRowClassName: () => "col2",
          }}
        />
      </Modal>
      <Modal
        open={recipeShow}
        title="New Recipe Name"
        onCancel={handleRecipeCancel}
        footer={[
          <Stack direction="row" spacing={1} justifyContent="end">
            <Button
              variant="contained"
              size="medium"
              key="cancel"
              onClick={handleRecipeCancel}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="medium"
              key="save"
              type="primary"
              onClick={handleRecipeOk}
            >
              Save
            </Button>
          </Stack>,
        ]}
      >
        <Grid item xs={12} lg={3}>
          <Stack spacing={1}>
            <InputLabel htmlFor="des">Recipe Name</InputLabel>
            <OutlinedInput
              id="des"
              type="text"
              size="medium"
              name="des"
              placeholder="Enter New Recipe Name"
              fullWidth
              value={recipe_name}
              required
              onChange={(e) => set_recipe_name(e.target.value)}
            />
          </Stack>
        </Grid>
      </Modal>
    </MainCard>
  );
}

export default ProdRecipeApproval;
