import React, { useState, useEffect } from "react";
// project import
import { get_api_function } from "utils/api";
import { useNavigate } from "react-router";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Grid,
  Card,
  Avatar,
  Divider,
  Tab,
  Box,
  Stack,
} from "@mui/material";
import { Typography as Typography1 } from "antd";
// ==============================|| SAMPLE PAGE ||============================== //

const avatarSX = {
  width: 36,
  height: 36,
  fontSize: "1rem",
};

const actionSX = {
  mt: "6px",
  ml: 1,
  top: "auto",
  right: "auto",
  alignSelf: "flex-start",

  transform: "none",
};

const deviation_color = (per) => {
  let color;
  let bg_color;
  color = "orange";
  bg_color = "#fff5e6";
  return { color: color, bg_color: bg_color };
};

const AlertNotificationPage = () => {
  const [notifications_data, set_notifications_data] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    get_api_function("/get_operation_alert", (res) => {
      set_notifications_data(res?.data?.data || []);
    });
  }, []);

  return (
    <List
      component="nav"
      sx={{
        p: 0,
        "& .MuiListItemButton-root": {
          py: 0.5,
          "& .MuiAvatar-root": avatarSX,
          "& .MuiListItemSecondaryAction-root": {
            ...actionSX,
            position: "relative",
          },
        },
      }}
    >
      <Grid container>
        {notifications_data?.map((item, index) => {
          return (
            <Grid item lg={3}>
              <Card sx={{ m: 2 }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        color: deviation_color()?.color,
                        bgcolor: deviation_color()?.bg_color,
                      }}
                    >
                      {item.operation_name
                        .split(" ")
                        ?.slice(0, 2)
                        .map((word) => word[0])
                        .join("")
                        .toUpperCase()}
                    </Avatar>
                  </ListItemAvatar>
                  <Stack>
                    <ListItemText
                      primary={
                        <Typography1.Paragraph
                          ellipsis={{
                            rows: 2,
                            expandable: true,
                            symbol: "more",
                          }}
                        >
                          {item?.msg}
                        </Typography1.Paragraph>
                      }
                      secondary={
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          spacing={0.5}
                        >
                          <Stack>
                            <div>{`Batch : ${item?.batch_number}`}</div>
                            <div>{`Operation Name : ${item?.operation_name}`}</div>
                            <div>{`Duration : ${item?.duration}`}</div>
                            <div>{`Running Time : ${item?.running_time}`}</div>
                          </Stack>
                        </Stack>
                      }
                    />
                  </Stack>
                </ListItem>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </List>
  );
};

export default AlertNotificationPage;
