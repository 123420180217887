import ELK from "elkjs";
import { MarkerType } from "reactflow";
import { Tooltip, Stack } from "@mui/material";
import { Typography } from "antd";
import Adding from "../../../assets/images/operations/Adding.png";
import Charging from "../../../assets/images/operations/Charging.png";
import Cleaning from "../../../assets/images/operations/Cleaning.png";
import Cooling from "../../../assets/images/operations/Cooling.png";
import Drying from "../../../assets/images/operations/Drying.png";
import Filtering from "../../../assets/images/operations/Filtering.png";
import Heating from "../../../assets/images/operations/Heating.png";
import Mixing from "../../../assets/images/operations/Mixing.png";
import Monitoring from "../../../assets/images/operations/Monitoring.png";
import Sampling from "../../../assets/images/operations/Sampling.png";
import Stir from "../../../assets/images/operations/Stir.png";
import Tableting from "../../../assets/images/operations/Tableting.png";
import Unloading from "../../../assets/images/operations/Unloading.png";
import Coating from "../../../assets/images/operations/Coating.png";
import Blending from "../../../assets/images/operations/Blending.png";
import MaintainState from "../../../assets/images/operations/Maintain state.png";
import MixingandBlending from "../../../assets/images/operations/Mixing and Blending.png";
import Washing from "../../../assets/images/operations/Washing.png";
import Expelling from "../../../assets/images/operations/Expelling.png";
import Extrusion from "../../../assets/images/operations/Extrusion.png";
import Loading from "../../../assets/images/operations/Loading.png";
import SamplingRequest from "../../../assets/images/operations/Sampling Request.png";
import Preparing from "../../../assets/images/operations/Preparing.png";
import Labeling from "../../../assets/images/operations/Labeling.png";
import Default from "../../../assets/images/operations/Default.png";

const img = {
  Adding,
  Charging,
  Cleaning,
  Cooling,
  Drying,
  Filtering,
  Heating,
  Mixing,
  Monitoring,
  Sampling,
  Stir,
  Tableting,
  Unloading,
  Coating,
  Blending,
  "Maintain state": MaintainState,
  "Mixing and Blending": MixingandBlending,
  Washing,
  Expelling,
  Extrusion,
  Loading,
  "Sampling Request": SamplingRequest,
  Preparing,
  Labeling,
  Default,
};
const { Text, Link } = Typography;

export default async function createLayout(
  nodes_data,
  groups_data,
  edges_data,
) {
  const initialNodes = nodes_data?.map((op) => ({
    id: op?.operationId,
    group: op?.process,
    data: op,
  }));

  const initialGroups = groups_data?.map((pro) => ({
    id: pro.process_id,
    width: 140,
    height: 50,
    data: pro,
  }));

  const initialEdges = edges_data;

  const elk = new ELK();
  const get_color = (status) => {
    switch (status) {
      case "Pending":
        return "rgb(255, 235, 180, 0.3)"; // Lightened version of (255, 220, 128, 0.3)
      case "In Progress":
        return "rgb(175, 218, 255, 0.3)"; // Lightened version of (135, 206, 255, 0.3)
      case "Completed":
        return "rgb(200, 255, 180, 0.3)"; // Lightened version of (170, 255, 128, 0.3)
      default:
        return "rgb(175, 218, 255, 0.3)"; // Lightened version of (135, 206, 255, 0.3)
    }
  };

  const get_edge_color = (status) => {
    switch (status) {
      case "Pending":
        return "#ff6d00";
      case "In Progress":
        return "#1e90ff";
      case "Completed":
        return "#28A745";
      default:
        return "#7371fc"; // or any default color if you prefer
    }
  };

  const get_filter_color = (status) => {
    switch (status) {
      case "Pending":
        return "brightness(0) saturate(100%) invert(45%) sepia(90%) saturate(2404%) hue-rotate(1deg) brightness(103%) contrast(104%)";
      case "In Progress":
        return "brightness(0) saturate(100%) invert(40%) sepia(58%) saturate(2139%) hue-rotate(194deg) brightness(103%) contrast(102%)";
      case "Completed":
        return "brightness(0) saturate(100%) invert(64%) sepia(20%) saturate(6370%) hue-rotate(88deg) brightness(86%) contrast(71%)";
      default:
        return "brightness(0) saturate(100%) invert(56%) sepia(68%) saturate(5586%) hue-rotate(223deg) brightness(104%) contrast(98%)";
    }
  };

  const graph = {
    id: "root",
    layoutOptions: {
      "elk.algorithm": "layered",
      "elk.direction": "RIGHT",
      "elk.edgeRouting": "POLYLINE",
      "elk.spacing.nodeNode": "40",
    },
    children: initialGroups.map((group) => ({
      id: group.id,
      data: group.data,
      width: group.width,
      height: group.height,
      layoutOptions: {
        "elk.algorithm": "layered",
        "elk.direction": "RIGHT",
        "elk.edgeRouting": "POLYLINE",
        "elk.spacing.edgeNode": "80",
        "elk.spacing.nodeNode": "40",
        "elk.layered.spacing.edgeNodeBetweenLayers": "40",
      },
      children: initialNodes
        .filter((node) => node.group === group.id)
        .map((node) => ({
          id: node.id,
          data: node.data,
          width: 120,
          height: 30,
          layoutOptions: {
            "elk.algorithm": "layered",
            "elk.direction": "RIGHT",
            "elk.layered.spacing.edgeNodeBetweenLayers": "40",
            "elk.edgeRouting": "POLYLINE",
            "elk.spacing.edgeNode": "40",
          },
        })),
    })),
    edges: initialEdges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  };

  const layout = await elk.layout(graph);
  const nodes = layout.children.reduce((result, current) => {
    result.push({
      id: current.id,
      position: { x: current.x, y: current.y },
      data: {
        label: (
          <div style={{ marginTop: "-28px" }}>
            <Tooltip title={current?.data?.process}>
              <div
                style={{
                  display: "block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {current?.data?.process}
              </div>
            </Tooltip>
          </div>
        ),
        data: current?.data,
      },
      style: {
        width: current.width,
        height: current.height,
        backgroundColor:
          // get_color(current?.data?.status),
          "RGB(0,0,0,0)",
        border: "2px dashed #BFBFBF",
      },
      sourcePosition: "right",
      targetPosition: "left",
    });

    current.children.forEach((child) =>
      result.push({
        id: child.id,
        position: { x: child.x, y: child.y },
        data: {
          label: (
            <Tooltip title={child.data?.operation}>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ height: "100%" }}
              >
                {img[child.data?.operation] ? (
                  <img
                    src={img[child.data?.operation]}
                    alt={child.data?.operation}
                    width={20}
                    style={{
                      filter: get_filter_color(child?.data?.status),
                    }}
                  />
                ) : (
                  <img
                    src={img["Default"]}
                    alt={child.data?.operation}
                    width={20}
                    style={{
                      filter: get_filter_color(child?.data?.status),
                    }}
                  />
                )}
                <Text
                  style={{
                    display: "block",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    fontSize: "0.7rem", // Adjust as needed
                    color: get_edge_color(child?.data?.status),
                  }}
                  type="secondary"
                >
                  {child.data?.operation}
                </Text>
              </Stack>
            </Tooltip>
          ),
          data: child.data,
        },
        style: {
          backgroundColor: get_color(child?.data?.status),
          width: child.width,
          height: child.height,
          border: `1px solid ${get_edge_color(child?.data?.status)}`,
          // border: "none",
        },
        parentNode: current.id,
        sourcePosition: "right",
        targetPosition: "left",
      })
    );

    return result;
  }, []);

  return {
    nodes,
    edges: initialEdges?.map((item) => {
      const status = nodes?.find((node) => node?.id === item?.source)?.data
        ?.data?.status;
      return {
        ...item,
        type: "step",
        pathOptions: { offset: 1 },
        style: {
          stroke: get_edge_color(status),
          strokeWidth: 1.5,
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 15,
          height: 15,
          color: get_edge_color(status),
        },
      };
    }),
  };
}
