import React, { useEffect, useState, useRef } from "react";
import {
  bp_machine_data,
  add_machine_data,
  bp_machine_cat_data,
  bp_parameters_data,
  get_employee,
  bp_location_data,
  get_sensor_list,
  bp_add_unit_data,
  bp_units_data,
} from "../../../utils/api";
import { toast } from "react-toastify";
import { Table, Pagination, Modal, Select as AntSelect, Input } from "antd";
import AddIcon from "@mui/icons-material/Add";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import MainCard from "components/MainCard";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import SensorConfigForm from "./Sensor";
import { useLocation, useNavigate } from "react-router-dom";
import QrCodeIcon from "@mui/icons-material/QrCode";
import CloseIcon from "@mui/icons-material/Close";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Tooltip,
  Grid,
  Select,
  ListItemText,
  Checkbox,
  Box,
  Chip,
  MenuItem,
  Button,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
} from "@mui/material";
import dayjs from "dayjs";
import ConfigModal from "./ConfigModal";

function Machine({ show_table, get_status_of_mod, set_get_status_of_mod }) {
  const [get_status, set_get_status] = useState(false);
  const formRef = useRef();
  const formRef2 = useRef();
  const navigate = useNavigate();
  const [machineData, setMachineData] = useState();
  const [show_form, set_show_form] = useState();
  const [parameterData, setParameterData] = useState();
  const [machineCatData, setMachineCatData] = useState();
  const [locationData, setLocationData] = useState();
  const [isAdddMachine, setIsAddMachine] = useState();
  const [employeeData, setEmployeeData] = useState();
  const [attribute_details, set_attribute_details] = useState({});
  const [isSensorVisible, setIsSensorVisible] = useState(false);
  const [get_refresh_status, set_refresh_get_status] = useState(false);
  const [searchvalue, setSearchvalue] = useState("");
  const [sensorData, set_sensorData] = useState([]);
  const [isRefresh,setIsRefresh] = useState(false)
  const [machineDataReq, setMachineDataReq] = useState({
    name: "",
    location: "",
    assert_no: "",
    tester_id: "",
    description: "",
    make: "",
    machine_type_id: "",
    frequency: "",
    machine_year: dayjs().year(),
    operational_status: "",
    software_details: "",
    SOP_document: "",
    equipment_manual: "",
    maintenance_instructions_manual: "",
    parameter: [],
    sensor_id: [],
  });

  const [machine_id, set_machine_id] = useState();

  // Create a new FormData object
  const formDataObj = new FormData();

  const handleSubmit = (e) => {
    e.preventDefault();
    // convert normal json to formData
    Object.entries(machineDataReq).forEach(([key, value]) => {
      formDataObj.append(key, value);
    });
    // call the api
    add_machine(formDataObj);
  };

  const machineDetailColumns = [
    {
      dataIndex: "name",
      title: "Machine Name",
      key: "Machine Name",
      width: "8%",
      filteredValue: [searchvalue],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.machine_type)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.location).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      dataIndex: "machine_type",
      title: "Machine Type",
      key: "Machine Type",
      width: "8%",
    },

    {
      dataIndex: "machine_year",
      title: "Manufacturing Year",
      key: "model",
      width: "8%",
    },
    {
      dataIndex: "location",
      title: "Location",
      key: "Location",
      width: "8%",
    },
    {
      dataIndex: "installation_date",
      title: "Installation Date",
      key: "installation date",
      width: "8%",
      render: (data) => data?.slice(0, 19),
    },
    {
      dataIndex: "operational_status",
      title: "Operational Status",
      key: "operational_status",
      width: "8%",
    },
  ];

  //####################### Handle Api's######################################################

  const get_all_machine = async () => {
    try {
      await bp_machine_data(success_machine_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_machine_list_get = (res) => {
    console.log("res", res);
    setMachineData(res?.data?.data);
  };
  const get_all_parameter = async () => {
    try {
      add_number === 4 &&
        (await bp_parameters_data(success_parameter_list_get));
      add_number === 3 && (await bp_location_data(success_location_data));
      add_number === 5 && (await bp_machine_cat_data(success_machineCatData));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_parameter_list_get = (res) => {
    console.log("res", res);
    setParameterData(res?.data?.data);
  };

  const add_machine = async (machineData) => {
    try {
      await add_machine_data(machineData, success_add_machine);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_add_machine = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      formRef.current.reset();
      toast.success(res.message);
      // set_machine_id(res.machine_id);
      get_all_machine();
      reset_function();
      set_get_status_of_mod(!get_status_of_mod);
      set_refresh_get_status(!get_refresh_status);
    }
  };

  const reset_function = () => {
    setMachineDataReq({
      name: "",
      location: "",
      assert_no: "",
      tester_id: "",
      description: "",
      make: "",
      machine_type_id: "",
      frequency: "",
      machine_year: dayjs().year(),
      operational_status: "",
      software_details: "",
      SOP_document: "",
      equipment_manual: "",
      maintenance_instructions_manual: "",
      parameter: [],
      sensor_id: [],
    });
  };
  const get_all_dropdown_Data = async () => {
    try {
      await bp_location_data(success_location_data);
      await get_employee(success_employee_data);
      await bp_machine_cat_data(success_machineCatData);
      await bp_parameters_data(success_parameter_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_machineCatData = (res) => {
    setMachineCatData(res?.data?.data);
  };

  const success_get_all_sensors = (res) => {
    set_sensorData(res?.data?.data);
  };

  const success_location_data = (res) => {
    setLocationData(res?.data?.data);
  };

  const success_employee_data = (res) => {
    setEmployeeData(res?.data?.data);
  };

  useEffect(() => {
    get_all_machine();
    get_all_parameter();
    get_all_dropdown_Data();
  }, [get_status_of_mod, get_refresh_status]);

  useEffect(() => {
    get_all_parameter();
  }, [get_status]);

  useEffect(() => {
    try {
      get_sensor_list(success_get_all_sensors);
    } catch (error) {
      console.log(error);
    }
  }, [isSensorVisible]);

  const [addSpecOpen, set_addSpecOpen] = useState(false);

  const handleAddParameter = (e) => {
    e.preventDefault();
    let temp = machineDataReq["parameter"]
      ? [...machineDataReq["parameter"]]
      : [];
    temp.push(attribute_details);
    setMachineDataReq({ ...machineDataReq, parameter: temp });
    set_addSpecOpen(false);
    set_attribute_details({});
    formRef2.current.reset();
  };

  useEffect(() => {
    console.log("machineDataReq", machineDataReq);
  }, [machineDataReq]);

  //   =============================================================
  const [show_modal, set_show_modal] = useState(false);
  const [add_number, set_add_number] = useState(0);

  function generateRandomCode() {
    function randomLetter() {
      return String.fromCharCode(65 + Math.floor(Math.random() * 26));
    }
    const randomLetters = Array.from({ length: 4 }, randomLetter).join("");
    const randomDigits = Math.floor(Math.random() * 10000)
      .toString()
      .padStart(4, "0");
    const generatedCode = `${randomLetters}-${randomDigits}`;
    return generatedCode;
  }

  return (
    <>
      <MainCard title="Machine">
        {show_form && (
          <Stack direction="row" justifyContent="end">
            <Tooltip title="Close" style={{ marginTop: "-45px" }}>
              <IconButton onClick={() => set_show_form(!show_form)}>
                <CloseIcon color="error" style={{ fontSize: "35px" }} />
              </IconButton>
            </Tooltip>
          </Stack>
        )}

        <Grid container spacing={2} columns={12} style={{ marginTop: "-25px" }}>
          {(show_form || show_table) && (
            <Grid item xs={12} lg={12}>
              <form ref={formRef} onSubmit={handleSubmit}>
                <Grid
                  container
                  spacing={1}
                  item
                  xs={12}
                  lg={12}
                  style={{ marginTop: "2px" }}
                >
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel required htmlFor="title">
                        Asset Number
                      </InputLabel>
                      <Stack direction="row">
                        <OutlinedInput
                          id="title"
                          size="medium"
                          type="text"
                          name="email"
                          placeholder="Enter Asset Number"
                          fullWidth
                          value={machineDataReq["assert_no"]}
                          required
                          onChange={(e) =>
                            setMachineDataReq({
                              ...machineDataReq,
                              assert_no: e.target.value,
                            })
                          }
                        />
                        <Tooltip label="Generate Asset Number">
                          <IconButton
                            onClick={() => {
                              setMachineDataReq({
                                ...machineDataReq,
                                assert_no: generateRandomCode(),
                              });
                            }}
                          >
                            <AutorenewIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel required htmlFor="title">
                        Machine Name
                      </InputLabel>
                      <OutlinedInput
                        id="title"
                        type="text"
                        size="medium"
                        name="email"
                        placeholder="Enter Machine Name"
                        fullWidth
                        value={machineDataReq["machine_name"]}
                        required
                        onChange={(e) =>
                          setMachineDataReq({
                            ...machineDataReq,
                            name: e.target.value,
                          })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Stack spacing={1}>
                      <InputLabel required htmlFor="rmt">
                        Location
                      </InputLabel>
                      <Stack direction="row">
                        <AntSelect
                          allowClear
                          showSearch
                          id="demo-select-small"
                          value={machineDataReq["location"] || null}
                          size="large"
                          style={{
                            width: "100%",
                            border: "0.5px solid #D9D9D9",
                            borderRadius: "3px",
                          }}
                          bordered={false}
                          placeholder="Select Location"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.localeCompare(optionB.children)
                          }
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          onChange={(value, option) => {
                            setMachineDataReq({
                              ...machineDataReq,
                              location: value,
                            });
                          }}
                        >
                          {locationData?.map((item) => (
                            <AntSelect.Option
                              key={item.location_id}
                              value={item.location_id}
                            >
                              {item.location_name}
                            </AntSelect.Option>
                          ))}
                        </AntSelect>

                        <Tooltip title="Add Location">
                          <IconButton
                            onClick={() => {
                              set_show_modal(true);
                              set_add_number(3);
                            }}
                          >
                            <AddIcon
                              style={{
                                color: "#00C853",
                                fontSize: "32px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Stack spacing={1}>
                      <InputLabel required htmlFor="rmt">
                        Tester
                      </InputLabel>
                      <AntSelect
                        allowClear
                        showSearch
                        id="demo-select-small"
                        value={machineDataReq["tester_id"] || null}
                        size="large"
                        style={{
                          width: "100%",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "3px",
                        }}
                        bordered={false}
                        placeholder="Select Tester"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children.localeCompare(optionB.children)
                        }
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        onChange={(value, option) => {
                          setMachineDataReq({
                            ...machineDataReq,
                            tester_id: value,
                          });
                        }}
                      >
                        {employeeData?.map((item) => (
                          <AntSelect.Option
                            key={item.employee_id}
                            value={item.employee_id}
                          >
                            {item.employee_name}
                          </AntSelect.Option>
                        ))}
                      </AntSelect>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="desc">Description</InputLabel>
                      <OutlinedInput
                        id="desc"
                        size="medium"
                        type="text"
                        name="desc"
                        placeholder="Enter Description"
                        fullWidth
                        value={machineDataReq["description"]}
                        onChange={(e) =>
                          setMachineDataReq({
                            ...machineDataReq,
                            description: e.target.value,
                          })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="title">Make</InputLabel>
                      <OutlinedInput
                        id="title"
                        size="medium"
                        type="text"
                        name="email"
                        placeholder="Enter Make"
                        fullWidth
                        value={machineDataReq["make"]}
                        // required
                        onChange={(e) =>
                          setMachineDataReq({
                            ...machineDataReq,
                            make: e.target.value,
                          })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <InputLabel required htmlFor="rmt">
                      Machine Type
                    </InputLabel>
                    <Stack direction="row" spacing={1}>
                      <AntSelect
                        allowClear
                        showSearch
                        id="demo-select-small"
                        value={machineDataReq["machine_type_id"] || null}
                        size="large"
                        style={{
                          width: "100%",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "3px",
                        }}
                        bordered={false}
                        placeholder="Select Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children.localeCompare(optionB.children)
                        }
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        onChange={(value, option) => {
                          setMachineDataReq({
                            ...machineDataReq,
                            machine_type_id: value,
                          });
                        }}
                      >
                        {machineCatData?.map((item) => (
                          <AntSelect.Option
                            key={item.machine_category_id}
                            value={item.machine_category_id}
                          >
                            {item.machine_category_name}
                          </AntSelect.Option>
                        ))}
                      </AntSelect>

                      <Tooltip title="Add Machine Type">
                        <IconButton
                          onClick={() => {
                            set_show_modal(true);
                            set_add_number(5);
                          }}
                        >
                          <AddIcon
                            style={{
                              color: "#00C853",
                              fontSize: "32px",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="title">
                        Manufacturing Year
                      </InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          value={dayjs(
                            `01-01-${machineDataReq["machine_year"]}`
                          )}
                          onChange={(e) => {
                            setMachineDataReq({
                              ...machineDataReq,
                              machine_year: e.year(),
                            });
                          }}
                          openTo="year"
                          views={["year"]}
                        />
                      </LocalizationProvider>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="title">Frequency</InputLabel>
                      <AntSelect
                        allowClear
                        showSearch
                        id="demo-select-small"
                        value={machineDataReq["frequency"] || null}
                        size="large"
                        style={{
                          width: "100%",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "3px",
                        }}
                        bordered={false}
                        placeholder="Select Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children.localeCompare(optionB.children)
                        }
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        onChange={(value, option) => {
                          setMachineDataReq({
                            ...machineDataReq,
                            frequency: value,
                          });
                        }}
                      >
                        {[
                          "Hourly",
                          "Daily",
                          "Monthly",
                          "Quaterly",
                          "Yearly",
                        ]?.map((item) => (
                          <AntSelect.Option key={item} value={item}>
                            {item}
                          </AntSelect.Option>
                        ))}
                      </AntSelect>
                    </Stack>
                  </Grid>

                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="title">Software Details</InputLabel>
                      <OutlinedInput
                        id="title"
                        size="medium"
                        type="text"
                        placeholder="Enter Software Details"
                        fullWidth
                        value={machineDataReq["software_details"]}
                        onChange={(e) =>
                          setMachineDataReq({
                            ...machineDataReq,
                            software_details: e.target.value,
                          })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel required htmlFor="title">
                        Operational Status
                      </InputLabel>

                      <AntSelect
                        allowClear
                        showSearch
                        id="demo-select-small"
                        value={machineDataReq["operational_status"] || null}
                        size="large"
                        style={{
                          width: "100%",
                          border: "0.5px solid #D9D9D9",
                          borderRadius: "3px",
                        }}
                        bordered={false}
                        placeholder="Select Type"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children.localeCompare(optionB.children)
                        }
                        dropdownStyle={{
                          maxHeight: 400,
                          overflow: "auto",
                        }}
                        onChange={(value, option) => {
                          setMachineDataReq({
                            ...machineDataReq,
                            operational_status: value,
                          });
                        }}
                      >
                        {["Active", "Inactive", "Under Maintenance"]?.map(
                          (item) => (
                            <AntSelect.Option key={item} value={item}>
                              {item}
                            </AntSelect.Option>
                          )
                        )}
                      </AntSelect>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="Sensors">Sensors</InputLabel>
                      <Stack spacing={1} direction="row">
                        <AntSelect
                          allowClear
                          mode="multiple"
                          showSearch
                          id="demo-select-small"
                          value={machineDataReq["sensor_id"] || null}
                          size="large"
                          style={{
                            width: "95%",
                            border: "0.5px solid #D9D9D9",
                            borderRadius: "3px",
                          }}
                          bordered={false}
                          placeholder="Select Sensors"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.localeCompare(optionB.children)
                          }
                          dropdownStyle={{
                            maxHeight: 400,
                            overflow: "auto",
                          }}
                          onChange={(value, option) => {
                            setMachineDataReq({
                              ...machineDataReq,
                              sensor_id: value
                            });
                          }}
                        >
                          {sensorData?.map((item, index) => (
                            <AntSelect.Option
                              key={index}
                              value={item?.sensor_id}
                            >
                              {item?.sensor_name}
                            </AntSelect.Option>
                          ))}
                        </AntSelect>
                        <Tooltip title="Add Sensor">
                          <IconButton
                            onClick={() => {
                              setIsSensorVisible(true);
                            }}
                          >
                            <AddIcon
                              style={{
                                color: "#00C853",
                                fontSize: "32px",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="title">SOP Document</InputLabel>
                      <OutlinedInput
                        id="title"
                        size="medium"
                        type="file"
                        name="email"
                        fullWidth
                        //value={machineDataReq["SOP_document"]}
                        onChange={(e) =>
                          setMachineDataReq({
                            ...machineDataReq,
                            SOP_document: e.target.files[0],
                          })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="title">Equipment Manual</InputLabel>
                      <OutlinedInput
                        id="title"
                        size="medium"
                        type="file"
                        name="email"
                        fullWidth
                        //    value={machineDataReq["equipment_manual"]}
                        onChange={(e) =>
                          setMachineDataReq({
                            ...machineDataReq,
                            equipment_manual: e.target.files[0],
                          })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="title">
                        Maintenance Instructions Manual
                      </InputLabel>
                      <OutlinedInput
                        id="title"
                        size="medium"
                        type="file"
                        name="email"
                        fullWidth
                        //value={machineDataReq["maintenance_instructions_manual"]}
                        onChange={(e) =>
                          setMachineDataReq({
                            ...machineDataReq,
                            maintenance_instructions_manual: e.target.files[0],
                          })
                        }
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <InputLabel required htmlFor="description">
                        Specification
                      </InputLabel>
                      <Stack
                        direction="row"
                        justifyContent="start"
                        flexWrap="wrap"
                      >
                        {machineDataReq["parameter"]?.map((item, index) => {
                          let value;

                          if (item?.data_type === "integer") {
                            value = `${item?.min_value}-${item?.max_value}`;
                          } else if (item?.data_type === "string") {
                            value = item?.value;
                          } else if (item?.data_type === "boolean") {
                            value = `${item?.bool_value}`;
                          }

                          return (
                            <Chip
                              sx={{ m: 0.5 }}
                              label={`${
                                parameterData?.find(
                                  (obj) => obj.id === item?.parameter_name_id
                                )?.parameter_name
                              } : ${value}`}
                              variant="outlined"
                              // icon={
                              //   <IconButton
                              //     onClick={() => {
                              //       edit_attributes(item, index);
                              //     }}
                              //   >
                              //     <EditOutlined />
                              //   </IconButton>
                              // }
                              onDelete={() => {
                                const temp = machineDataReq[
                                  "parameter"
                                ]?.filter(
                                  (obj) =>
                                    obj.parameter_name_id !==
                                    item.parameter_name_id
                                );
                                setMachineDataReq({
                                  ...machineDataReq,
                                  parameter: temp,
                                });
                              }}
                            />
                          );
                        })}

                        <Button
                          onClick={() => {
                            set_addSpecOpen(true);
                            set_attribute_details({
                              parameter_name_id: "",
                              data_type: "",
                            });
                          }}
                        >
                          <AddIcon color="primary" />
                        </Button>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      spacing={1}
                      sx={{ m: 1 }}
                    >
                      <Button
                        size="small"
                        type="reset"
                        variant="contained"
                        color="primary"
                        onClick={reset_function}
                      >
                        Reset
                      </Button>
                      <Button
                        size="small"
                        type="submit"
                        disabled={
                          !(
                            machineDataReq["location"] !== "" &&
                            machineDataReq["machine_type_id"] !== "" &&
                            machineDataReq["operational_status"] !== "" &&
                            machineDataReq["tester_id"] !== ""
                          )
                        }
                        variant="contained"
                        color="primary"
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          )}
        </Grid>
        {!show_table && (
          <Grid item container style={{ marginTop: "-5px" }}>
            <Grid item xs={12} lg={12}>
              <Stack direction="row" spacing={1} justifyContent="end">
              <Tooltip title="Generate QR's">
                    <IconButton
                      color="primary"
                      onClick={() => {
                        navigate("/generate-qr",{ state: {from:"machine"} });
                      }}
                    >
                      <QrCodeIcon sx={{ fontSize: "29px" }} color="primary" />
                    </IconButton>
              </Tooltip>
                <Tooltip title="Add Machine">
                  <IconButton onClick={() => set_show_form(!show_form)}>
                    {!show_form && (
                      <AddIcon color="primary" style={{ fontSize: "35px" }} />
                    )}
                  </IconButton>
                </Tooltip>
                <Input.Search
                  Placeholder="Search here..."
                  className="my-1"
                  size="large"
                  style={{ width: "15%" }}
                  onSearch={(value) => {
                    setSearchvalue(value);
                  }}
                  onChange={(e) => setSearchvalue(e.target.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} lg={12}>
              <Table
                dataSource={machineData}
                columns={machineDetailColumns}
                size="small"
                bordered
                pagination={true}
              />
            </Grid>
          </Grid>
        )}

        <Modal
          open={addSpecOpen}
          title="Add Specifications"
          footer={[]}
          onCancel={() => {
            set_addSpecOpen(false);
            set_attribute_details({});
          }}
        >
          <form ref={formRef2} onSubmit={handleAddParameter}>
            <Grid container spacing={2}>
              <Grid container item lg={12} spacing={1}>
                <Grid item lg={12}>
                  <InputLabel htmlFor="title">Parameter Name</InputLabel>
                </Grid>
                <Grid item lg={12}>
                  <Stack direction="row" justifyContent="end" spacing={1}>
                    <AntSelect
                      allowClear
                      showSearch
                      id="demo-select-small"
                      value={attribute_details["parameter_name_id"] || null}
                      size="large"
                      style={{
                        width: "100%",
                        border: "0.5px solid #D9D9D9",
                        borderRadius: "3px",
                      }}
                      bordered={false}
                      placeholder="Select Parameter"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.localeCompare(optionB.children)
                      }
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      onChange={(value, option) => {
                        set_attribute_details({
                          ...attribute_details,
                          parameter_name_id: value,
                        });
                      }}
                    >
                      {parameterData?.map((item) => (
                        <AntSelect.Option key={item.id} value={item.id}>
                          {item.parameter_name}
                        </AntSelect.Option>
                      ))}
                    </AntSelect>

                    <Tooltip title="Add Parameter">
                      <IconButton
                        onClick={() => {
                          set_show_modal(true);
                          set_add_number(4);
                        }}
                      >
                        <AddIcon
                          style={{
                            color: "#00C853",
                            fontSize: "32px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Grid>
              </Grid>

              <Grid item lg={12}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="title">Data Type</InputLabel>

                  <AntSelect
                    allowClear
                    showSearch
                    id="demo-select-small"
                    value={attribute_details["data_type"] || null}
                    size="large"
                    style={{
                      width: "100%",
                      border: "0.5px solid #D9D9D9",
                      borderRadius: "3px",
                    }}
                    bordered={false}
                    placeholder="Select Datatype"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.localeCompare(optionB.children)
                    }
                    dropdownStyle={{
                      maxHeight: 400,
                      overflow: "auto",
                    }}
                    onChange={(value, option) => {
                      set_attribute_details({
                        ...attribute_details,
                        data_type: value,
                        bool_value: "",
                      });
                    }}
                  >
                    {[
                      {
                        label: "Integer",
                        value: "integer",
                      },
                      {
                        label: "String",
                        value: "string",
                      },
                      {
                        label: "Boolean",
                        value: "boolean",
                      },
                    ]?.map((item) => (
                      <AntSelect.Option key={item.value} value={item.value}>
                        {item.label}
                      </AntSelect.Option>
                    ))}
                  </AntSelect>
                </Stack>
              </Grid>
              <Grid container item lg={12} spacing={1}>
                {attribute_details["data_type"] && (
                  <Grid item lg={12}>
                    <InputLabel htmlFor="title">Value</InputLabel>
                  </Grid>
                )}
                <Grid container item lg={12} spacing={1}>
                  {/* Integer */}
                  {attribute_details["data_type"] == "integer" && (
                    <React.Fragment>
                      <Grid item lg={6}>
                        <TextField
                          type="number"
                          placeholder="Min Value"
                          fullWidth
                          required
                          // value={attribute_details["min_value"]}
                          onChange={(e) =>
                            set_attribute_details({
                              ...attribute_details,
                              min_value: e.target.value,
                            })
                          }
                        />
                        {/* {error_min && (
                      <FormHelperText error={error_min}>
                        Value should be more than 0
                      </FormHelperText>
                    )} */}
                      </Grid>
                      <Grid item lg={6}>
                        <TextField
                          type="number"
                          placeholder="Max Value"
                          fullWidth
                          required
                          inputProps={{ min: attribute_details["min_value"] }}
                          // value={attribute_details["max_value"]}
                          onChange={(e) =>
                            set_attribute_details({
                              ...attribute_details,
                              max_value: e.target.value,
                            })
                          }
                        />
                        {/* {error_max && (
                      <FormHelperText error={error_max}>
                        Value should be more than minimum value
                      </FormHelperText>
                    )} */}
                      </Grid>
                    </React.Fragment>
                  )}

                  {/* String */}
                  {attribute_details["data_type"] == "string" && (
                    <Grid item lg={12}>
                      <TextField
                        type="text"
                        placeholder="Value"
                        fullWidth
                        required
                        //  value={attribute_details["value"]}
                        onChange={(e) =>
                          set_attribute_details({
                            ...attribute_details,
                            value: e.target.value,
                          })
                        }
                      />
                    </Grid>
                  )}
                  {/* BOOLEAN */}
                  {attribute_details["data_type"] == "boolean" && (
                    <RadioGroup
                      // value={attribute_details["bool_value"]}
                      onChange={(e) =>
                        set_attribute_details({
                          ...attribute_details,
                          bool_value: e.target.value,
                        })
                      }
                    >
                      <Stack direction="row" spacing={1}>
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </Stack>
                    </RadioGroup>
                  )}
                </Grid>
              </Grid>
              <Grid item lg={12}>
                <Stack direction="row" spacing={2} justifyContent="end">
                  <Button
                    variant="outlined"
                    type="reset"
                    onClick={() => {
                      set_addSpecOpen(false);
                      set_attribute_details({});
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={
                      attribute_details.data_type === "boolean" &&
                      !attribute_details.bool_value
                    }
                  >
                    Add
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Modal>
        <ConfigModal
          show_modal={show_modal}
          set_show_modal={set_show_modal}
          add_number={add_number}
          set_add_number={set_add_number}
          get_status={get_status}
          set_get_status={set_get_status}
        />
        <Modal
          title="Sensor Configuration"
          visible={isSensorVisible}
          onCancel={()=>{setIsSensorVisible(false)}}
          footer={null} // Removes default OK/Cancel buttons
        >
          <SensorConfigForm
            isRefresh={isRefresh}
            setIsSensorVisible={setIsSensorVisible}
          />
        </Modal>
      </MainCard>
    </>
  );
}

export default Machine;
