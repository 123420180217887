import { useEffect, useRef, useState } from "react";
import ReactFlow, {
  Controls,
  Background,
  ReactFlowProvider,
  MarkerType,
  useReactFlow,
  Panel,
  BackgroundVariant,
} from "reactflow";
import { useNavigate } from "react-router-dom";
import "reactflow/dist/style.css";
import { Stack, IconButton, Tooltip } from "@mui/material";
import { Badge } from "antd";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FitScreenIcon from "@mui/icons-material/FitScreen";
import CloseIcon from "@mui/icons-material/Close";
import VerticalAlignCenterIcon from "@mui/icons-material/VerticalAlignCenter";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import "../../assets/styles/flow.css";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import createLayout from "./nodes_and_edges";
import { get_api_function } from "utils/api";

const color_json = {
  Completed: "#28A745",
  "In Progress": "#1e90ff",
  Pending: "#ff6d00",
};

function Flow({ data, set_flow_data, isClickable }) {
  const [graph, setGraph] = useState(null);
  const [align, setAlign] = useState(true);
  const [all_data, set_all_data] = useState();
  const navigate = useNavigate();
  const call_function = async (
    { operation_list, process_list, operation_edges, process_edges },
    align_align
  ) => {
    const { nodes, edges } = await createLayout(
      operation_list,
      process_list,
      [
        ...operation_edges,
        ...process_edges?.map((_) => ({
          ..._,
          hidden: true,
        })),
      ],
      align_align
    );
    setGraph({ nodes, edges });
  };

  const [dag_nodes, set_dag_nodes] = useState([]);
  const get_function = (al) => {
    get_api_function(
      `/get_operation_operation_type`,
      (res) => {
        let data = res.data.data;
        set_all_data(data[0]);
        call_function(data[0], al); // use align state here
        set_dag_nodes(
          data[0]["operation_list"]
            ?.filter((item) => item?.status === "In Progress")
            ?.map((item) => item?.operationId)
        );
      },
      {
        ...(data?.batch_number ? { batch_number: data?.batch_number } : {}),
        ...(data?.recipe_id ? { recipe_id: data?.recipe_id } : {}),
      }
    );
  };

  useEffect(() => {
    const fetchflow = () => {
      get_function(true);
    };

    fetchflow();
    const intervalId = setInterval(fetchflow, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, [data]); // exclude align from the dependency array

  // Separate useEffect for alignment changes
  useEffect(() => {
    if (all_data) {
      call_function(all_data, align);
    }
  }, [align, all_data]);

  const { fitView, setViewport, setCenter, zoomTo } = useReactFlow();
  const divRef = useRef(null);
  const handle = useFullScreenHandle();

  const handleFitView = () => {
    if (divRef.current) {
      fitView();
    }
  };

  const handleNodeClick = (event, node) => {
    if (node?.data?.data?.operationId && isClickable) {
      const stateparam = {
        operation_id: node?.data?.data?.operationId,
        process_id: node?.data?.data?.process,
        recipe_name: node?.data?.data?.recipe_name,
        process_name: node?.data?.data?.process_name,
        edit_recipe_id: node?.data?.data?.recipe_id,
        fromDag:"dag"
      };
      window?.localStorage?.setItem(
        "editOperation",
        JSON.stringify(stateparam)
      );
      navigate("/edit-operation", {
        state: stateparam,
      });
    }
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      fitView({
        padding: 0,
        nodes: dag_nodes?.map((ids) => ({ id: ids })),
      });
    });

    if (divRef.current) {
      resizeObserver.observe(divRef.current);
    }

    return () => {
      if (divRef.current) {
        resizeObserver.unobserve(divRef.current);
      }
    };
  }, [fitView, dag_nodes]);

  return (
    <FullScreen handle={handle}>
      <div
        ref={divRef}
        style={{
          height: handle.active ? "100vh" : "20vh",
          minHeight: "20vh",
          maxHeight: handle.active ? "100vh" : "60vh",
          resize: "vertical",
          overflow: "auto",
        }}
      >
        {graph && (
          <ReactFlow
            nodes={graph?.nodes}
            edges={graph?.edges}
            nodesDraggable={false}
            nodesConnectable={false}
            fitView
            edgesUpdatable={false}
            draggable={false}
            onBeforeDelete={() => false}
            deleteKeyCode={[]}
            colorMode="light"
            onNodeClick={handleNodeClick}
            style={{ backgroundColor: "white" }}
            proOptions={{ hideAttribution: true }}
          >
            <Controls
              showZoom={false}
              showFitView={false}
              showInteractive={false}
            >
              <Stack direction="column" justifyContent="center">
                {/* <Tooltip
                  title={align ? "Align Verical" : "Align Horizontal"}
                  placement="right"
                >
                  <IconButton
                    onClick={() => {
                      get_function(!align);
                      setAlign(!align);
                    }}
                    size="small"
                  >
                    <VerticalAlignCenterIcon
                      sx={{
                        transform: align ? "" : "rotate(90deg)",
                      }}
                    />
                  </IconButton>
                </Tooltip> */}

                <Tooltip
                  title={
                    handle.active ? "Exit Full Screen" : "Enter Full Screen"
                  }
                  placement="right"
                >
                  <IconButton
                    onClick={() => {
                      handle.active ? handle.exit() : handle.enter();
                      fitView();
                    }}
                    size="small"
                  >
                    {handle.active ? (
                      <FullscreenExitIcon />
                    ) : (
                      <FullscreenIcon />
                    )}
                  </IconButton>
                </Tooltip>

                <Tooltip title="Fit View" placement="right">
                  <IconButton
                    onClick={() => {
                      handleFitView();
                    }}
                    size="small"
                  >
                    <FitScreenIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Close" placement="right">
                  <IconButton
                    onClick={() => {
                      set_flow_data(null);
                    }}
                    size="small"
                  >
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Controls>
            {data?.batch_number && (
              <Panel position="top-right">
                <Stack direction="row" spacing={0.5}>
                  {Object.keys(color_json)?.map((item) => (
                    <Badge color={color_json[item]} count={item} />
                  ))}
                </Stack>
              </Panel>
            )}
          </ReactFlow>
        )}
      </div>
    </FullScreen>
  );
}

export default function ({ data, set_flow_data, isClickable }) {
  return (
    <ReactFlowProvider>
      <Flow
        data={data}
        set_flow_data={set_flow_data}
        isClickable={isClickable}
      />
    </ReactFlowProvider>
  );
}
