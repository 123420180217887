import React, { useEffect, useState, useRef, createContext } from "react";
import {
  bp_get_all_Ingredients,
  bp_units_data,
  bp_parameters_data,
  bp_machine_cat_data,
  bp_inventory_data,
  bp_product_inv_data,
  add_process_details,
  get_api_function,
  bp_get_machine_by_machine_typ,
  getProcessByRecipeId,
  get_process_operation_by_recipe,
  department_data,
  bp_get_operation_type,
  get_device_details,
  add_operationType,
  bp_get_all_testing_type,
  //add_new_operation_in_recipe,
  add_new_operation_in_operation,
  bp_get_role,
  bp_material_type_data,
} from "../../../utils/api";
import { CaretDownOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Table,
  Pagination,
  Modal,
  Popconfirm,
  Popover,
  Checkbox as Checkbox1,
  Drawer,
  theme,
  Space,
  Typography as Typography1,
} from "antd";
import { useNavigate } from "react-router-dom";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { Input, Select as Select1, Switch, TreeSelect, Divider } from "antd";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { Badge, Descriptions, ConfigProvider, Radio } from "antd";
import VisibilityIcon from "@mui/icons-material/Visibility";
import TableInModal from "components/TableInModal";
import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import InfoComponent from "components/Info";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";
import AddDevice from "./AddDevice";
import {
  InputLabel,
  OutlinedInput,
  TextareaAutosize,
  Stack,
  Chip,
  Select,
  MenuItem,
  FormControlLabel,
  Tooltip,
  Grid,
  InputAdornment,
  FormHelperText,
  IconButton,
  Button,
  Checkbox,
  ListItemText,
  Box,
  FormGroup,
  Typography,
} from "@mui/material";
import MainCard from "components/MainCard";
import ConfigureRecipe from "../ConfigureRecipe";
import ConfigModal from "../Configurations/ConfigModal";
import Machine from "../Configurations/Machine";
import SampleConfig from "../Configurations/SampleConfig";
import { useDispatch, useSelector } from "react-redux";
import { set_submit_status } from "store/reducers/submit_status";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TestFlow from "../flow/TestFlow";
import { setnewProcessDataShow } from "store/reducers/newProcessData";
import { generateEdgeId, generateOpId } from "pages/reusable_functions";
import { useLocation } from "react-router-dom";

function AddProcessOperation() {
  const dispatch = useDispatch();
  const { Paragraph } = Typography1;
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };
  const { Option } = Select1;
  const navigate = useNavigate();
  const { TextArea } = Input;
  const [unit, setUnitData] = useState();
  const [machineData, setMachineData] = useState([]);
  const [isView, setIsView] = useState(false);
  const [isIngredients, setIsIngredients] = useState(false);
  const [conditionalparam, setIsConditionalParam] = useState(false);
  const [isSampaling, setIsSampaling] = useState(false);
  const [isMonitoring, setismonitoring] = useState(false);
  const [isProduct, setisProduct] = useState(false);
  const [isInstruction, setIsInstruction] = useState(false);
  const [instruction, setInstruction] = useState();
  const [automanual, set_automanual] = useState("Manual");
  const [monitoring, set_monitoring] = useState("Manual");
  const [operationNumber, setOperationNumber] = useState(0);
  const [exeTimeline, setExeTimeline] = useState(false);
  const [operationName, setOperationName] = useState("");
  const [ingredientsData, setIngredientsData] = useState([]);
  const [material_type_list, set_material_type_list] = useState([]);
  const [parameterData, setParameterData] = useState();
  const [inventory_data, set_inventory_data] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [sourceData, setSourceData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [modalTitle, setModalTitle] = useState();
  const [modalWidth, setModalWidth] = useState();
  const [equipSampletitle, setEquipSampletitle] = useState();
  const recipe_id = localStorage.getItem("recipe_md_id");
  const recipe = localStorage.getItem("recipe_name");
  const [processDataByRecipeId, set_processDataByRecipeId] = useState([]);
  const [machineName, setMachineName] = useState([]);
  const [testingTypeList, setTestingTypeList] = useState([]);
  const [department_dataList, set_department_data_list] = useState([]);
  const [operationTypeList, setOperationTypes] = useState([]);
  const [rolesList, setRoleList] = useState([]);
  const [dependantOperations, setDependentOperations] = useState([]);
  const [dependant_operation, set_dependant_opId] = useState([]);
  const [isOperationTypeAdd, setisOperationTypeAdd] = useState(false);
  const [add_name, set_add_name] = useState();
  const [maxresult1, setResult1] = useState("");
  const [selectedDevice, setSelectedDevice] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [isMachine, set_isMachine] = useState("");
  const [instructions, setInstructions] = useState([]);
  const location = useLocation();
  const [startTime, setStartTime] = useState(false);
  const [endTime, setEndTime] = useState(false);
  const handleStartChange = (e) => {
    if (e.target.checked) {
      setStartTime(true);
      set_monitoring("Auto");
      setMonitorReading("");
    } else {
      set_monitoring("Manual");
      setStartTime(false);
    }
  };
  const handleEndChange = (e) => {
    if (e.target.checked) {
      setEndTime(true);
      set_monitoring("Auto");
      setMonitorReading("");
    } else {
      set_monitoring("Manual");
      setEndTime(false);
    }
  };
  // Check if the  already exists data
  const updateExistingRecord = (
    prevDetails,
    idField,
    newDetail,
    selectedId
  ) => {
    const existingIndex = prevDetails?.findIndex(
      (item) => item[idField] === selectedId
    );
    console.log(existingIndex);

    if (existingIndex !== -1) {
      // If it exists, create a new array with the updated item
      return prevDetails?.map((item, index) =>
        index === existingIndex ? { ...item, ...newDetail } : item
      );
    } else {
      // If it doesn't exist, add the new item
      return [...prevDetails, newDetail];
    }
  };

  const { editProcess, editProcessName, recipeId, level, recipeName } =
    location.state || {};
  const newOper = JSON.parse(window?.localStorage?.getItem("newOperation"));
  const [processName, setProcessName] = useState(editProcessName);
  const handleInstructionChange = (value) => {
    if (value) {
      setInstruction(value);
    } else {
      setInstruction("");
    }
  };

  const handleOperationSubmit = () => {
    handleNextOperationChange();
    // call the api
  };

  const containerStyle = {
    position: "relative",
    height: "100%",
    right: 0,
    padding: 20,
    border: "1px solid black",
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const toggleDrawer = (open) => {
    setIsDrawerOpen(open);
  };

  const addInstruction = () => {
    if (instruction) {
      setInstructions([...instructions, instruction]);
    }
    setInstruction("");
  };

  const removeInstruction = (index) => {
    const newInstructions = instructions.filter((_, i) => i !== index);
    setInstructions(newInstructions);
  };

  const truncateString = (str) => {
    if (str?.length > 4) {
      return str?.substring(0, 4) + "...";
    }
    return str;
  };

  const handleConvertMinutesTime = (timeString) => {
    // Split the time string into hours, minutes, and seconds
    const [hours, minutes] = timeString.split(":").map(Number);
    // Convert hours and minutes to total minutes
    const totalMinutes = hours * 60 + minutes;
    return totalMinutes;
  };

  // Add Recipe Config Modal
  const [is_model_open, set_is_model_open] = useState(false);
  const [type_id, set_type_id] = useState(0);
  const [title, set_title] = useState("");
  const [get_submit_status, set_get_submit_status] = useState(false);

  // Add Parameter Modal

  const [show_modal, set_show_modal] = useState(false);
  const [add_number, set_add_number] = useState(0);
  const [get_status, set_get_status] = useState(false);

  // machine modal

  const [machine_modal, set_machine_modal] = useState(false);
  const [mod_number, set_mod_number] = useState(0);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const treeData = (rawData) => {
    return rawData?.map((process) => ({
      title: process?.process_name,
      value: process?.process_name,
      key: process?.process_name,
      selectable: false,
      children: process?.operation?.map((operation) => ({
        title: `Operation ${operation.operation_number}`, // Apdevessuming you want to display the operation number as the title
        value: `${operation?.operation_number.toString()}`, // Using the operation_id as the value
        key: operation.operation_number.toString(),
        selectable: true,
      })),
    }));
  };

  const handleChange = (value) => {
    console.log(typeof value === "string" ? value.split(",") : value);

    setMachineName(typeof value === "string" ? value.split(",") : value);
  };

  //Duration
  const [minDuration, setMinDuration] = useState("00:05");
  const [maxDuration, setMaxDuration] = useState("00:05");

  //Equipment
  const [selectedEquipment, setSelectedEquipment] = useState("");

  //ingredients
  const [selectedIngredent, setselectIngredent] = useState("");
  const [is_percentage, set_is_percentage] = useState(false);
  const [ingredentQuantity, setIngredentQuantity] = useState();
  const [ingredientunit, setIngredientUnit] = useState();
  const [ingredautomanual, set_ingredautomanual] = useState("Manual");
  const [deviation, setDeviation] = useState("");
  const [ingredInstruct, setIngredInstruct] = useState("");

  //products
  const [allProductInv, setProductInv] = useState();
  const [selectedProduct, setSelectedProduct] = useState("");
  const [productMinRange, setProductMinRange] = useState();
  const [productMaxRange, setProductMaxRange] = useState();
  const [actualProdValue, setActualProdValue] = useState();
  const [productDetails, setProductDetails] = useState([]);
  const [productunit, setProductunit] = useState();
  const [prodInstruct, setProdInstruct] = useState("");

  //Conditional
  const [selectedConditionalparam, setConditionalparam] = useState("");
  const [conditionalMinQuantity, setConditionalMinQuantity] = useState();
  const [conditionalMaxQuantity, setConditionalMaxQuantity] = useState();
  const [conditionalparamDetails, setConditionalparaDetails] = useState([]);
  const [condparamunit, setCondparamUnit] = useState();

  //monitoring
  const [selectedMonitoringparam, setMonitoringParam] = useState("");
  const [monitoringMinQuantity, setMonitoringMinQuantity] = useState("");
  const [sensorData, setSensorData] = useState([]);
  const [selectedSensor, set_selectedSensor] = useState("");
  const [monitoringQuantity, setMonitoringQuantity] = useState("");
  const [monitorReading, setMonitorReading] = useState("");
  const [monitoringMaxQuantity, setMonitoringMaxQuantity] = useState("");
  const [monitoringDetails, setMonitoringDetails] = useState([]);
  const [monInstruct, setMonInstruct] = useState("");

  //sampling
  const [selectedSampalingParam, setselectedSampalingParam] = useState("");
  const [samplingQuantity, setSamplingQuantity] = useState();
  const [testingType, setTestingType] = useState("");
  const [samplingTo, setSampingTo] = useState("");
  const [samplingDetails, set_SamplingDetails] = useState([]);
  const [samplingUnit, setSamplingUnit] = useState();
  const [machinesByType, set_machinesByType] = useState([]);
  const [sampleInstruct, setSampleInstruct] = useState("");

  //Sampling Conditional
  const [samplingConditionalparam, setSampConditionalparam] = useState("");
  const [sampMinQuantity, setSampMinQuantity] = useState();
  const [sampMaxQuantity, setSampMaxQuantity] = useState();
  const [sampDescription, setSampDescription] = useState("");
  const [sampParamUnit, setSampParamUnit] = useState();

  //Operations List
  const [operationsList, setOperationsList] = useState([]);
  const [preViewOperationsList, setPreviewOperationsList] = useState([]);

  //ProcessRoll
  const [processRollList, setProcessRollList] = useState([]);

  //Device
  const [deviceDetails, setDeviceDetails] = useState([]);

  //Roll list Change
  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      // Checkbox is checked, add the role_id to the list
      setProcessRollList([...processRollList, { role_id: id }]);
    } else {
      // Checkbox is unchecked, remove the role_id from the list
      setProcessRollList(processRollList.filter((item) => item.role_id !== id));
    }
  };

  //take unit for sampling
  const takeSamplingInput = (dataList, actualParam) => {
    if (dataList.length > 0) {
      const matchingData = dataList.find(
        (data) => data.material_name === actualParam
      );

      if (matchingData) {
        return matchingData.unit;
      }
    }
    return null;
  };

  const getParameterIdByName = (paramName) => {
    // Find the parameter object with the given parameter name
    const parameter = parameterData?.find(
      (data) => data.parameter_name === paramName
    );
    if (parameter) {
      return parameter.id;
    } else {
      return null;
    }
  };

  const getOperationTypeNameById = (typeId) => {
    // Find the parameter object with the given parameter name
    const type = operationTypeList?.find(
      (data) => data.operation_type_id === typeId
    );
    if (type) {
      return type.operation_type;
    } else {
      return "";
    }
  };

  const getIngredientIdByName = (ingredName) => {
    // Find the parameter object with the given parameter name
    const ingredId = inventory_data?.find(
      (data) => data.material_name === ingredName
    );
    if (ingredId) {
      return ingredId.material_id;
    } else {
      return null;
    }
  };

  const getDepartmentIdByName = (deptName) => {
    // Find the parameter object with the given parameter name
    const ingredId = department_dataList?.find(
      (data) => data.department_name === deptName
    );
    if (ingredId) {
      return ingredId.id;
    } else {
      return null;
    }
  };

  const getIngredientUnitByName = (ingredName) => {
    // Find the parameter object with the given parameter name
    const ingredId = inventory_data?.find(
      (data) => data.material_name === ingredName
    );
    if (ingredId) {
      return ingredId.unit;
    } else {
      return null;
    }
  };

  const getParamUnitByName = (paramName) => {
    // Find the parameter object with the given parameter name
    const paramUnit = parameterData?.find(
      (data) => data.parameter_name === paramName
    );
    if (paramUnit) {
      return paramUnit.unit;
    } else {
      return null;
    }
  };

  const getTestIdByName = (testname) => {
    // Find the parameter object with the given parameter name
    const testId = testingTypeList?.find(
      (data) => data.testing_type === testname
    );
    if (testId) {
      return testId.testing_type_id;
    } else {
      return null;
    }
  };

  const get_material_type_id = (material_type_name) => {
    // Find the parameter object with the given parameter name
    return material_type_list?.find(
      (data) => data.material_type === material_type_name
    )?.material_type_id;
  };

  const handleTreeSelectChange = (value) => {
    set_dependant_opId(value);
  };

  function get_process_ids(operationIds) {
    return dependent_operation_list
      ?.filter((process) => {
        // Get the list of operation IDs for this process
        const operationIdsInProcess = process.operation.map(
          (op) => op.operation_number
        );
        // Check if at least one operation ID in the input is in this process
        return operationIds.some((id) => operationIdsInProcess.includes(id));
      })
      .map((process) => process.process_id);
  }

  function find_operation_id(operationNumbers) {
    const result = operationNumbers.map((opNum) => {
      // Convert opNum to a number to compare
      const opNumber = parseInt(`${opNum}`.trim(), 10);

      // Search for the operation in processData
      for (let process of dependent_operation_list) {
        for (let operation of process.operation) {
          if (operation.operation_number === opNumber) {
            return operation.operation_id;
          }
        }
      }

      // If not found, return the operation number as string
      return `${opNum}`.trim();
    });

    return result;
  }

  const newProcessData = useSelector((state) => state.newProcessData);

  //handle Next Operation
  const handleNextOperationChange = () => {
    //  if(isIngredients && conditionalparamDetails &&
    //   conditionalparamDetails.length > 0){
    //  }
    if (processName) {
      // const temp_op_id = generateOpId();
      let operation_edges_temp = find_operation_id(dependant_operation)?.map(
        (i) => ({
          id: `${generateEdgeId()}`,
          source: `${i}`,
          target: `${operationNumber + 1}`,
        })
      );

      let process_edges_temp = [];
      if (dependant_operation?.length > 0) {
        process_edges_temp = get_process_ids(
          dependant_operation?.map((i) => parseInt(i))
        )?.map((item) => ({
          id: generateEdgeId(),
          source: `${item}`,
          target: localStorage.getItem("newProcessId"),
        }));
      }

      dispatch(
        setnewProcessDataShow({
          ...newProcessData,
          operation_list: [
            ...newProcessData?.operation_list,
            {
              operationId: `${operationNumber + 1}`,
              process: editProcess,
              operation: operationTypeList?.find(
                (i) => i?.operation_type_id === operationType
              )?.operation_type,
            },
          ],
          operation_edges: [
            ...newProcessData?.operation_edges,
            ...operation_edges_temp,
          ],
          process_edges: [
            ...newProcessData?.process_edges,
            ...process_edges_temp,
          ],
        })
      );
    }

    if (processName) {
      const newOperation = {
        operation_number: operationNumber + 1,
        dependencies: dependant_operation?.join(", ") || "",
        operation_name: operationName,
        operation_type_id: operationType,
        is_fullTime: exeTimeline,
        department_id: getDepartmentIdByName(operationBy),
        instruction: instructions,
        ...(automanual &&
          dependant_operation &&
          dependant_operation?.length > 0 && { auto_status: automanual }),
        mass: disabled,
        min_duration: minDuration,
        max_duration: maxDuration,
        ...(automanual === "Auto" &&
          selectedDevice !== "" &&
          dependant_operation &&
          dependant_operation?.length > 0 && { device_id: selectedDevice }),
        ...(productDetails &&
          productDetails.length > 0 && {
            process_products: productDetails?.map((obj) => ({
              process_thero_quantity: obj.actualProdValue,
              process_expec_min_value: obj.minValue,
              process_expec_max_value: obj.maxvalue,
              product_inventory_id: getIngredientIdByName(obj.param_id),
              ...(obj?.prod_instruction && {
                prod_instruction: obj.prod_instruction,
              }),
            })),
          }), // handle if productDetails is undefined

        ...(conditionalparamDetails &&
          conditionalparamDetails.length > 0 && {
            ingredients: conditionalparamDetails?.map((obj) => ({
              quantity: obj.quantity,
              ...(obj?.is_percentage && { is_percentage: obj.is_percentage }),
              material_inventory_id: getIngredientIdByName(obj.ingredients_id),
              ...(obj.param_id !== "" && {
                parameter_id: getParameterIdByName(obj.param_id),
              }),
              deviation: obj.deviation,
              auto_status: ingredautomanual,
              min_value: obj.minValue,
              max_value: obj.maxvalue,
              ...(obj?.ingre_instruction && {
                ingre_instruction: obj.ingre_instruction,
              }),
            })),
          }), // handle if conditionalparamDetails is undefined

        ...(monitoringDetails &&
          monitoringDetails.length > 0 && {
            process_parameters: monitoringDetails?.map((obj) => ({
              parameter_id: getParameterIdByName(obj.param_id),
              auto_status: obj.auto_status,
              //  para_min_value: obj.fromTime,
              ...(obj?.fromTime && {
                readings: obj.fromTime,
                para_max_value:
                  handleConvertMinutesTime(maxDuration) / obj.fromTime,
              }),
              is_start: obj.startTime,
              is_end: obj.endTime,
              qunatity: obj.qunatity,
              ...(obj?.sensor &&
                monitoring === "Auto" && { sensor_id: obj.sensor }),
              ...(obj?.min_value && { min_value: obj.min_value }),
              ...(obj?.max_value && { max_value: obj.max_value }),
              ...(obj?.param_instruction && {
                param_instruction: obj.param_instruction,
              }),
            })),
          }), // handle if monitoringDetails is undefined

        ...(samplingDetails &&
          samplingDetails.length > 0 && {
            sampling: samplingDetails.map((obj) => ({
              material_inventory_id: getIngredientIdByName(obj.sample),
              quantity: obj.quantity,
              to: getDepartmentIdByName(obj.to),
              testing_type_id: getTestIdByName(obj.test_type_id),
              ...(obj?.parameter_id && {
                parameter_id: getParameterIdByName(obj.parameter_id),
              }),
              ...(obj?.min_value && { min_value: obj.min_value }),
              ...(obj?.max_value && { max_value: obj.max_value }),
              ...(obj?.description && { description: obj.description }),
              ...(obj?.sample_instruction && {
                sample_instruction: obj.sample_instruction,
              }),
            })),
          }),

        ...(machineName &&
          machineName.length > 0 && {
            machines: machineName.map((name) => ({
              machine_id:
                machinesByType.length > 0 &&
                machinesByType.find((machine) => machine.machine_name === name)
                  ?.machine_id,
            })),
          }),
      };
      if (selectedEquipment !== "") {
        newOperation.machine_type_id = selectedEquipment;
      } // if its empty then key should not be add in the request

      const previewOperation = {
        operation_number: operationNumber + 1,
        operation_name: operationName,
        is_fullTime: exeTimeline,
        machine_type_id: selectedEquipment !== "" ? selectedEquipment : "",
        operation_type_id: getOperationTypeNameById(operationType),
        department_id: operationBy,
        instruction: instructions,
        auto_status: automanual,
        mass: disabled,
        min_duration: minDuration,
        max_duration: maxDuration,
        ...(selectedDevice !== "" && { device_id: selectedDevice }),
        ...(productDetails &&
          productDetails.length > 0 && {
            process_products: productDetails?.map((obj) => ({
              process_thero_quantity: obj.actualProdValue,
              process_expec_min_value: obj.minValue,
              process_expec_max_value: obj.maxvalue,
              product_inventory_id: obj.param_id,
              prod_instruction: obj.prod_instruction,
            })),
          }), // handle if productDetails is undefined

        ...(conditionalparamDetails &&
          conditionalparamDetails.length > 0 && {
            ingredients: conditionalparamDetails?.map((obj) => ({
              quantity: obj.quantity,
              ...(obj?.is_percentage && { is_percentage: obj.is_percentage }),
              material_inventory_id: obj.ingredients_id,
              parameter_id: obj.param_id,
              deviation: obj.deviation,
              min_value: obj.minValue,
              max_value: obj.maxvalue,
              ingre_instruction: obj.ingre_instruction,
            })),
          }), // handle if conditionalparamDetails is undefined

        ...(monitoringDetails &&
          monitoringDetails.length > 0 && {
            process_parameters: monitoringDetails?.map((obj) => ({
              parameter_id: obj.param_id,
              auto_status: obj.auto_status,
              ...(obj?.sensor &&
                monitoring === "Auto" && { sensor_id: obj.sensor }),
              qunatity: obj.qunatity,
              is_start: obj.startTime,
              is_end: obj.endTime,
              para_max_value: obj.fromTime,
              min_value: obj.min_value,
              max_value: obj.max_value,
              param_instruction: obj.param_instruction,
            })),
          }), // handle if monitoringDetails is undefined

        ...(samplingDetails &&
          samplingDetails.length > 0 && {
            sampling: samplingDetails.map((obj) => ({
              material_inventory_id: obj.sample,
              quantity: obj.quantity,
              to: obj.to,
              testing_type_id: obj.test_type_id,
              parameter_id: obj.parameter_id,
              min_value: obj.min_value,
              max_value: obj.max_value,
              description: obj.description,
            })),
          }),

        ...(machineName &&
          machineName.length > 0 && {
            machines: machineName.map((name) => ({
              machine_id:
                machinesByType.length > 0 &&
                machinesByType.find((machine) => machine.machine_name === name)
                  ?.machine_id,
            })),
          }),
      };

      setOperationsList([...operationsList, newOperation]);
      setPreviewOperationsList([...preViewOperationsList, previewOperation]);

      let parentIndex = dependantOperations?.findIndex(
        (child) => child.title === processName
      );

      const childrens = {
        title: processName,
        value: processName,
        key: processName,
        disabled: true,
        children: [
          ...operationsList?.map((operation) => ({
            title: `Operation ${operation.operation_number}`, // Display the operation number as the title
            value: operation?.operation_number, // Using the operation_id as the value
            key: operation?.operation_number,
            selectable: true,
          })),
          {
            title: `Operation ${newOperation.operation_number}`, // Display the new operation number as the title
            value: newOperation?.operation_number, // Using the new operation_id as the value
            key: newOperation?.operation_number,
            selectable: true,
          },
        ],
      };

      if (parentIndex !== -1) {
        // If parent exists, update its children
        setDependentOperations((prevDependantOperations) =>
          prevDependantOperations?.map((operation, index) =>
            index === parentIndex
              ? {
                  ...operation,
                  children: [
                    ...operation.children,
                    {
                      title: `Operation ${newOperation.operation_number}`, // Display the new operation number as the title
                      value: newOperation?.operation_number, // Using the new operation_id as the value
                      key: newOperation?.operation_number,
                      selectable: true,
                    },
                  ],
                }
              : operation
          )
        );
      } else {
        // Add new process to dependent operations
        setDependentOperations((prevDependantOperations) => [
          ...prevDependantOperations,
          childrens,
        ]);
      }

      setOperationNumber(operationNumber + 1);
      setOperationName("");
      set_SamplingDetails([]);
      setConditionalparaDetails([]);
      setMonitoringDetails([]);
      setProductDetails([]);
      setSelectedEquipment("");
      setOpeartionBy("");
      setOperationType("");
      setInstruction("");
      setInstructions([]);
      setSelectedDevice("");
      set_automanual("Manual");
      set_monitoring("Manual");
      setDisabled(true);
      setMinDuration("00:05");
      setMaxDuration("00:05");
      setIsIngredients(false);
      setIsConditionalParam(false);
      setIsSampaling(false);
      setismonitoring(false);
      setisProduct(false);
      setMachineName([]);
      setEndTime(false);
      setStartTime(false);
      setselectIngredent("");
      setIngredInstruct("");
      set_is_percentage(false);
      setIngredentQuantity("");
      setDeviation("");
      setIngredientUnit("");
      set_ingredautomanual("Manual");

      setSelectedProduct("");
      setProductMinRange("");
      setProductMaxRange("");
      setActualProdValue("");
      setProductunit("");
      setProdInstruct("");

      setConditionalparam("");
      setConditionalMinQuantity("");
      setConditionalMinQuantity("");
      setCondparamUnit("");

      setMonitoringParam("");
      setMonitoringMinQuantity("");
      setMonitorReading("");
      setMonitoringQuantity("");
      set_selectedSensor("");
      setMonitoringMaxQuantity("");
      setMonInstruct("");

      setselectedSampalingParam("");
      setSamplingQuantity("");
      setTestingType("");
      setSampingTo("");
      setSamplingUnit("");
      setSampConditionalparam("");
      setSampMinQuantity();
      setSampMaxQuantity();
      setSampleInstruct("");
      setSampDescription("");
      setSampParamUnit();
      set_dependant_opId([]);
      const addRequest = {
        recipe_id: recipeId ? recipeId : newOper?.recipeId,
        process_name: processName ? processName : newOper?.processName,
        new_recipe_name: "",
        level: level ? level : newOper?.level,
        process_id: editProcess ? editProcess : newOper?.editProcess,
        data: {
          operation: newOperation,
        },
      };
      setProcessDetails([...processDetails, addRequest]);
      add_operation(addRequest);
    } else {
      toast.warning("Process name should not be empty");
    }
  };

  const handleSummaryDetails = (data) => {
    const batchDetailsList = [];
    if (
      processDataByRecipeId &&
      processDataByRecipeId.length > 0 &&
      processDataByRecipeId[0].process_list?.length > 0
    ) {
      const processDetails = processDataByRecipeId[0].process_list[0]; // Assuming processDetails comes from data

      const filteredEntries = Object.entries(processDetails)
        .filter(([key, value]) => typeof value !== "object") // Filter out entries with object values
        .map(([key, value], index) => ({
          key: index,
          label: key,
          children: value,
        }));

      batchDetailsList.push(...filteredEntries); // Push filtered entries into batchDetailsList

      const processRoles = processDetails.process_roles
        ?.map((role) => role.role_name)
        .join(",");
      if (processRoles) {
        batchDetailsList.push({
          key: batchDetailsList.length,
          label: "Process Roles",
          children: processRoles,
        });
      }
    }

    return batchDetailsList;
  };

  const [processDetails, setProcessDetails] = useState([]);

  const [addRecipeReq, setAddRecipeReq] = useState({
    // process_name: "",
    // ingredients_id: "i",
    // ingredientsQuantity: "",
    // machine: "i",
    // parameter: "i",
    // unit: "i",
    // duration: "",
    // expected_output: "i",
    // output_quantity: "i",
    // value: "",
  });

  const handlePreviewDetails = () => {
    setIsView(true);
  };

  //####################### Handle Api's######################################################

  const add_operation = async (processData) => {
    try {
      await add_new_operation_in_operation(processData, success_add_operation);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const get_process_operation_recipe = async (opNumber, recipeid) => {
    try {
      if (recipeid) {
        await get_process_operation_by_recipe(
          (res) => {
            const filteredData = res?.data?.data
              ?.map((process) => ({
                ...process,
                operation: process?.operation?.filter(
                  (op) => op.operation_number < opNumber
                ),
              }))
              ?.filter((process) => process.operation.length > 0); // Filter data before currunt operation number

            if (res?.data?.status === "failed") {
              toast.warning(res.message);
            } else {
              setDependentOperations(treeData(filteredData));
              set_dependent_operation_list(res?.data?.data);
              set_isMachine(res?.data?.machine_name || "");
            }
          },
          {
            recipe_id: recipeid,
          }
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSensors = () => {
    get_api_function("/get_sensor_list", (res) => {
      setSensorData(res?.data?.data);
    });
  };

  const [dependent_operation_list, set_dependent_operation_list] = useState([]);

  const add_operation_type = async (typeReq) => {
    try {
      await add_operationType(typeReq, success_add_OpType);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_add_OpType = (res) => {
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      toast.success(res.message);
      bp_get_operationType();
    }
  };

  const success_add_operation = (res) => {
    console.log(res);
    if (res.status === "failed") {
      toast.warning(res.message);
    } else {
      setProcessName("");
      setOperationNumber(0);
      setExeTimeline(false);
      setOperationName("");
      set_SamplingDetails([]);
      setConditionalparaDetails([]);
      setMonitoringDetails([]);
      setProductDetails([]);
      setSelectedEquipment("");
      setOperationType("");
      setInstruction("");
      setInstructions([]);
      setSelectedDevice("");
      set_automanual("Manual");
      set_monitoring("Manual");
      setDisabled(true);
      setMinDuration("00:05");
      setMaxDuration("00:05");
      toast.success(res.message);

      navigate(-1);
      dispatch(
        setnewProcessDataShow({
          process_list: [],
          operation_list: [],
          operation_edges: [],
          process_edges: [],
        })
      );
    }
  };

  const bp_get_operationType = async () => {
    try {
      await bp_get_operation_type(succcessGetOperationTypes);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_Recipe_list_get = (res) => {
    set_processDataByRecipeId(res?.data?.data);
  };

  const bp_get_machine_by_machineType = async (machineType) => {
    try {
      const req = { machine_type_id: machineType };
      await bp_get_machine_by_machine_typ(success_machine_by_machine_typ, req);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_machine_by_machine_typ = (res) => {
    set_machinesByType(res?.data?.data);
  };

  const get_all_DropDown_data = async () => {
    try {
      await department_data(success_department_data);
      await bp_get_role(success_Role_List);
      await bp_inventory_data(success_inventory_list_get);
      await bp_get_all_Ingredients(success_ingredients_list_get);
      await bp_material_type_data(success_bp_material_type_data);
      await bp_units_data(success_unit_list_get);
      await bp_parameters_data(success_parameter_list_get);
      await bp_machine_cat_data(success_machine_list_get);
      await bp_product_inv_data(success_product_list_get);
      await bp_get_all_testing_type(succcessGetTestingTypes);
      await bp_get_operation_type(succcessGetOperationTypes);
      await get_process_operation_recipe(
        level ? parseInt(level) : parseInt(newOper?.level),
        recipeId ? recipeId : newOper?.recipeId
      );
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const get_all_DropDown_data_after_submit = async () => {
    try {
      await bp_inventory_data(success_inventory_list_get);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    try {
      bp_get_all_testing_type(succcessGetTestingTypes);
      bp_machine_cat_data(success_machine_list_get);
    } catch (error) {
      console.log(error);
    }
  }, [get_status]);

  useEffect(() => {
    try {
      get_device_details(succcessGetDeviceDetails);
    } catch (error) {
      console.log(error);
    }
  }, [modalVisible]);

  const success_department_data = (res) => {
    set_department_data_list(res?.data?.departdata);
  };

  const success_Role_List = (res) => {
    if (res?.data?.data) {
      const data = res?.data?.data;
      const rolesToFilter = ["QA", "Production Manager", "R&D"];
      const filteredList = data?.filter((item) =>
        rolesToFilter.includes(item.role)
      ); // to get exact rolls

      setRoleList(filteredList);
    }
  };

  const succcessGetTestingTypes = (res) => {
    setTestingTypeList(res?.data?.data);
  };

  const succcessGetOperationTypes = (res) => {
    setOperationTypes(res?.data?.data);
  };

  const success_inventory_list_get = (res) => {
    set_inventory_data(res?.data?.data);
  };

  const success_machine_list_get = (res) => {
    setMachineData(res?.data?.data);
  };

  const success_product_list_get = (res) => {
    setProductInv(res?.data?.data);
  };
  const succcessGetDeviceDetails = (res) => {
    setDeviceDetails(res?.data?.data);
  };

  const success_unit_list_get = (res) => {
    setUnitData(res?.data?.data);
  };

  const success_ingredients_list_get = (res) => {
    console.log("res", res);
    setIngredientsData(res?.data?.data);
  };

  const success_bp_material_type_data = (res) => {
    console.log("res", res);
    set_material_type_list(res?.data?.data);
  };

  const success_parameter_list_get = (res) => {
    console.log("res", res);
    setParameterData(res?.data?.data);
  };

  useEffect(() => {
    get_all_DropDown_data();
    fetchSensors();
    dispatch(
      setnewProcessDataShow({
        ...newProcessData,
        process_list: [
          {
            process_id: editProcess,
            process: editProcessName || "New Process",
          },
        ],
      })
    );
  }, []);

  useEffect(() => {
    get_all_DropDown_data_after_submit();
  }, [get_submit_status]);

  useEffect(() => {
    add_number === 4 && bp_parameters_data(success_parameter_list_get);
  }, [get_status]);

  // useEffect(() => {
  //   let operationValue = localStorage.getItem("last_process_op_id");
  //   if (parseInt(operationValue)) {
  //     setOperationNumber(parseInt(operationValue));
  //   }
  // }, []);

  useEffect(() => {
    if (level) {
      setOperationNumber(parseInt(level ? level - 1 : newOper?.level - 1));
    }
  }, []);
  // -----------------------------

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsVisible(false);
    setModalWidth("");
  };

  const handleOperationTypeOk = () => {
    if (add_name) {
      add_operation_type({
        name: add_name,
      });
      setisOperationTypeAdd(false);
      set_add_name("");
    } else {
      toast.warning("Enter Operation Type");
    }
  };

  const handleCancel = () => {
    setIsVisible(false);
    setModalWidth("");
  };

  // ==========================================

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [outputDetails, set_outputDetails] = useState([]);
  const [operationBy, setOpeartionBy] = useState("");
  const [operationType, setOperationType] = useState("");

  const operationsColumns = [
    {
      title: "Operation Number",
      dataIndex: "operation_number",
      key: "operation_number",
    },
    {
      title: "Machine Type",
      dataIndex: "machine_type_id",
      key: "machine_type",
      render: (data, record, index) => {
        // Assuming `machineData` is available in the component's state or props
        const machineCategory = machineData.find(
          (item) => item.machine_category_id === data
        );
        // Check if machineCategory is found
        if (machineCategory) {
          return <span>{machineCategory.machine_category_name}</span>;
        } else {
          return null; // Handle case where machine category is not found
        }
      },
    },

    {
      title: "Operation Type",
      dataIndex: "operation_type_id",
      key: "operation_type",
    },
    {
      title: "Operation By",
      dataIndex: "department_id",
      key: "department_name",
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",
    },
    {
      title: "Mass",
      dataIndex: "mass",
      key: "mass",
      render: (data, record, index) => {
        return <span>{data ? "True" : "False"}</span>;
      },
    },
    {
      title: "Duration",
      dataIndex: "max_duration",
      key: "max_duration",
      align: "center",
    },
    {
      title: "Process Products",
      dataIndex: "process_products",
      key: "process_products",
      align: "center",
      render: (data, record, index) => {
        return (
          <>
            <Button
              size="medium"
              type="reset"
              disabled={!(data && data.length > 0)}
              onClick={() => {
                setColumnData([
                  {
                    title: "Product Name",
                    dataIndex: "product_inventory_id",
                    key: "product_name",
                  },
                  {
                    title: "Process Thero Quantity",
                    dataIndex: "process_thero_quantity",
                    key: "operation_number",
                    render: (data, record, index) => {
                      return (
                        <span>{`${data} (${getIngredientUnitByName(
                          record.product_inventory_id
                        )})`}</span>
                      );
                    },
                  },
                  {
                    title: "Expected Min Value",
                    dataIndex: "process_expec_min_value",
                    key: "process_expec_min_value",
                    render: (data, record, index) => {
                      return (
                        <span>{`${data} (${getIngredientUnitByName(
                          record.product_inventory_id
                        )})`}</span>
                      );
                    },
                  },
                  {
                    title: "Expected Max Value",
                    dataIndex: "process_expec_max_value",
                    key: "process_expec_max_value",
                    render: (data, record, index) => {
                      return (
                        <span>{`${data} (${getIngredientUnitByName(
                          record.product_inventory_id
                        )}})`}</span>
                      );
                    },
                  },
                ]);
                setSourceData(data);
                setIsVisible(true);
                setModalTitle("Process Product");
              }}
            >
              <VisibilityIcon
                style={{ color: data && data.length > 0 ? "green" : "orange" }}
              />
            </Button>
          </>
        );
      },
    },
    {
      title: "Ingredients",
      dataIndex: "ingredients",
      key: "min_duration",
      align: "center",
      render: (data, record, index) => {
        return (
          <>
            <Button
              size="medium"
              disabled={!(data && data.length > 0)}
              onClick={() => {
                setColumnData([
                  {
                    title: "Material Name",
                    dataIndex: "material_inventory_id",
                    key: "material_name",
                  },
                  {
                    title: "Quantity",
                    dataIndex: "quantity",
                    key: "quantity",
                  },
                  {
                    title: "Deviation",
                    dataIndex: "deviation",
                    key: "quantity",
                    render: (data, record, index) => {
                      return `${data} %`;
                    },
                  },
                  {
                    title: "Is Observative",
                    dataIndex: "is_percentage",
                    key: "quantity",
                  },
                  {
                    title: "Parameter",
                    dataIndex: "parameter_id",
                    key: "parameter_name",
                    render: (data, record, index) => {
                      return data !== "" ? data : "";
                    },
                  },
                  {
                    title: "Min Value",
                    dataIndex: "min_value",
                    key: "min_value",
                    render: (data, record, index) => {
                      if (record?.parameter_id !== "") {
                        return (
                          <span>{`${data} (${getIngredientUnitByName(
                            record.material_inventory_id
                          )})`}</span>
                        );
                      } else {
                        return "";
                      }
                    },
                  },
                  {
                    title: "Max Value",
                    dataIndex: "max_value",
                    key: "max_value",
                    render: (data, record, index) => {
                      if (record?.parameter_id !== "") {
                        return (
                          <span>{`${data} (${getIngredientUnitByName(
                            record.material_inventory_id
                          )})`}</span>
                        );
                      } else {
                        return "";
                      }
                    },
                  },
                ]);
                setSourceData(data);
                setIsVisible(true);
                setModalTitle("Ingredients");
              }}
            >
              <VisibilityIcon
                style={{ color: data && data.length > 0 ? "green" : "orange" }}
              />
            </Button>
          </>
        );
      },
    },
    {
      title: "Process Parameters",
      dataIndex: "process_parameters",
      key: "process_parameters",
      align: "center",
      render: (data, record, index) => {
        return (
          <>
            <Button
              size="medium"
              disabled={!(data && data.length > 0)}
              onClick={() => {
                setColumnData([
                  {
                    title: "Parameter",
                    dataIndex: "parameter_id",
                    key: "parameter_name",
                  },
                  {
                    title: "No of Readings",
                    dataIndex: "para_max_value",
                    key: "parameter_min_value",
                    render: (data, record, index) => {
                      return <span>{`${data}`}</span>;
                    },
                  },
                  {
                    title: "Min",
                    dataIndex: "min_value",
                    key: "min_value",
                  },
                  {
                    title: "Max",
                    dataIndex: "max_value",
                    key: "max_value",
                  },
                ]);
                setSourceData(data);
                setIsVisible(true);
                setModalTitle("Monitoring");
              }}
            >
              <VisibilityIcon
                style={{ color: data && data.length > 0 ? "green" : "orange" }}
              />
            </Button>
          </>
        );
      },
    },
    {
      title: "Sampling",
      dataIndex: "sampling",
      key: "sampling",
      align: "center",
      render: (data, record, index) => {
        return (
          <>
            <Button
              size="medium"
              disabled={!(data && data.length > 0)}
              onClick={() => {
                setColumnData([
                  {
                    title: "Mass",
                    align: "center",
                    dataIndex: "material_inventory_id",
                    key: "sample",
                  },
                  {
                    title: "To",
                    align: "center",
                    dataIndex: "to",
                    key: "sample",
                  },
                  {
                    title: "Test Type",
                    align: "center",
                    dataIndex: "testing_type_id",
                    key: "sample",
                  },
                  {
                    title: "Quantity",
                    align: "center",
                    dataIndex: "quantity",
                    key: "quantity",
                    render: (data, record, index) => {
                      return (
                        <span>{`${data} (${
                          getIngredientUnitByName(
                            record.material_inventory_id
                          ) === "Liter"
                            ? "ml"
                            : getIngredientUnitByName(
                                record.material_inventory_id
                              ) === "KG"
                            ? "gram"
                            : "other unit"
                        })`}</span>
                      );
                    },
                  },
                  {
                    title: "Parameter",
                    align: "center",
                    dataIndex: "parameter_id",
                    key: "parameter_id",
                  },
                  {
                    title: "Min",
                    align: "center",
                    dataIndex: "min_value",
                    key: "min_value",
                  },
                  {
                    title: "Max",
                    align: "center",
                    dataIndex: "max_value",
                    key: "max_value",
                  },
                  {
                    title: "Description",
                    align: "center",
                    dataIndex: "description",
                    key: "description",
                  },
                ]);
                setSourceData(data);
                setIsVisible(true);
                setModalTitle("Sampling");
              }}
            >
              <VisibilityIcon
                style={{ color: data && data?.length > 0 ? "green" : "orange" }}
              />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <MainCard>
      <Grid container spacing={1} columns={12} style={{ display: "flex" }}>
        <Grid item container lg={12} xs={12}>
          <Grid item xs={6}>
            <span>
              <b>Recipe Name:</b> &nbsp;
              <span style={{ color: "#cc0000" }}>
                {recipeName ? recipeName : newOper?.recipeName}
              </span>
            </span>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <IconButton onClick={() => navigate(-1)}>
              <BackspaceIcon color="primary" sx={{ fontSize: "30px" }} />
            </IconButton>
          </Grid>
          <Grid item xs={6}>
            <span>
              <b>Process Name:</b> &nbsp;
              <span style={{ color: "#cc0000" }}>
                {editProcessName ? editProcessName : newOper?.editProcessName}
              </span>
            </span>
          </Grid>
        </Grid>
        <Grid container item columns={12}>
          <Grid item xs={12} lg={12}>
            {/* <MainCard
              title="Process"
              secondary={
                <IconButton
                  onClick={() => navigate("/viewRecipe", { tabId: 2 })}
                >
                  <BackspaceIcon color="primary" sx={{ fontSize: "30px" }} />
                </IconButton>
              }
            > */}
            <div style={containerStyle}>
              <Grid item container xs={12} lg={12}>
                <Grid item xs={8.4} lg={8.4}>
                  <Grid item container xs={12} lg={12}>
                    {/* <Grid item xs={12} lg={12}>
                      <TestFlow data={{ recipe_id: recipe_id }} />
                    </Grid> 
                    <Grid item xs={12} lg={12}>
                      <Divider
                        dashed={true}
                        plain={true}
                        style={{
                          border: "2px dotted gray",
                          borderColor: "#9e9e9e",
                        }}
                      ></Divider>
                    </Grid> */}
                    <Grid item xs={12} lg={12}>
                      <Stack className="mt-2" spacing={1}>
                        <Box
                          style={{
                            ...(conditionalparamDetails?.length > 0 && {
                              border: "1px solid #b0bec5",
                              padding: "5px",
                              borderRadius: "8px",
                            }),
                          }}
                        >
                          <Stack
                            spacing={11.6}
                            direction="row"
                            alignItems="center"
                          >
                            <Typography>
                              <strong>{"Ingredients"}</strong>
                            </Typography>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                            >
                              <IconButton
                                onClick={() => {
                                  setIsInstruction(false);
                                  setisProduct(false);
                                  setismonitoring(false);
                                  setIsIngredients(!isIngredients);
                                  setIsConditionalParam(!conditionalparam);
                                  setIsSampaling(false);
                                }}
                              >
                                <Tooltip
                                  title={
                                    isIngredients ? "Close" : "Add Ingredients"
                                  }
                                >
                                  {isIngredients ? (
                                    <CancelIcon color="warning" />
                                  ) : (
                                    <>
                                      <AddCircleIcon color="primary" />
                                      <span>{"Add"}</span>
                                    </>
                                  )}
                                </Tooltip>
                              </IconButton>
                              {isIngredients &&
                                conditionalparamDetails?.length > 0 && (
                                  <IconButton
                                    onClick={() => {
                                      setConditionalparaDetails([]);
                                      setIsIngredients(false);
                                      setIsConditionalParam(false);
                                    }}
                                  >
                                    <Tooltip title="Clear">
                                      <>
                                        <RemoveCircleIcon color="error" />
                                      </>
                                    </Tooltip>
                                  </IconButton>
                                )}
                            </Stack>
                          </Stack>
                          <Grid item lg={12} xs={12}>
                            {conditionalparamDetails?.map((item) => (
                              <Chip
                                sx={{
                                  m: 1,
                                }}
                                onDelete={(e) => {
                                  const temp = [...conditionalparamDetails];
                                  const temp2 = temp?.filter(
                                    (obj) => obj.param_id !== item?.param_id
                                  );
                                  setConditionalparaDetails(temp2);
                                }}
                                onClick={() => {
                                  setIsInstruction(false);
                                  setisProduct(false);
                                  setismonitoring(false);
                                  setIsIngredients(true);
                                  setIsConditionalParam(true);
                                  setIsSampaling(false);
                                  setselectIngredent(item?.ingredients_id);
                                  set_is_percentage(item?.is_percentage);
                                  setIngredentQuantity(item?.quantity);
                                  setConditionalparam(item?.param_id);
                                  setDeviation(item?.deviation);
                                  setConditionalMinQuantity(item?.minValue);
                                  setConditionalMaxQuantity(item?.maxvalue);
                                  setCondparamUnit(item?.unit);
                                  setIngredInstruct(item?.ingre_instruction);
                                }}
                                label={`${item.ingredients_id}: ${
                                  item.quantity + item.unit
                                } ${
                                  item.param_id !== ""
                                    ? `(${item.param_id} ${
                                        item.minValue + item.paramUnit
                                      } to ${item.maxvalue + item.paramUnit})`
                                    : ""
                                }`}
                              />
                            ))}
                          </Grid>
                        </Box>
                        <Box
                          style={{
                            ...(monitoringDetails?.length > 0 && {
                              border: "1px solid #b0bec5",
                              padding: "5px",
                              borderRadius: "8px",
                            }),
                          }}
                        >
                          <Stack
                            className="mt-2"
                            spacing={11.9}
                            direction="row"
                            alignItems="center"
                          >
                            <Typography>
                              <strong>{"Monitoring"}</strong>
                            </Typography>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                            >
                              <IconButton
                                onClick={() => {
                                  setIsIngredients(false);
                                  setIsConditionalParam(false);
                                  setIsInstruction(false);
                                  setisProduct(false);
                                  setismonitoring(!isMonitoring);
                                  setIsSampaling(false);
                                }}
                              >
                                <Tooltip
                                  title={
                                    isMonitoring ? "Close" : "Add Monitoring"
                                  }
                                >
                                  {isMonitoring ? (
                                    <CancelIcon color="warning" />
                                  ) : (
                                    <>
                                      <AddCircleIcon color="primary" />{" "}
                                      <span>{"Add"}</span>
                                    </>
                                  )}
                                </Tooltip>
                              </IconButton>
                              {isMonitoring &&
                                monitoringDetails?.length > 0 && (
                                  <IconButton
                                    onClick={() => {
                                      setMonitoringDetails([]);
                                      setismonitoring(false);
                                    }}
                                  >
                                    <Tooltip title="Clear">
                                      <>
                                        <RemoveCircleIcon color="error" />
                                      </>
                                    </Tooltip>
                                  </IconButton>
                                )}
                            </Stack>
                          </Stack>
                          {monitoringDetails?.map((item, index) => (
                            <Chip
                              sx={{
                                m: 1,
                              }}
                              onDelete={(e) => {
                                const temp = [...monitoringDetails];
                                temp?.splice(index, 1);
                                setMonitoringDetails(temp);
                              }}
                              onClick={() => {
                                setIsIngredients(false);
                                setIsConditionalParam(false);
                                setIsInstruction(false);
                                setisProduct(false);
                                setismonitoring(true);
                                setIsSampaling(false);
                                setMonitoringParam(item?.param_id);
                                setMonitoringMaxQuantity(item?.max_value);
                                setMonitoringMinQuantity(item?.min_value);
                                set_monitoring(item?.auto_status);
                                setMonitoringQuantity(item?.qunatity);
                                set_selectedSensor(item?.sensor);
                                setMonInstruct(item?.param_instruction);
                                setEndTime(item?.endTime);
                                setStartTime(item?.startTime);
                                setMonitorReading(item?.fromTime);
                              }}
                              label={`${item.param_id} ${
                                item.startTime && item.endTime
                                  ? ` at start & end`
                                  : item.startTime && !item.endTime
                                  ? ` at start`
                                  : !item.startTime && item.endTime
                                  ? ` at end`
                                  : `: ${item.fromTime}`
                              } ${
                                item?.min_value &&
                                item?.max_value &&
                                `(min: ${item?.min_value}  max: ${item?.max_value})`
                              }`}
                            />
                          ))}
                        </Box>
                        <Box
                          style={{
                            ...(productDetails?.length > 0 && {
                              border: "1px solid #b0bec5",
                              padding: "5px",
                              borderRadius: "8px",
                            }),
                          }}
                        >
                          <Stack
                            className="mt-2"
                            spacing={14.3}
                            direction="row"
                            alignItems="center"
                          >
                            <Typography>
                              <strong>{"Product"}</strong>
                            </Typography>
                            <Stack
                              spacing={1}
                              direction="row"
                              alignItems="center"
                            >
                              <IconButton
                                onClick={() => {
                                  setIsIngredients(false);
                                  setIsConditionalParam(false);
                                  setIsInstruction(false);
                                  setisProduct(!isProduct);
                                  setismonitoring(false);
                                  setIsSampaling(false);
                                }}
                              >
                                <Tooltip
                                  title={isProduct ? "Close" : "Add Product"}
                                >
                                  {isProduct ? (
                                    <CancelIcon color="warning" />
                                  ) : (
                                    <>
                                      <AddCircleIcon color="primary" />{" "}
                                      <span>{"Add"}</span>
                                    </>
                                  )}
                                </Tooltip>
                              </IconButton>
                              {isProduct && productDetails?.length > 0 && (
                                <IconButton
                                  onClick={() => {
                                    setProductDetails([]);
                                    setisProduct(false);
                                  }}
                                >
                                  <Tooltip title="Clear">
                                    <>
                                      <RemoveCircleIcon color="error" />
                                    </>
                                  </Tooltip>
                                </IconButton>
                              )}
                            </Stack>
                          </Stack>
                          {productDetails?.map((item) => (
                            <Chip
                              sx={{
                                m: 1,
                              }}
                              onDelete={(e) => {
                                const temp = [...productDetails];
                                const temp2 = temp?.filter(
                                  (obj) => obj.param_id !== item?.param_id
                                );
                                setProductDetails(temp2);
                              }}
                              onClick={() => {
                                setIsIngredients(false);
                                setIsConditionalParam(false);
                                setIsInstruction(false);
                                setisProduct(true);
                                setismonitoring(false);
                                setIsSampaling(false);
                                setSelectedProduct(item?.param_id);
                                setProductMinRange(item?.minValue);
                                setActualProdValue(item?.actualProdValue);
                                setProductMaxRange(item?.maxvalue);
                                setProdInstruct(item?.prod_instruction);
                                setProductunit(item?.unit);
                              }}
                              label={`${item.param_id} ${item.minValue}(${item.unit}) To ${item.maxvalue}(${item.unit})`}
                            />
                          ))}
                        </Box>
                        <Box
                          style={{
                            ...(instructions?.length > 0 && {
                              border: "1px solid #b0bec5",
                              padding: "5px",
                              borderRadius: "8px",
                            }),
                          }}
                        >
                          <Stack
                            className="mt-2"
                            spacing={12}
                            direction="row"
                            alignItems="center"
                          >
                            <Typography>
                              <strong>{"Instruction"}</strong>
                            </Typography>
                            <Stack direction="row">
                              <IconButton
                                onClick={() => {
                                  setIsIngredients(false);
                                  setIsConditionalParam(false);
                                  setisProduct(false);
                                  setIsInstruction(!isInstruction);
                                  setismonitoring(false);
                                  setIsSampaling(false);
                                }}
                              >
                                <Tooltip
                                  title={
                                    isInstruction ? "Close" : "Add Instruction"
                                  }
                                >
                                  {isInstruction ? (
                                    <CancelIcon color="warning" />
                                  ) : (
                                    <>
                                      <AddCircleIcon color="primary" />{" "}
                                      <span>{"Add"}</span>
                                    </>
                                  )}
                                </Tooltip>
                              </IconButton>
                              {isInstruction && instructions?.length > 0 && (
                                <IconButton
                                  onClick={() => {
                                    setInstructions([]);
                                    setIsInstruction(false);
                                  }}
                                >
                                  <Tooltip title="Clear">
                                    <>
                                      <RemoveCircleIcon color="error" />
                                    </>
                                  </Tooltip>
                                </IconButton>
                              )}
                            </Stack>
                          </Stack>
                          {instructions?.map((item, index) => (
                            <Chip
                              sx={{
                                m: 1,
                              }}
                              onDelete={() => {
                                removeInstruction(index);
                              }}
                              label={
                                item?.length > 8
                                  ? `${item.substring(0, 8)}...`
                                  : item
                              }
                              onClick={() => {
                                Modal.info({
                                  title: "Instruction",
                                  content: <>{item}</>,
                                });
                              }}
                              clickable
                            />
                          ))}
                        </Box>

                        <Box
                          style={{
                            ...(samplingDetails?.length > 0 && {
                              border: "1px solid #b0bec5",
                              padding: "5px",
                              borderRadius: "8px",
                            }),
                          }}
                        >
                          <Stack
                            className="mt-2"
                            spacing={13}
                            direction="row"
                            alignItems="center"
                          >
                            <Typography>
                              <strong>{"Sampling"}</strong>
                            </Typography>
                            <Stack direction="row">
                              <IconButton
                                onClick={() => {
                                  let str = "Sampling";
                                  isSampaling
                                    ? setOperationType("")
                                    : setOperationType(
                                        operationTypeList?.find(
                                          (i) =>
                                            i?.operation_type?.toLowerCase() ===
                                            str?.toLowerCase()
                                        )?.operation_type_id || ""
                                      ); // set If add sampling the operation type should be selected as sampling

                                  setIsIngredients(false);
                                  setIsConditionalParam(false);
                                  setisProduct(false);
                                  setIsInstruction(false);
                                  setIsSampaling(!isSampaling);
                                  setismonitoring(false);
                                }}
                              >
                                <Tooltip
                                  title={isSampaling ? "Close" : "Add Sampling"}
                                >
                                  {isSampaling ? (
                                    <CancelIcon color="warning" />
                                  ) : (
                                    <>
                                      <AddCircleIcon color="primary" />
                                      <span>{"Add"}</span>
                                    </>
                                  )}
                                </Tooltip>
                              </IconButton>
                              {isSampaling && samplingDetails?.length > 0 && (
                                <IconButton
                                  onClick={() => {
                                    set_SamplingDetails([]);
                                    setIsSampaling(false);
                                    setOperationType("");
                                  }}
                                >
                                  <Tooltip title="Clear">
                                    <>
                                      <RemoveCircleIcon color="error" />
                                    </>
                                  </Tooltip>
                                </IconButton>
                              )}
                            </Stack>
                          </Stack>

                          {samplingDetails?.map((item) => (
                            <Chip
                              sx={{
                                m: 1,
                              }}
                              onDelete={(e) => {
                                const temp = [...samplingDetails];
                                const temp2 = temp?.filter(
                                  (obj) => obj.sample !== item?.sample
                                );
                                set_SamplingDetails(temp2);
                              }}
                              onClick={(e) => {
                                setIsIngredients(false);
                                setIsConditionalParam(false);
                                setisProduct(false);
                                setIsInstruction(false);
                                setIsSampaling(true);
                                setismonitoring(false);
                                setselectedSampalingParam(item?.sample);
                                setSamplingQuantity(item?.quantity);
                                setSampingTo(item?.to);
                                setSamplingUnit(item?.unit);
                                setTestingType(item?.test_type_id);
                                setSampConditionalparam(item?.parameter_id);
                                setSampMinQuantity(item?.min_value);
                                setSampMaxQuantity(item?.max_value);
                                setSampDescription(item?.description);
                                setSampleInstruct(item?.sample_instruction);
                              }}
                              label={`${item.sample}  :  ${item.quantity}${
                                item.unit
                              } ${
                                item?.parameter_id
                                  ? `(${item?.parameter_id}: ${item?.min_value} ${item.unit} to ${item?.max_value} ${item.unit})`
                                  : ""
                              } (${item.to}) (${item.test_type_id} )`}
                            />
                          ))}
                        </Box>

                        <FormControlLabel
                          label="Mass"
                          control={
                            <Switch
                              size="small"
                              checked={disabled}
                              onChange={() => setDisabled(!disabled)}
                            />
                          }
                          sx={{ gap: 1 }}
                          className="mt-2"
                        />
                      </Stack>
                    </Grid>
                    {(isIngredients ||
                      isInstruction ||
                      isMonitoring ||
                      isSampaling ||
                      isProduct) && (
                      <Grid item xs={12} lg={12}>
                        <Divider
                          style={{
                            borderColor: "#9e9e9e",
                          }}
                        >
                          <span style={{ fontSize: "15px", color: "#9e9e9e" }}>
                            Add Data
                          </span>
                        </Divider>
                      </Grid>
                    )}
                    {isInstruction && (
                      <Grid container item xs={12} lg={12}>
                        <Grid item xs={12} lg={12}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Stack direction="row" alignItems="center">
                              <FormControlLabel
                                control={
                                  <TextArea
                                    rows={2}
                                    placeholder="Instruction"
                                    value={instruction}
                                    //maxLength={300}
                                    style={{ width: 300 }}
                                    onChange={(e) =>
                                      handleInstructionChange(e.target.value)
                                    }
                                  />
                                }
                                className="mt-2"
                              />
                              <IconButton onClick={addInstruction}>
                                <AddCircleIcon color="success" />
                              </IconButton>
                            </Stack>

                            {dependant_operation &&
                              dependant_operation?.length > 0 && (
                                <>
                                  <Radio.Group
                                    onChange={(e) =>
                                      set_automanual(e.target.value)
                                    }
                                    required
                                    value={automanual}
                                  >
                                    <Radio value="Auto">Auto</Radio>
                                    <Radio value="Manual">Manual</Radio>
                                  </Radio.Group>
                                  <Popover
                                    placement="leftTop"
                                    title={"Info"}
                                    content={
                                      <Paragraph
                                        style={{
                                          width: "200px",
                                        }}
                                        ellipsis={{
                                          rows: 5,
                                          expandable: true,
                                          symbol: "more",
                                        }}
                                      >
                                        <i>
                                          {
                                            " Choose either 'Auto' or 'Manual' as the operation type"
                                          }
                                        </i>
                                      </Paragraph>
                                    }
                                  >
                                    <IconButton color="primary">
                                      <HiOutlineInformationCircle
                                        color="action"
                                        style={{ fontSize: "20" }}
                                      />
                                    </IconButton>
                                  </Popover>
                                  {automanual === "Auto" && (
                                    <Stack direction="row">
                                      <Select1
                                        allowClear
                                        showSearch
                                        id="demo-select-small"
                                        value={selectedDevice || null}
                                        size="large"
                                        style={{
                                          width: "180px",
                                          border: "0.5px solid #D9D9D9",
                                          borderRadius: "3px",
                                        }}
                                        bordered={false}
                                        placeholder="Select Device"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .includes(input.toLowerCase())
                                        }
                                        dropdownStyle={{
                                          maxHeight: 400,
                                          width: "200px",
                                          overflow: "auto",
                                        }}
                                        onChange={(value) => {
                                          if (value) {
                                            setSelectedDevice(value);
                                          } else {
                                            setSelectedDevice("");
                                          }
                                        }}
                                      >
                                        {deviceDetails?.map((mdata) => (
                                          <Option
                                            key={mdata.device_id}
                                            value={mdata.device_id}
                                          >
                                            {mdata.device_name}
                                          </Option>
                                        ))}
                                      </Select1>
                                      <Tooltip title="Add Device">
                                        <IconButton
                                          onClick={() => {
                                            setModalVisible(true);
                                          }}
                                        >
                                          <AddIcon
                                            color="primary"
                                            style={{ fontSize: 30 }}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    </Stack>
                                  )}
                                </>
                              )}
                          </Stack>
                        </Grid>
                      </Grid>
                    )}

                    {isIngredients && (
                      <Grid container item xs={12} lg={12}>
                        <MainCard>
                          <Typography className="mb-2">
                            <b>Ingredients</b>
                          </Typography>

                          <form
                            onSubmit={(event) => {
                              event.preventDefault();

                              setConditionalparaDetails([
                                ...conditionalparamDetails,
                                {
                                  ingredients_id: selectedIngredent,
                                  quantity: ingredentQuantity,
                                  is_percentage: is_percentage,
                                  deviation: deviation,
                                  param_id: selectedConditionalparam,
                                  minValue: conditionalMinQuantity,
                                  maxvalue: conditionalMaxQuantity,
                                  unit: ingredientunit,
                                  paramUnit: condparamunit,
                                  ingre_instruction: ingredInstruct,
                                },
                              ]);
                              setselectIngredent("");
                              set_is_percentage(false);
                              setIngredentQuantity("");
                              setConditionalparam("");
                              setDeviation("");
                              setConditionalMinQuantity("");
                              setConditionalMaxQuantity("");
                              setCondparamUnit("");
                              setIngredInstruct("");
                            }}
                          >
                            <Grid container item columns={12} spacing={1}>
                              <Grid item xs={4} lg={4}>
                                <Stack direction="row">
                                  <Select1
                                    showSearch
                                    id="demo-select-small"
                                    value={selectedIngredent || null}
                                    size="large"
                                    style={{
                                      width: "100%",
                                      border: "0.5px solid #D9D9D9",
                                      borderRadius: "3px",
                                      height: "36px",
                                    }}
                                    bordered={false}
                                    placeholder="Select Ingredient"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    dropdownStyle={{
                                      maxHeight: 400,
                                      overflow: "auto",
                                    }}
                                    onChange={(value) => {
                                      if (value) {
                                        setIngredientUnit(
                                          getIngredientUnitByName(value)
                                        );
                                        setselectIngredent(value);
                                      } else {
                                        setselectIngredent("");
                                        setIngredientUnit("");
                                      }
                                    }}
                                  >
                                    {inventory_data
                                      ?.filter(
                                        (item) =>
                                          item.material_type === "Ingredients"
                                      )
                                      ?.map((mdata) => (
                                        <Option
                                          key={mdata.material_id}
                                          value={mdata.material_name}
                                        >
                                          {mdata.material_name}
                                        </Option>
                                      ))}
                                  </Select1>

                                  <Tooltip title="Add Ingredient">
                                    <IconButton
                                      onClick={() => {
                                        set_type_id(
                                          get_material_type_id("Ingredients")
                                        );
                                        set_is_model_open(true);
                                        set_title("Ingredients");
                                        dispatch(set_submit_status());
                                      }}
                                    >
                                      <AddIcon
                                        color="primary"
                                        style={{ fontSize: 30 }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <OutlinedInput
                                  id="des"
                                  type="number"
                                  size="small"
                                  name="des"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Popover content={ingredientunit}>
                                        {truncateString(ingredientunit)}
                                      </Popover>
                                    </InputAdornment>
                                  }
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-input": {
                                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                        {
                                          "-webkit-appearance": "none",
                                        },
                                    },
                                  }}
                                  placeholder={
                                    "quantity" +
                                    (ingredientunit
                                      ? " in " + ingredientunit
                                      : "")
                                  }
                                  inputProps={{ min: 0, step: "any" }}
                                  required
                                  value={ingredentQuantity}
                                  // required
                                  onChange={(e) => {
                                    setIngredentQuantity(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <OutlinedInput
                                  id="des"
                                  type="number"
                                  size="small"
                                  name="des"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      {"%"}
                                    </InputAdornment>
                                  }
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-input": {
                                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                        {
                                          "-webkit-appearance": "none",
                                        },
                                    },
                                  }}
                                  placeholder="deviation"
                                  inputProps={{ min: 0, step: "any" }}
                                  required
                                  value={deviation}
                                  // required
                                  onChange={(e) => {
                                    setDeviation(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <Tooltip title="Percentage / Observative ">
                                  <Select
                                    labelId="demo-select-small-label"
                                    id="rmt"
                                    value={is_percentage}
                                    size="small"
                                    startAdornment={
                                      <InputAdornment position="start">
                                        X
                                      </InputAdornment>
                                    }
                                    required
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                      set_is_percentage(e.target.value);
                                    }}
                                  >
                                    <MenuItem value={"i"} disabled>
                                      <em>{"select"}</em>
                                    </MenuItem>
                                    <MenuItem key={"Batch Size"} value={false}>
                                      <em>{"Batch Size"}</em>
                                    </MenuItem>
                                    <MenuItem
                                      key={"% of Batch Size"}
                                      value={true}
                                    >
                                      <em>{"% of Batch Size"}</em>
                                    </MenuItem>
                                  </Select>
                                </Tooltip>
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <Stack direction="row">
                                  {/* <Select
                                      labelId="demo-select-small-label"
                                      id="demo-select-small"
                                      value={selectedConditionalparam}
                                      size="small"
                                      required
                                      style={{ width: 200 }}
                                      onChange={(e) => {
                                        setConditionalparam(e?.target?.value);
                                        setCondparamUnit(
                                          getParamUnitByName(e?.target?.value)
                                        );
                                      }}
                                    >
                                      <MenuItem value="i" disabled>
                                        <em> Select Cond Parameter</em>
                                      </MenuItem>
                                      {parameterData?.map((param) => (
                                        <MenuItem value={param.parameter_name}>
                                          {param.parameter_name}
                                        </MenuItem>
                                      ))}
                                    </Select> */}

                                  <Select1
                                    allowClear
                                    showSearch
                                    id="demo-select-small"
                                    value={selectedConditionalparam || null}
                                    size="large"
                                    style={{
                                      width: "100%",
                                      border: "0.5px solid #D9D9D9",
                                      borderRadius: "3px",
                                      height: "35px",
                                    }}
                                    bordered={false}
                                    placeholder="Cond Parameter"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    filterSort={(optionA, optionB) =>
                                      optionA.children.localeCompare(
                                        optionB.children
                                      )
                                    }
                                    dropdownStyle={{
                                      maxHeight: 400,
                                      width: "200px",
                                      overflow: "auto",
                                    }}
                                    onChange={(value) => {
                                      if (value) {
                                        setConditionalparam(value);
                                        setCondparamUnit(
                                          getParamUnitByName(value)
                                        );
                                      } else {
                                        setConditionalparam("");
                                        setCondparamUnit("");
                                      }
                                    }}
                                  >
                                    {parameterData?.map((mdata) => (
                                      <Option
                                        key={mdata.parameter_name}
                                        value={mdata.parameter_name}
                                      >
                                        {mdata.parameter_name}
                                      </Option>
                                    ))}
                                  </Select1>

                                  <Tooltip title="Add Parameter">
                                    <IconButton
                                      onClick={() => {
                                        set_show_modal(true);
                                        set_add_number(4);
                                      }}
                                    >
                                      <AddIcon
                                        color="primary"
                                        style={{ fontSize: 30 }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Grid>
                              <Grid item xs={2} lg={2}>
                                <OutlinedInput
                                  id="des"
                                  type="number"
                                  size="small"
                                  name="des"
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Popover content={condparamunit}>
                                        {truncateString(condparamunit)}
                                      </Popover>
                                    </InputAdornment>
                                  }
                                  placeholder={
                                    "min value" +
                                    (condparamunit
                                      ? " in " + condparamunit
                                      : "")
                                  }
                                  inputProps={{
                                    step: "any",
                                  }}
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-input": {
                                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                        {
                                          "-webkit-appearance": "none",
                                        },
                                    },
                                  }}
                                  value={conditionalMinQuantity}
                                  required={selectedConditionalparam !== ""}
                                  onChange={(e) => {
                                    setConditionalMinQuantity(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={2} lg={2}>
                                <OutlinedInput
                                  id="des"
                                  type="number"
                                  size="small"
                                  name="des"
                                  sx={{
                                    width: "100%",
                                    "& .MuiOutlinedInput-input": {
                                      "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                        {
                                          "-webkit-appearance": "none",
                                        },
                                    },
                                  }}
                                  placeholder={
                                    "max value" +
                                    (condparamunit
                                      ? " in " + condparamunit
                                      : "")
                                  }
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Popover content={condparamunit}>
                                        {truncateString(condparamunit)}
                                      </Popover>
                                    </InputAdornment>
                                  }
                                  inputProps={{
                                    min: conditionalMinQuantity,
                                    step: "any",
                                  }}
                                  value={conditionalMaxQuantity}
                                  required={selectedConditionalparam !== ""}
                                  onChange={(e) =>
                                    setConditionalMaxQuantity(e.target.value)
                                  }
                                />
                              </Grid>
                              <Grid item xs={6} lg={6}>
                                <Stack direction="row" spacing={2}>
                                  <TextArea
                                    rows={2}
                                    placeholder="Instruction"
                                    value={ingredInstruct}
                                    //maxLength={300}
                                    style={{ width: 300 }}
                                    onChange={(e) =>
                                      setIngredInstruct(e.target.value)
                                    }
                                  />
                                  <Stack direction="row" alignItems="center">
                                    <Radio.Group
                                      onChange={(e) =>
                                        set_ingredautomanual(e.target.value)
                                      }
                                      required
                                      value={ingredautomanual}
                                    >
                                      <Radio value="Auto">Auto</Radio>
                                      <Radio value="Manual">Manual</Radio>
                                    </Radio.Group>
                                    <Popover
                                      placement="leftTop"
                                      title={"Info"}
                                      content={
                                        <Paragraph
                                          style={{
                                            width: "200px",
                                          }}
                                          ellipsis={{
                                            rows: 5,
                                            expandable: true,
                                            symbol: "more",
                                          }}
                                        >
                                          <i>
                                            {
                                              " Choose either 'Auto' or 'Manual' "
                                            }
                                          </i>
                                        </Paragraph>
                                      }
                                    >
                                      <IconButton color="primary">
                                        <HiOutlineInformationCircle
                                          color="action"
                                          style={{ fontSize: "20" }}
                                        />
                                      </IconButton>
                                    </Popover>
                                  </Stack>
                                </Stack>
                              </Grid>
                              <Grid item xs={12} lg={12}>
                                <Stack
                                  direction="row"
                                  justifyContent="end"
                                  alignItems="center"
                                >
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    disabled={
                                      !(
                                        (
                                          selectedIngredent !== "" &&
                                          ingredentQuantity
                                        )
                                        // conditionalMinQuantity &&
                                        // conditionalMaxQuantity &&
                                        // selectedConditionalparam !== "i"
                                      )
                                    }
                                  >
                                    Add
                                  </Button>
                                </Stack>
                              </Grid>
                            </Grid>
                          </form>
                        </MainCard>
                      </Grid>
                    )}

                    {isProduct && (
                      <Grid container item xs={12} lg={12}>
                        <MainCard>
                          <Typography className="mb-2">
                            <b>Product </b>
                          </Typography>

                          <form
                            onSubmit={(event) => {
                              event.preventDefault();
                              if (
                                parseFloat(productMinRange) <=
                                parseFloat(productMaxRange)
                              ) {
                                setProductDetails([
                                  ...productDetails,
                                  {
                                    param_id: selectedProduct,
                                    actualProdValue: actualProdValue,
                                    minValue: productMinRange,
                                    maxvalue: productMaxRange,
                                    unit: productunit,
                                    prod_instruction: prodInstruct,
                                  },
                                ]);
                                setSelectedProduct("");
                                setProductMinRange("");
                                setActualProdValue("");
                                setProductMaxRange("");
                                setProdInstruct("");
                              }
                            }}
                          >
                            <Grid container item columns={12} spacing={1}>
                              <Grid item xs={4} lg={4}>
                                <Stack direction="row">
                                  <Select1
                                    allowClear
                                    showSearch
                                    id="demo-select-small"
                                    value={selectedProduct || null}
                                    size="medium"
                                    style={{
                                      width: "100%",
                                      border: "0.5px solid #D9D9D9",
                                      borderRadius: "3px",
                                      height: "35px",
                                    }}
                                    bordered={false}
                                    placeholder="Select Product"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    dropdownStyle={{
                                      maxHeight: 400,
                                      width: "200px",
                                      overflow: "auto",
                                    }}
                                    onChange={(value) => {
                                      if (value) {
                                        setSelectedProduct(value);
                                        setProductunit(
                                          getIngredientUnitByName(value)
                                        );
                                      } else {
                                        setSelectedProduct("");
                                        setProductunit("");
                                      }
                                    }}
                                  >
                                    {inventory_data
                                      ?.filter(
                                        (item) =>
                                          item?.material_type === "Product"
                                      )
                                      ?.map((mdata) => (
                                        <Option
                                          key={mdata.material_id}
                                          value={mdata.material_name}
                                        >
                                          {mdata.material_name}
                                        </Option>
                                      ))}
                                  </Select1>
                                  <Tooltip title="Add Product">
                                    <IconButton
                                      onClick={() => {
                                        set_type_id(
                                          get_material_type_id("Product")
                                        );
                                        set_is_model_open(true);
                                        set_title("Product");
                                        dispatch(set_submit_status());
                                      }}
                                    >
                                      <AddIcon
                                        color="primary"
                                        style={{ fontSize: 30 }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <Tooltip
                                  title={`Theor value ${
                                    productunit ? `in ${productunit}` : ""
                                  }`}
                                >
                                  <OutlinedInput
                                    id="des"
                                    type="number"
                                    size="small"
                                    name="des"
                                    placeholder={`Theor value ${
                                      productunit ? `in ${productunit}` : ""
                                    }`}
                                    fullWidth
                                    required
                                    inputProps={{ min: 0 }}
                                    value={actualProdValue}
                                    // required
                                    onChange={(e) => {
                                      setActualProdValue(e.target.value);
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={2} lg={2}>
                                <Tooltip
                                  title={`Expected Min Value ${
                                    "in " + productunit
                                  }`}
                                >
                                  <OutlinedInput
                                    id="des"
                                    type="number"
                                    size="small"
                                    name="des"
                                    placeholder="min value"
                                    fullWidth
                                    required
                                    value={productMinRange}
                                    inputProps={{ min: 0 }}
                                    // required
                                    onChange={(e) => {
                                      setProductMinRange(e.target.value);
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={2} lg={2}>
                                <Tooltip
                                  title={`Expected Max Value ${
                                    "in " + productunit
                                  }`}
                                >
                                  <OutlinedInput
                                    id="des"
                                    type="number"
                                    size="small"
                                    name="des"
                                    placeholder="max value"
                                    fullWidth
                                    required
                                    inputProps={{
                                      min: productMinRange,
                                    }}
                                    value={productMaxRange}
                                    // required
                                    onChange={(e) =>
                                      setProductMaxRange(e.target.value)
                                    }
                                  />
                                </Tooltip>
                              </Grid>
                              <Grid item xs={4} lg={4}>
                                <TextArea
                                  rows={2}
                                  placeholder="Instruction"
                                  value={prodInstruct}
                                  //maxLength={300}
                                  style={{ width: 300 }}
                                  onChange={(e) =>
                                    setProdInstruct(e.target.value)
                                  }
                                />
                              </Grid>
                              <Grid item xs={12} lg={12}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                >
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    disabled={
                                      !(
                                        productMinRange &&
                                        productMaxRange &&
                                        selectedProduct !== ""
                                      )
                                    }
                                  >
                                    Add
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </form>
                        </MainCard>
                      </Grid>
                    )}

                    {isMonitoring && (
                      <Grid item xs={12} lg={12}>
                        <MainCard>
                          <Typography className="mb-2">
                            <b>Monitoring</b>
                          </Typography>

                          <form
                            onSubmit={(event) => {
                              event.preventDefault();
                              const newDetail = {
                                param_id: selectedMonitoringparam,
                                fromTime: monitorReading,
                                startTime: startTime,
                                auto_status: monitoring,
                                endTime: endTime,
                                // toTime: monitoringMaxQuantity,
                                qunatity: monitoringQuantity,
                                min_value: monitoringMinQuantity,
                                max_value: monitoringMaxQuantity,
                                Max: maxresult1,
                                sensor: selectedSensor,
                                param_instruction: monInstruct,
                              };
                              setMonitoringDetails((prevDetails) => [
                                ...prevDetails,
                                newDetail,
                              ]);

                              setMonitoringParam("");
                              setMonitoringMaxQuantity("");
                              setMonitoringMinQuantity("");
                              setMonitoringQuantity("");
                              set_selectedSensor("");
                              set_monitoring("Manual");
                              setMonInstruct("");
                              setEndTime(false);
                              setStartTime(false);
                              setMonitorReading("");
                            }}
                          >
                            <Grid container item xs={12} lg={12} spacing={2}>
                              <Grid item xs={3} lg={3}>
                                <Stack direction="row">
                                  <Select1
                                    allowClear
                                    showSearch
                                    id="demo-select-small"
                                    value={selectedMonitoringparam || null}
                                    size="medium"
                                    style={{
                                      width: "100%",
                                      border: "0.5px solid #D9D9D9",
                                      borderRadius: "3px",
                                      height: "35px",
                                    }}
                                    bordered={false}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    dropdownStyle={{
                                      maxHeight: 400,
                                      width: "200px",
                                      overflow: "auto",
                                    }}
                                    onChange={(value) => {
                                      if (value) {
                                        setMonitoringParam(value);
                                      } else {
                                        setMonitoringParam("");
                                      }
                                    }}
                                  >
                                    {parameterData?.map((mdata) => (
                                      <Option
                                        key={mdata.parameter_name}
                                        value={mdata.parameter_name}
                                      >
                                        {mdata.parameter_name}
                                      </Option>
                                    ))}
                                  </Select1>
                                  <Tooltip title="Add Parameter">
                                    <IconButton
                                      onClick={() => {
                                        set_show_modal(true);
                                        set_add_number(4);
                                      }}
                                    >
                                      <AddIcon
                                        color="primary"
                                        style={{ fontSize: 30 }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </Stack>
                              </Grid>
                              <Grid item xs={3} lg={3}>
                                <OutlinedInput
                                  id="des"
                                  type="number"
                                  size="small"
                                  name="des"
                                  sx={{ width: "100%" }}
                                  required
                                  placeholder="No. Of Reading"
                                  value={monitorReading}
                                  disabled={startTime || endTime}
                                  inputProps={{ min: 0 }}
                                  // required
                                  onChange={(e) => {
                                    setMonitorReading(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={2} lg={2}>
                                <OutlinedInput
                                  id="des"
                                  type="number"
                                  size="small"
                                  name="des"
                                  sx={{ width: "100%" }}
                                  placeholder="quantity"
                                  required
                                  value={monitoringQuantity}
                                  inputProps={{ min: 0, step: "any" }}
                                  // required
                                  onChange={(e) => {
                                    setMonitoringQuantity(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={2} lg={2}>
                                <OutlinedInput
                                  id="des"
                                  type="number"
                                  size="small"
                                  name="des"
                                  sx={{ width: "100%" }}
                                  placeholder="min"
                                  value={monitoringMinQuantity}
                                  inputProps={{ min: 0, step: "any" }}
                                  // required
                                  onChange={(e) => {
                                    setMonitoringMinQuantity(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={2} lg={2}>
                                <OutlinedInput
                                  id="des"
                                  type="number"
                                  size="small"
                                  name="des"
                                  sx={{ width: "100%" }}
                                  placeholder="max"
                                  value={monitoringMaxQuantity}
                                  inputProps={{
                                    min: monitoringMinQuantity,
                                    step: "any",
                                  }}
                                  // required
                                  onChange={(e) => {
                                    setMonitoringMaxQuantity(e.target.value);
                                  }}
                                />
                              </Grid>
                              <Grid item xs={10} lg={10}>
                                <Stack direction="row" alignItems="center">
                                  <TextArea
                                    rows={2}
                                    placeholder="Instruction"
                                    value={monInstruct}
                                    //maxLength={300}
                                    style={{ width: 300 }}
                                    onChange={(e) =>
                                      setMonInstruct(e.target.value)
                                    }
                                  />
                                  <Stack>
                                    <Checkbox1
                                      checked={startTime}
                                      onChange={handleStartChange}
                                      style={{ marginLeft: "20px" }}
                                      disabled={monitorReading}
                                    >
                                      Start Time
                                    </Checkbox1>
                                    <Checkbox1
                                      checked={endTime}
                                      onChange={handleEndChange}
                                      style={{ marginLeft: "20px" }}
                                      disabled={monitorReading}
                                    >
                                      End Time
                                    </Checkbox1>
                                  </Stack>
                                  <Stack direction="row" alignItems="center">
                                    <Radio.Group
                                      onChange={(e) =>
                                        set_monitoring(e.target.value)
                                      }
                                      required
                                      value={monitoring}
                                      style={{ marginLeft: "20px" }}
                                    >
                                      <Radio value="Auto">Auto</Radio>
                                      <Radio value="Manual">Manual</Radio>
                                    </Radio.Group>
                                    <Popover
                                      placement="leftTop"
                                      title={"Info"}
                                      content={
                                        <Paragraph
                                          style={{
                                            width: "200px",
                                          }}
                                          ellipsis={{
                                            rows: 5,
                                            expandable: true,
                                            symbol: "more",
                                          }}
                                        >
                                          <i>
                                            {
                                              " Choose either 'Auto' or 'Manual' for monitoring"
                                            }
                                          </i>
                                        </Paragraph>
                                      }
                                    >
                                      <IconButton color="primary">
                                        <HiOutlineInformationCircle
                                          color="action"
                                          style={{ fontSize: "20" }}
                                        />
                                      </IconButton>
                                    </Popover>
                                  </Stack>
                                  {monitoring === "Auto" && (
                                    <Select1
                                      allowClear
                                      showSearch
                                      id="demo-select-small"
                                      value={selectedSensor || null}
                                      size="medium"
                                      style={{
                                        width: "20%",
                                        border: "0.5px solid #D9D9D9",
                                        borderRadius: "3px",
                                        height: "35px",
                                      }}
                                      bordered={false}
                                      placeholder="Select sensor"
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .includes(input.toLowerCase())
                                      }
                                      dropdownStyle={{
                                        maxHeight: 400,
                                        width: "200px",
                                        overflow: "auto",
                                      }}
                                      onChange={(value) => {
                                        if (value) {
                                          set_selectedSensor(value);
                                        } else {
                                          set_selectedSensor("");
                                        }
                                      }}
                                    >
                                      {sensorData?.map((mdata) => (
                                        <Option
                                          key={mdata.sensor_id}
                                          value={mdata.sensor_id}
                                        >
                                          {mdata.sensor_name}
                                        </Option>
                                      ))}
                                    </Select1>
                                  )}
                                </Stack>
                              </Grid>

                              {/* <OutlinedInput
                                    id="des"
                                    type="time"
                                    size="small"
                                    name="des"
                                    placeholder="max"
                                    startAdornment={
                                      <InputAdornment position="start">
                                        Tolerance:
                                      </InputAdornment>
                                    }
                                    sx={{ width: 200 }}
                                    required
                                    inputProps={{ max: monitoringMinQuantity }}
                                    value={monitoringMaxQuantity}
                                    // required
                                    onChange={(e) => {
                                      setMonitoringMaxQuantity(e.target.value);
                                    }}
                                  /> */}
                              <Grid item xs={12} lg={12}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                    marginTop: "10px",
                                  }}
                                >
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    size="small"
                                    disabled={
                                      !(
                                        selectedMonitoringparam !== "" &&
                                        (monitorReading !== "" ||
                                          startTime ||
                                          endTime) &&
                                        (monitoring === "Auto"
                                          ? selectedSensor !== ""
                                          : true) &&
                                        monitoringQuantity !== ""
                                      )
                                    }
                                  >
                                    Add
                                  </Button>
                                </div>
                              </Grid>
                            </Grid>
                          </form>
                        </MainCard>
                      </Grid>
                    )}
                  </Grid>
                  {isSampaling && (
                    <Grid container item xs={12} lg={12}>
                      <MainCard>
                        <form
                          onSubmit={(event) => {
                            event.preventDefault();
                            const newDetail = {
                              sample: selectedSampalingParam,
                              to: samplingTo,
                              test_type_id: testingType,
                              quantity: samplingQuantity,
                              unit:
                                samplingUnit === "Liter"
                                  ? "ml"
                                  : samplingUnit === "KG"
                                  ? "gram"
                                  : samplingUnit,
                              parameter_id: samplingConditionalparam,
                              min_value: sampMinQuantity,
                              max_value: sampMaxQuantity,
                              description: sampDescription,
                              sample_instruction: sampleInstruct,
                            };

                            set_SamplingDetails((prevDetails) => {
                              // Check if the param_id already exists
                              return updateExistingRecord(
                                prevDetails || [],
                                "sample",
                                newDetail,
                                selectedSampalingParam
                              );
                            });

                            setselectedSampalingParam("");
                            setSamplingQuantity("");
                            setSampingTo("");
                            setSamplingUnit("");
                            setTestingType("");
                            setSampConditionalparam("");
                            setSampMinQuantity();
                            setSampMaxQuantity();
                            setSampDescription("");
                            setSampParamUnit();
                            setSampleInstruct("");
                          }}
                        >
                          <Typography className="mb-2">
                            <b>Sampling</b>
                          </Typography>

                          <Grid container item xs={12} lg={12} spacing={2}>
                            <Grid item xs={3} lg={3}>
                              <Stack direction="row">
                                <Select1
                                  allowClear
                                  showSearch
                                  id="demo-select-small"
                                  value={selectedSampalingParam || null}
                                  size="large"
                                  style={{
                                    width: "100%",
                                    border: "0.5px solid #D9D9D9",
                                    borderRadius: "3px",
                                    height: "35px",
                                  }}
                                  bordered={false}
                                  placeholder="Select"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  dropdownStyle={{
                                    maxHeight: 400,
                                    width: "200px",
                                    overflow: "auto",
                                  }}
                                  onChange={(value) => {
                                    if (value) {
                                      const newSamplingUnit = takeSamplingInput(
                                        inventory_data,
                                        value
                                      );
                                      setSamplingUnit(newSamplingUnit);
                                      setselectedSampalingParam(value);
                                    } else {
                                      setSamplingUnit("");
                                      setselectedSampalingParam("");
                                    }
                                  }}
                                >
                                  {inventory_data
                                    ?.filter(
                                      (item) =>
                                        item?.material_type === "By Product"
                                    )
                                    ?.map((mdata, index) => (
                                      <Option
                                        key={mdata.material_id}
                                        value={mdata.material_name}
                                      >
                                        {mdata.material_name}
                                      </Option>
                                    ))}
                                </Select1>

                                <Tooltip title="Add Sampling">
                                  <IconButton
                                    onClick={() => {
                                      set_type_id(
                                        get_material_type_id("By Product")
                                      );
                                      set_is_model_open(true);
                                      set_title("Sampling");
                                      dispatch(set_submit_status());
                                    }}
                                  >
                                    <AddIcon
                                      color="primary"
                                      style={{ fontSize: 30 }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </Grid>
                            <Grid item xs={2} lg={2}>
                              <OutlinedInput
                                id="des"
                                type="number"
                                size="small"
                                name="des"
                                placeholder="quantity"
                                // fullWidth
                                sx={{ width: "100%" }}
                                required
                                value={samplingQuantity}
                                // required
                                inputProps={{ min: 0 }}
                                onChange={(e) => {
                                  setSamplingQuantity(e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={3} lg={3}>
                              <Input.Group compact>
                                <Input
                                  style={{ width: "20%", height: "35px" }}
                                  defaultValue="To"
                                  size="medium"
                                  disabled
                                />
                                <Select1
                                  allowClear
                                  showSearch
                                  id="demo-select-small"
                                  value={samplingTo || null}
                                  size="large"
                                  style={{
                                    width: "80%",
                                    border: "0.5px solid #D9D9D9",
                                    borderRadius: "3px",
                                    height: "35px",
                                  }}
                                  bordered={false}
                                  placeholder="Select Department"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  dropdownStyle={{
                                    maxHeight: 400,
                                    width: "200px",
                                    overflow: "auto",
                                  }}
                                  onChange={(value) => {
                                    if (value) {
                                      setSampingTo(value);
                                    } else {
                                      setSampingTo("");
                                    }
                                  }}
                                >
                                  {department_dataList?.map((mdata) => (
                                    <Option
                                      key={mdata.department_name}
                                      value={mdata.department_name}
                                    >
                                      {mdata.department_name}
                                    </Option>
                                  ))}
                                </Select1>
                              </Input.Group>
                            </Grid>

                            {/* <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  value={testingType}
                                  style={{ width: 200 }}
                                  required
                                  startAdornment={
                                    <InputAdornment position="start">
                                      Test Type :
                                    </InputAdornment>
                                  }
                                  onChange={(e) => {
                                    setTestingType(e.target.value);
                                  }}
                                >
                                  <MenuItem value="i" disabled>
                                    <em>{"Select Test Type"}</em>
                                  </MenuItem>
                                  {testingTypeList?.map((tdata) => (
                                    <MenuItem value={tdata.testing_type}>
                                      <em>{tdata.testing_type}</em>
                                    </MenuItem>
                                  ))}
                                </Select> */}
                            <Grid item xs={4} lg={4}>
                              <Stack direction="row" spacing={1}>
                                <Input.Group compact>
                                  <Input
                                    style={{ width: "35%", height: "35px" }}
                                    defaultValue="Test Type :"
                                    size="medium"
                                    disabled
                                  />
                                  <Select1
                                    showSearch
                                    allowClear
                                    id="demo-select-small"
                                    value={testingType || null}
                                    size="medium"
                                    style={{
                                      width: "65%",
                                      border: "0.5px solid #D9D9D9",
                                      borderRadius: "3px",
                                      height: "35px",
                                    }}
                                    bordered={false}
                                    placeholder="Select"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                    }
                                    // filterSort={(optionA, optionB) =>
                                    //   optionA.children.localeCompare(optionB.children)
                                    // }
                                    dropdownStyle={{
                                      maxHeight: 400,
                                      width: "20%",
                                      overflow: "auto",
                                    }}
                                    onChange={(value) => {
                                      if (value) {
                                        setTestingType(value);
                                      } else {
                                        setTestingType("");
                                      }
                                    }}
                                  >
                                    {testingTypeList?.map((mdata) => (
                                      <Option
                                        key={mdata.testing_type}
                                        value={mdata.testing_type}
                                      >
                                        {mdata.testing_type}
                                      </Option>
                                    ))}
                                  </Select1>
                                </Input.Group>

                                <Tooltip title={"Add Testing Type"}>
                                  <IconButton
                                    onClick={() => {
                                      setEquipSampletitle("Add Sample Config");
                                      set_machine_modal(true);
                                      set_mod_number(2);
                                    }}
                                  >
                                    <AddIcon
                                      color="primary"
                                      style={{ fontSize: 30 }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </Grid>
                            <Grid item xs={3} lg={3}>
                              <Stack direction="row">
                                <Select1
                                  allowClear
                                  showSearch
                                  id="demo-select-small"
                                  value={samplingConditionalparam || null}
                                  size="large"
                                  style={{
                                    width: "100%",
                                    border: "0.5px solid #D9D9D9",
                                    borderRadius: "3px",
                                    height: "35px",
                                  }}
                                  bordered={false}
                                  placeholder="Cond Parameter"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  filterSort={(optionA, optionB) =>
                                    optionA.children.localeCompare(
                                      optionB.children
                                    )
                                  }
                                  dropdownStyle={{
                                    maxHeight: 400,
                                    width: "200px",
                                    overflow: "auto",
                                  }}
                                  onChange={(value) => {
                                    if (value) {
                                      setSampConditionalparam(value);
                                      setSampParamUnit(
                                        getParamUnitByName(value)
                                      );
                                    } else {
                                      setSampConditionalparam("");
                                      setSampParamUnit("");
                                    }
                                  }}
                                >
                                  {parameterData?.map((mdata) => (
                                    <Option
                                      key={mdata.parameter_name}
                                      value={mdata.parameter_name}
                                    >
                                      {mdata.parameter_name}
                                    </Option>
                                  ))}
                                </Select1>

                                <Tooltip title="Add Parameter">
                                  <IconButton
                                    onClick={() => {
                                      set_show_modal(true);
                                      set_add_number(4);
                                    }}
                                  >
                                    <AddIcon
                                      color="primary"
                                      style={{ fontSize: 30 }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </Grid>
                            <Grid item xs={2} lg={2}>
                              <OutlinedInput
                                id="quantity"
                                type="number"
                                size="small"
                                name="quantity"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Popover content={sampParamUnit}>
                                      {truncateString(sampParamUnit)}
                                    </Popover>
                                  </InputAdornment>
                                }
                                placeholder={
                                  "min value" +
                                  (sampParamUnit ? " in " + sampParamUnit : "")
                                }
                                sx={{
                                  width: "100%",
                                  "& .MuiOutlinedInput-input": {
                                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                      {
                                        "-webkit-appearance": "none",
                                      },
                                  },
                                }}
                                inputProps={{ min: 0 }}
                                value={sampMinQuantity || ""}
                                required={samplingConditionalparam !== ""}
                                onChange={(e) => {
                                  setSampMinQuantity(e.target.value);
                                }}
                              />
                            </Grid>
                            <Grid item xs={2} lg={2}>
                              <OutlinedInput
                                id="des"
                                type="number"
                                size="small"
                                name="des"
                                sx={{
                                  width: "100%",
                                  "& .MuiOutlinedInput-input": {
                                    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button":
                                      {
                                        "-webkit-appearance": "none",
                                      },
                                  },
                                }}
                                placeholder={
                                  "max value" +
                                  (sampParamUnit ? " in " + sampParamUnit : "")
                                }
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Popover content={sampParamUnit}>
                                      {truncateString(sampParamUnit)}
                                    </Popover>
                                  </InputAdornment>
                                }
                                inputProps={{
                                  min: sampMinQuantity,
                                }}
                                value={sampMaxQuantity || ""}
                                required={samplingConditionalparam !== ""}
                                onChange={(e) =>
                                  setSampMaxQuantity(e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                              <OutlinedInput
                                id="des"
                                type="text"
                                size="small"
                                name="des"
                                fullWidth
                                placeholder="Description"
                                value={sampDescription}
                                required={samplingConditionalparam !== ""}
                                onChange={(e) =>
                                  setSampDescription(e.target.value)
                                }
                              />
                            </Grid>
                            <Grid item xs={4} lg={4}>
                              <TextArea
                                rows={2}
                                placeholder="Instruction"
                                value={sampleInstruct}
                                //maxLength={300}
                                style={{ width: 300 }}
                                onChange={(e) =>
                                  setSampleInstruct(e.target.value)
                                }
                              />
                            </Grid>

                            <Grid item xs={12} lg={12}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  size="small"
                                  type="submit"
                                  disabled={
                                    !(
                                      selectedSampalingParam !== "" &&
                                      samplingQuantity &&
                                      samplingTo &&
                                      testingType !== ""
                                    )
                                  }
                                >
                                  Add
                                </Button>
                              </div>
                            </Grid>
                          </Grid>
                        </form>
                      </MainCard>
                    </Grid>
                  )}
                </Grid>
                <Grid item xs={3.6} lg={3.6}>
                  <Drawer
                    title="Process Info"
                    placement="right"
                    closable={false}
                    onClose={onClose}
                    open={true}
                    getContainer={false}
                    width="30%"
                    mask={false}
                    style={{
                      position: "absolute",
                      zIndex: 1000,
                      overflowY: "scroll",
                    }}
                  >
                    <Grid item container xs={12} lg={12} spacing={2}>
                      <Grid item xs={12} lg={8}>
                        <Stack spacing={2} direction="row">
                          {/* <InputLabel htmlFor="des">Process Name</InputLabel> */}
                          <OutlinedInput
                            id="des"
                            type="text"
                            size="small"
                            name="des"
                            placeholder="Process Name"
                            fullWidth
                            disabled={true}
                            value={processName}
                            required
                            onChange={(e) => {
                              dispatch(
                                setnewProcessDataShow({
                                  ...newProcessData,
                                  process_list: [
                                    {
                                      process_id:
                                        localStorage.getItem("newProcessId"),
                                      process: e.target.value || "New Process",
                                    },
                                  ],
                                })
                              );
                              setProcessName(e.target.value);
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <FormGroup className="mx-1">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <InputLabel htmlFor="title">
                              <strong>Approval:</strong>
                            </InputLabel>
                            {rolesList?.map((data) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    //  checked={oneTimeChecked}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, data.role_id)
                                    }
                                  />
                                }
                                label={
                                  data.role === "Production Manager"
                                    ? "Production"
                                    : data.role
                                }
                                // disabled={frequencyChecked}
                              />
                            ))}
                          </Stack>
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Divider
                          dashed={true}
                          style={{
                            borderColor: "black",
                            marginTop: "-1px",
                          }}
                        >
                          <span style={{ fontSize: "13px", color: "#9e9e9e" }}>
                            Add Operation
                          </span>
                        </Divider>
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        {operationNumber + 1 > 1 && (
                          <TreeSelect
                            showSearch
                            multiple
                            suffixIcon={
                              <CaretDownOutlined style={{ color: "black" }} />
                            }
                            disabled={!processName}
                            style={{
                              width: "180px",
                              border: "1px solid #e0e0e0",
                              borderRadius: "3px",
                              color: "black",
                            }}
                            value={dependant_operation}
                            bordered={false}
                            dropdownStyle={{
                              width: "250px",
                              overflow: "auto",
                            }}
                            placeholder="Dependent Operation"
                            allowClear
                            size="medium"
                            treeDefaultExpandAll
                            onChange={handleTreeSelectChange}
                            treeData={dependantOperations}
                            dropdownClassName="custom-dropdown" // Add custom class for dropdown icon color
                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                            maxTagCount={2} // Optional: Limit the number of tags displayed
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} lg={12}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography className="mb-2">
                            <b>Operations :</b>
                          </Typography>
                          {/* <Select
                        labelId="demo-select-small-label"
                        id="rmt"
                        value={dependant_operation}
                        size="small"
                        required
                        placeholder="Dependent Operation"
                        style={{ width: "200px" }}
                        onChange={(e) => {
                          set_dependant_opId(e.target?.value);
                        }}
                      >
                        <MenuItem value="i" disabled>
                          <em>Dependent Operation</em>
                        </MenuItem>
                        {dependantOperations?.map((data, index) => (
                          <MenuItem
                            key={data.operation_number}
                            value={data.operation_number}
                          >
                            {data.operation_number}
                          </MenuItem>
                        ))}
                      </Select> */}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={6}>
                        <OutlinedInput
                          id="des"
                          type="number"
                          startAdornment={
                            <InputAdornment position="start">
                              Operation-
                            </InputAdornment>
                          }
                          aria-describedby="outlined-weight-helper-text"
                          size="small"
                          name="des"
                          placeholder="Operation No."
                          style={{ width: "100%" }}
                          value={operationNumber + 1}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} lg={9}>
                        <OutlinedInput
                          id="name"
                          type="text"
                          aria-describedby="outlined-weight-helper-text"
                          size="small"
                          name="des"
                          placeholder="Operation Name."
                          style={{ width: "100%" }}
                          value={operationName}
                          onChange={(e) => {
                            setOperationName(e.target.value);
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <Stack direction="row">
                          <Select1
                            showSearch
                            allowClear
                            id="demo-select-small"
                            value={operationType || null}
                            size="medium"
                            style={{
                              width: "100%",
                              border: "0.5px solid #D9D9D9",
                              borderRadius: "3px",
                              height: "35px",
                            }}
                            bordered={false}
                            placeholder="Operation Type"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // filterSort={(optionA, optionB) =>
                            //   optionA.children.localeCompare(optionB.children)
                            // }
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                            }}
                            onChange={(value) => {
                              const type_name = getOperationTypeNameById(value);
                              if (type_name === "Sampling") {
                                setIsSampaling(!isSampaling);
                                setOperationType(value);
                              } else {
                                setIsSampaling(false);
                                setOperationType(value);
                              }
                            }}
                          >
                            {operationTypeList?.map((mdata) => (
                              <Option
                                key={mdata.operation_type_id}
                                value={mdata.operation_type_id}
                              >
                                {mdata.operation_type}
                              </Option>
                            ))}
                          </Select1>
                          <Tooltip title="Add Operation Type">
                            <IconButton
                              onClick={() => {
                                setisOperationTypeAdd(true);
                              }}
                            >
                              <AddIcon
                                color="primary"
                                style={{ fontSize: 30 }}
                              />
                            </IconButton>
                          </Tooltip>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <Input.Group compact>
                          <Input
                            style={{ width: "48%", height: "35px" }}
                            defaultValue="Operation By"
                            size="medium"
                            disabled
                          />
                          <Select1
                            showSearch
                            allowClear
                            id="demo-select-small"
                            value={operationBy || null}
                            size="medium"
                            style={{
                              width: "50%",
                              border: "0.5px solid #D9D9D9",
                              borderRadius: "3px",
                              height: "35px",
                            }}
                            bordered={false}
                            placeholder="Select"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // filterSort={(optionA, optionB) =>
                            //   optionA.children.localeCompare(optionB.children)
                            // }
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                            }}
                            onChange={(value) => {
                              if (value) {
                                setOpeartionBy(value);
                              } else {
                                setOpeartionBy("");
                              }
                            }}
                          >
                            {department_dataList?.map((mdata) => (
                              <Option
                                key={mdata.department_name}
                                value={mdata.department_name}
                              >
                                {mdata.department_name}
                              </Option>
                            ))}
                          </Select1>
                        </Input.Group>
                      </Grid>
                      <Grid item xs={12} lg={8}>
                        <Tooltip
                          title={
                            isMachine !== ""
                              ? `The '${isMachine}' is Already Associated With This Recipe`
                              : ""
                          }
                        >
                          <Stack direction="row">
                            <Select1
                              showSearch
                              allowClear
                              id="demo-select-small"
                              value={selectedEquipment || null}
                              size="medium"
                              style={{
                                width: "100%",
                                border: "0.5px solid #D9D9D9",
                                borderRadius: "3px",
                                height: "35px",
                              }}
                              bordered={false}
                              placeholder="Select Equipment"
                              disabled={isMachine !== "" ? true : false}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              dropdownStyle={{
                                maxHeight: 400,
                                overflow: "auto",
                              }}
                              onChange={(value) => {
                                if (value) {
                                  setMachineName([]);
                                  set_machinesByType([]);
                                  bp_get_machine_by_machineType(value); //call the api
                                  setSelectedEquipment(value);
                                } else {
                                  setSelectedEquipment("");
                                  setMachineName([]);
                                  set_machinesByType([]);
                                }
                              }}
                            >
                              {machineData?.map((mdata) => (
                                <Option
                                  key={mdata.machine_category_id}
                                  value={mdata.machine_category_id}
                                >
                                  {mdata.machine_category_name}
                                </Option>
                              ))}
                            </Select1>
                            {isMachine === "" && (
                              <Tooltip title="Add Equipment">
                                <IconButton
                                  onClick={() => {
                                    setEquipSampletitle("Add Equipment");
                                    set_machine_modal(true);
                                    set_mod_number(1);
                                  }}
                                >
                                  <AddIcon
                                    color="primary"
                                    style={{ fontSize: 30 }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Stack>
                        </Tooltip>
                      </Grid>
                      {selectedEquipment !== "" && (
                        <Grid item xs={12} lg={8}>
                          <Select1
                            mode="multiple"
                            showSearch
                            allowClear
                            id="demo-select-small"
                            value={machineName}
                            size="medium"
                            style={{
                              width: "100%",
                              border: "0.5px solid #D9D9D9",
                              borderRadius: "3px",
                              minHeight: "35px",
                              maxHeight: "70px",
                              overflow:
                                machineName?.length > 2 ? "scroll" : "hidden",
                            }}
                            bordered={false}
                            placeholder="Select Machines"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            // filterSort={(optionA, optionB) =>
                            //   optionA.children.localeCompare(optionB.children)
                            // }
                            dropdownStyle={{
                              maxHeight: 400,
                              overflow: "auto",
                            }}
                            onChange={handleChange}
                          >
                            {machinesByType?.map((mdata) => (
                              <Option
                                key={mdata?.machine_name}
                                value={mdata?.machine_name}
                              >
                                {mdata?.machine_name}
                              </Option>
                            ))}
                          </Select1>
                          {/* </Tooltip> */}
                        </Grid>
                      )}
                      <Grid item xs={8} lg={8}>
                        <Tooltip title="Duration(HH:MM)">
                          <OutlinedInput
                            id="des"
                            type="time"
                            size="small"
                            name="des"
                            startAdornment={
                              <InputAdornment position="start">
                                Duration-
                              </InputAdornment>
                            }
                            placeholder="Duration"
                            style={{ width: "100%" }}
                            value={maxDuration}
                            required
                            onChange={(e) => {
                              console.log(e.target.value);
                              if (e.target.value) {
                                setMinDuration(e.target.value);
                                setMaxDuration(e.target.value);
                              }
                            }}
                          />
                        </Tooltip>
                      </Grid>
                      <Grid item xs={8} lg={8}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={exeTimeline}
                              onChange={() => setExeTimeline(!exeTimeline)}
                            />
                          }
                          label="Execution Timeline"
                        />
                      </Grid>
                    </Grid>
                  </Drawer>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid
            container
            xs={12}
            lg={12}
            direction="row"
            justifyContent="end"
            alignItems="end"
            style={{ marginTop: "10px" }}
          >
            <Grid item lg={12}>
              <Stack direction="row" justifyContent="end" spacing={2}>
                {/* <Tooltip title="Preview Process">
                  <Button
                    type="button"
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                      //  get_processByRecipeId(recipe_id); //call the api
                      const addRequest = {
                        recipe_id: localStorage.getItem("recipe_md_id"),
                        process_name: processName,
                        operations: [...preViewOperationsList],
                        process_roles: preViewOperationsList?.map((obj) => ({
                          role_id: obj.role_id,
                        })),
                      };
                      set_processDataByRecipeId(addRequest);
                      handlePreviewDetails();
                    }}
                  >
                    Preview
                  </Button>
                </Tooltip> */}

                <Popconfirm
                  title={"Are you sure to Add this Process?"}
                  onConfirm={() => handleOperationSubmit()}
                  // onCancel={cancel}
                  okText="Yes"
                  cancelText="No"
                >
                  <Tooltip title="Submit Operation">
                    <Button
                      type="submit"
                      size="small"
                      disabled={
                        !(
                          operationType !== "" &&
                          operationBy !== "" &&
                          minDuration !== "00:00" &&
                          maxDuration !== "00:00" &&
                          (samplingDetails.length > 0 ||
                            monitoringDetails.length > 0 ||
                            conditionalparamDetails.length > 0 ||
                            productDetails?.length > 0 ||
                            instructions?.length > 0) &&
                          (machineName?.length || isMachine !== "")
                        )
                      }
                      variant="contained"
                      color="primary"
                      //onClick={() => handleProcessSubmit()}
                    >
                      Submit Operation
                    </Button>
                  </Tooltip>
                </Popconfirm>
              </Stack>
            </Grid>
            {/* </MainCard> */}
          </Grid>
        </Grid>
        {/* <Grid item lg={12}>
                <Stack direction="row" justifyContent="end" spacing={2}>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={() => handleProcessSubmit()}
                  >
                    Add Recipe
                  </Button>
                </Stack>
              </Grid> */}
      </Grid>

      <Modal
        open={isView}
        title="Details"
        onOk={handleOk}
        width={1200}
        onCancel={() => setIsView(false)}
        footer={[]}
      >
        <div className="mt-4">
          <ConfigProvider
            theme={{
              components: {
                Descriptions: {
                  titleMarginBottom: "10px",
                },
              },
            }}
          >
            <Descriptions
              title="Process"
              bordered
              items={handleSummaryDetails("")}
            />
          </ConfigProvider>
          <div className="mt-2 mb-2" style={{ color: "Red", fontSize: 16 }}>
            <strong>Operations</strong>
            <Table
              size="small"
              bordered
              pagination={false}
              columns={operationsColumns}
              dataSource={
                processDataByRecipeId.operations &&
                processDataByRecipeId.operations.length > 0
                  ? processDataByRecipeId.operations
                  : []
              }
              scroll={{ x: 950, y: 450 }}
            />

            <TableInModal></TableInModal>
          </div>
        </div>
      </Modal>

      <TableInModal
        isVisible={isVisible} // Set to true or false based on visibility
        title={modalTitle}
        handleOk={handleOk}
        handleCancel={handleCancel}
        sourceData={sourceData}
        columnsData={columnData}
        width={modalWidth}
      />
      <ConfigureRecipe
        is_model_open={is_model_open}
        set_is_model_open={set_is_model_open}
        type_id={type_id}
        set_type_id={set_type_id}
        title={title}
        get_submit_status={get_submit_status}
        set_get_submit_status={set_get_submit_status}
      />
      <ConfigModal
        show_modal={show_modal}
        set_show_modal={set_show_modal}
        add_number={add_number}
        set_add_number={set_add_number}
        get_status={get_status}
        set_get_status={set_get_status}
      />
      <Modal
        title={equipSampletitle}
        open={machine_modal}
        onCancel={() => {
          set_machine_modal(false);
        }}
        width={1000}
        footer={[]}
      >
        {mod_number === 1 && (
          <Machine
            show_config={machine_modal}
            show_table={false}
            get_status_of_mod={get_status}
            set_get_status_of_mod={set_get_status}
          />
        )}
        {mod_number === 2 && (
          <SampleConfig
            show_config={machine_modal}
            show_table={false}
            get_status_of_mod={get_status}
            set_get_status_of_mod={set_get_status}
          />
        )}
      </Modal>

      <Modal
        title={"Add Operation Type"}
        open={isOperationTypeAdd}
        onCancel={() => {
          setisOperationTypeAdd(false);
          set_add_name("");
        }}
        onOk={handleOperationTypeOk}
        okText="Add"
        okButtonProps={{
          disabled: !add_name,
        }}
        width={500}
      >
        <OutlinedInput
          type="text"
          placeholder="Operation Type Name"
          fullWidth
          value={add_name}
          onChange={(e) => set_add_name(e.target.value)}
        />
      </Modal>
      <AddDevice
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
      />
    </MainCard>
  );
}

export default AddProcessOperation;
