import React, { useState, useEffect, useRef } from "react";
import { Input, Select, Form, InputNumber, Modal, DatePicker } from "antd";
import {
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  Grid,
  InputAdornment,
  Tooltip,
  Button,
  IconButton,
  MenuItem,
} from "@mui/material";
import {
  bp_units_data,
  get_machine_category,
  sensor_configuration,
  update_sensor_status,
  get_sensor_type,
  add_sensor_type,
  get_sensor_list,
} from "../../../utils/api";
import ConfigModal from "./ConfigModal";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
const { Option } = Select;
const SensorConfigForm = ({ isRefresh,setIsSensorVisible }) => {
  const [form] = Form.useForm();
  const formRef = useRef();
  const [show_modal, set_show_modal] = useState(false);
  const [unitData, setUnitsData] = useState([]);
  const [typeList, set_typeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [get_status, set_get_status] = useState(false);
  const [name, setName] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [unitId, setUnitId] = useState("");
  const [make, setMake] = useState("");
  const [pollRate, set_pollRate] = useState();
  const [pollRateUnit, set_pollRateUnit] = useState("");
  const [sensorStatus, setSensorStatus] = useState("");
  const [ip, set_ip] = useState("");
  const [port, set_port] = useState("");
  const [type, set_type] = useState("");
  const [address, set_address] = useState("");
  const [show, set_isShow] = useState(false);
  const [quantity, set_Quantity] = useState("");
  const [sensorType, set_sensorType] = useState("");
  const handleOk = () => {
    add_sensorType({ name: sensorType });
  };
  const ipRegex =
    /^(?=\d+\.\d+\.\d+\.\d+$)(?:(?:25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]|[0-9])\.?){4}$/;

  useEffect(() => {
    reset_function();
  }, [isRefresh]);

  //######## handle API ###########

  const get_all_dropDown_Data = async () => {
    try {
      await get_sensor_type(successGetSensorType);
      await bp_units_data(success_units_list_get);
      await get_machine_category(success_get_all_categories);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_get_all_categories = (res) => {
    setCategoryList(res?.data?.data);
  };

  const add_sensor_Data = async (req) => {
    try {
      await sensor_configuration(req, success_add_sensor_data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const add_sensorType = async (req) => {
    try {
      await add_sensor_type(req, success_add_sensor_type);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const success_add_sensor_data = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      get_all_dropDown_Data();
      set_sensorType("");
      setIsSensorVisible(false);
      reset_function();
    } else {
      toast.warning(res?.message);
    }
  };
  const success_add_sensor_type = (res) => {
    if (res?.status === "success") {
      toast.success(res?.message);
      get_sensor_type(successGetSensorType);
      set_isShow(false);
    } else {
      toast.warning(res?.message);
    }
  };

  const successGetSensorType = (res) => {
    set_typeList(res?.data?.data || []);
  };

  const success_units_list_get = (res) => {
    setUnitsData(res?.data?.data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validateIpAddress = ipRegex.test(ip);
    if (validateIpAddress) {
      if (categoryId && unitId) {
        const req = {
          name: name,
          category_id: categoryId,
          unit_id: unitId,
          make: make,
          sensor_status: sensorStatus === "Active" ? true : false,
          ip_address: ip,
          port: port,
          type: type,
          address: address,
          poll_rate: pollRate,
          quantity: quantity,
        };
        add_sensor_Data(req);
      } else {
        toast?.warning("Please provide all fields");
      }
    } else {
      toast?.warning("Please provide valid IP Address");
    }
  };
  const reset_function = () => {
    setName("");
    setCategoryId("");
    setMake("");
    setSensorStatus("");
    setUnitId("");
    set_address("");
    set_type("");
    set_Quantity("");
    set_pollRate("");
    set_pollRateUnit("");
    set_ip("");
    set_port("");
  };

  useEffect(() => {
    get_all_dropDown_Data();
  }, [get_status]);

  return (
    <>
      <form ref={formRef} onSubmit={handleSubmit}>
        <Grid container spacing={2} style={{ display: "flex" }}>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="size">Sensor ID / Name</InputLabel>
              <OutlinedInput
                id="title"
                size="medium"
                type="text"
                name="size"
                placeholder="Enter sensor ID or name"
                fullWidth
                inputProps={{ min: 0 }}
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="size">IP Address</InputLabel>
              <OutlinedInput
                id="title"
                size="medium"
                type="text"
                name="size"
                placeholder="Enter IP"
                fullWidth
                inputProps={{ min: 0 }}
                value={ip}
                required
                onChange={(e) => set_ip(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="size">Port</InputLabel>
              <OutlinedInput
                id="title"
                size="medium"
                type="number"
                name="size"
                placeholder="Enter Port"
                fullWidth
                inputProps={{ min: 0 }}
                value={port}
                required
                onChange={(e) => set_port(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="size">Type</InputLabel>
              <Stack direction="row" spacing={1}>
                <Select
                  showSearch
                  id="demo-select-small"
                  value={type || null}
                  size="large"
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  bordered={false}
                  placeholder="Select Type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onChange={(value) => set_type(value)}
                >
                  {typeList?.map((mdata, index) => (
                    <Select.Option key={mdata.index} value={mdata.id}>
                      {mdata.name}
                    </Select.Option>
                  ))}
                </Select>
                <Tooltip title="Add Type">
                  <IconButton
                    onClick={() => {
                      set_isShow(true);
                    }}
                  >
                    <AddIcon
                      style={{
                        color: "#00C853",
                        fontSize: "32px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Category</InputLabel>
              <Stack direction="row" spacing={1}>
                <Select
                  showSearch
                  allowClear
                  id="demo-select-small"
                  value={categoryId || null}
                  size="large"
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  bordered={false}
                  placeholder="Select Category"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onChange={(value) => setCategoryId(value)}
                >
                  {categoryList?.map((mdata) => (
                    <Select.Option
                      key={mdata.category_id}
                      value={mdata.category_id}
                    >
                      {mdata.category}
                    </Select.Option>
                  ))}
                </Select>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="size">Make</InputLabel>
              <OutlinedInput
                id="title"
                size="medium"
                type="text"
                name="size"
                placeholder="Enter Manufacturer"
                fullWidth
                inputProps={{ min: 0 }}
                value={make}
                required
                onChange={(e) => setMake(e?.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="size">Address</InputLabel>
              <OutlinedInput
                id="title"
                size="medium"
                type="number"
                name="size"
                placeholder="Enter Adddress"
                fullWidth
                inputProps={{ min: 0 }}
                value={address}
                required
                onChange={(e) => set_address(e.target.value)}
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="size">Quantity</InputLabel>
              <OutlinedInput
                id="title"
                size="medium"
                type="number"
                name="size"
                placeholder="Quantity"
                fullWidth
                inputProps={{ min: 0 }}
                value={quantity}
                required
                onChange={(e) => set_Quantity(e.target.value)}
              />
            </Stack>
          </Grid>

          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Unit</InputLabel>
              <Stack direction="row" spacing={1}>
                <Select
                  showSearch
                  id="demo-select-small"
                  value={unitId || null}
                  size="large"
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  bordered={false}
                  placeholder="Select Unit"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onChange={(value) => setUnitId(value)}
                >
                  {unitData?.map((mdata) => (
                    <Select.Option key={mdata.id} value={mdata.id}>
                      {mdata.unit_name}
                    </Select.Option>
                  ))}
                </Select>
                <Tooltip title="Add Unit">
                  <IconButton onClick={() => set_show_modal(true)}>
                    <AddIcon
                      style={{
                        color: "#00C853",
                        fontSize: "32px",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="size">Poll Rate </InputLabel>
              <OutlinedInput
                id="pollrate"
                type="number"
                name="pollrate"
                placeholder={`Poll Rate`}
                fullWidth
                required
                value={pollRate}
                onChange={(e) => set_pollRate(e.target.value)}
                endAdornment={
                  <InputAdornment position="end" sx={{ marginRight: 0 }}>
                    <Select
                      showSearch
                      allowClear
                      size="large"
                      labelRender={(props) => {
                        return props?.label?.props?.children;
                      }}
                      placeholder="Select"
                      optionFilterProp="children"
                      value={pollRateUnit || null}
                      onChange={(value) => {
                        set_pollRateUnit(value);
                      }}
                      filterOption={(input, option) =>
                        (option?.label2 ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{
                        width: "100%",
                      }}
                      variant="borderless"
                      dropdownStyle={{
                        maxHeight: 400,
                        overflow: "auto",
                      }}
                      options={["min", "sec"]?.map((item) => {
                        return {
                          value: item,
                          label: (
                            <div
                              style={{
                                maxWidth: "100%",
                                wordWrap: "break-word",
                                whiteSpace: "pre-wrap",
                              }}
                            >
                              {item}
                            </div>
                          ),
                          label2: item,
                        };
                      })}
                    />
                  </InputAdornment>
                }
              />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack spacing={1}>
              <InputLabel htmlFor="title">Status</InputLabel>
              <Stack direction="row" spacing={1}>
                <Select
                  showSearch
                  id="demo-select-small"
                  value={sensorStatus || null}
                  size="large"
                  style={{
                    width: "100%",
                    border: "0.5px solid #D9D9D9",
                    borderRadius: "3px",
                  }}
                  bordered={false}
                  placeholder="Select Status"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                  //   filterSort={(optionA, optionB) =>
                  //     optionA.children.localeCompare(optionB.children)
                  //   }
                  dropdownStyle={{
                    maxHeight: 400,
                    overflow: "auto",
                  }}
                  onChange={(value) => setSensorStatus(value)}
                >
                  {[{ status: "Active" }, { status: "InActive" }]?.map(
                    (mdata) => (
                      <Select.Option key={mdata.status} value={mdata.status}>
                        {mdata.status}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              justifyContent="end"
              spacing={1}
              sx={{ m: 1 }}
            >
              <Button
                size="small"
                type="reset"
                variant="contained"
                color="primary"
                className="mx-2"
                onClick={reset_function}
              >
                Reset
              </Button>
              <Button
                size="small"
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  !(
                    name &&
                    categoryId &&
                    make &&
                    unitId &&
                    sensorStatus &&
                    type &&
                    ip &&
                    address &&
                    quantity &&
                    pollRate
                  )
                }
              >
                Submit
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </form>

      <Modal
        open={show}
        title="Sensor Type"
        onOk={handleOk}
        okText="Add"
        onCancel={() => {
          set_isShow(false);
          set_sensorType("");
        }}
        okButtonProps={{ disabled: !sensorType }}
      >
        <div className="mt-4">
          <Stack spacing={1}>
            <InputLabel htmlFor="size">Sensor Type</InputLabel>
            <OutlinedInput
              id="title"
              size="medium"
              type="text"
              name="size"
              placeholder="Enter Type"
              fullWidth
              value={sensorType}
              required
              onChange={(e) => set_sensorType(e.target.value)}
            />
          </Stack>
        </div>
      </Modal>

      <ConfigModal
        show_modal={show_modal}
        set_show_modal={set_show_modal}
        add_number={2}
        get_status={get_status}
        set_get_status={set_get_status}
      />
    </>
  );
};

export default SensorConfigForm;
